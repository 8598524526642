import React from "react";
import "../../../asset/css/common/model.css";
import CustomBreadcrumb from "../../Common/customBreadcrumb";
import SalesOfferList from "./salesOfferList";

const SalesOfferPage = () => {
  return (
    <div className="container page-containners-main">
      <CustomBreadcrumb
        title="Manage Sales Offer"
        home="Dashbord"
        currentPage="Sales Offer"
        addExtraPageTitle=""
        addExtraPageTitlePath=""
      />
      <SalesOfferList />
    </div>
  );
};

export default SalesOfferPage;
