import React, { useState } from "react";
import CustomDatePicker from "../../Common/DatePickerCommon";
import DataTable from "react-data-table-component";
import { customStyles } from "../../Common/dataTableStyle";
import { tenantsListData } from "../../../asset/StastucJsonData/TenantsJsonData";
import { Link, useNavigate } from "react-router-dom";

const OwnerList = () => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectAgentLeadId, setSelectAgentLeadId] = useState("1");
  const [perPage, setPerPage] = useState(10);

  const navigate = useNavigate();
  var rowsPerPage = 10;

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = tenantsListData.filter((item) =>
    Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const handleRefNoClick = (row) => {
    setSelectAgentLeadId(row._id);
  };

  const paginationComponentOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  // Handle change page for data table functionality...

  const handlePageChange = (page) => {
    setCurrentPage(page);
    const pageRows = tenantsListData.slice(
      (page - 1) * perPage,
      page * perPage
    );
    const allPageRowsSelected = pageRows.every((row) =>
      selectedRowIds.includes(row._id)
    );
    setSelectAll(allPageRowsSelected);
  };

  const handleRowCheckboxChange = (rowId) => {
    const isSelected = selectedRowIds.includes(rowId);
    const newSelectedRowIds = isSelected
      ? selectedRowIds.filter((id) => id !== rowId)
      : [...selectedRowIds, rowId];

    setSelectedRowIds(newSelectedRowIds);
    setSelectAll(newSelectedRowIds.length === tenantsListData.length);
  };

  const handleSelectAllChange = () => {
    const pageRows = tenantsListData.slice(
      (currentPage - 1) * perPage,
      currentPage * perPage
    );
    const pageRowIds = pageRows.map((row) => row._id);

    if (selectAll) {
      setSelectedRowIds(
        selectedRowIds.filter((id) => !pageRowIds.includes(id))
      );
      setSelectAll(false);
    } else {
      setSelectedRowIds([
        ...selectedRowIds,
        ...pageRowIds.filter((id) => !selectedRowIds.includes(id)),
      ]);
      setSelectAll(true);
    }
  };

  const column = [
    {
      name: (
        <div className="text-center">
          {/* <span>Sr.</span> */}
          <input
            type="checkbox"
            checked={selectAll}
            onChange={handleSelectAllChange}
          />
        </div>
      ),
      cell: (row) => (
        <div className="text-center">
          <input
            type="checkbox"
            checked={selectedRowIds.includes(row._id)}
            onChange={() => handleRowCheckboxChange(row._id)}
          />
        </div>
      ),
      width: "100px",
      center: true,
      wrap: true,
    },
    {
      name: "Owner Id",
      selector: (row) => row?.Reference,
      center: true,
      wrap: true,
    },
    {
      name: "Owner Name",
      selector: (row) => row?.["Tenant/Buyer_Name"],
      center: true,
      wrap: true,
    },
    {
      name: "Email",
      selector: (row) => row?.["Tenant/Buyer_Email"],
      center: true,
      wrap: true,
    },
    {
      name: "Mobile",
      selector: (row) => row?.["Tenant/Buyer_Mobile"],
      center: true,
      wrap: true,
    },
    {
      name: "Nationality",
      selector: (row) => row?.["Tenant/Buyer_Nationality"],
      center: true,
      wrap: true,
    },
    {
      name: "Agent Name",
      selector: (row) => row?.Agent1,
      center: true,
      wrap: true,
    },
    {
      name: "Propertys",
      cell: (row) => (
        <>
          <Link to={`/owners-show-property/${row?._id}`}>
            <div className="cursor-pointer">
              <img width={"25px"} src="/svg/house.png" alt="images" />
            </div>
          </Link>
        </>
      ),
      center: true,
      wrap: true,
    },
    {
      name: "Edit",
      cell: (row) => (
        <>
          <Link to={`/edit-owners/${row?._id}`}>
            <div className="cursor-pointer">
              <img width={"20px"} src="/svg/pencil.png" alt="images" />
            </div>
          </Link>
        </>
      ),
      center: true,
      wrap: true,
    },
  ];

  return (
    <>
      <div className="all-list-main-containner">
        <div className="row">
          <div className="col-lg-2 col-md-4 col-sm-6 mb-2">
            <CustomDatePicker
              startDate={startDate}
              setStartDate={setStartDate}
              src={"/svg/calendar.png"}
            />
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 mb-2">
            <CustomDatePicker
              startDate={endDate}
              setStartDate={setEndDate}
              src={"/svg/calendar.png"}
            />
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 mb-2">
            <button
              type="button"
              class="btn filter_button listing_button custom-select unit-number-list"
            >
              <i class="fa fa-file-pdf-o me-2"></i>Export To CSV
            </button>
          </div>
        </div>
      </div>

      <div className="d-flex datatable-header-main">
        <button
          type="button"
          className="btn filter_button add_property_button rounded-0"
          onClick={() => navigate("/add-owners")}
        >
          <i className="fa fa-plus me-2"></i>Add New Owner
        </button>
        <div className="searchbox-parant-main ">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearch}
            className="add-search-box-in-datatable rounded-0"
          />
          <i className="fa fa-search"></i>
        </div>
      </div>

      <div className="mt-2 mb-2">
        <DataTable
          data={filteredData ? filteredData : tenantsListData}
          noDataComponent="No data available"
          customStyles={customStyles}
          onRowClicked={handleRefNoClick}
          highlightOnHover
          noHeader={false}
          persistTableHead
          columns={column}
          pagination
          searchable
          responsive
          paginationComponentOptions={paginationComponentOptions}
          paginationTotalRows={filteredData.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerRowsChange}
          paginationPerPage={(perPage, rowsPerPage)}
        />
      </div>
    </>
  );
};

export default OwnerList;
