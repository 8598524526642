import React, { useState } from "react";
import { Link } from "react-router-dom";
import CustomDatePicker from "../../../Common/DatePickerCommon";
import CustomSelect from "../../../Common/customSelectbox";
import { addDealsOptionsData } from "../../../../asset/StastucJsonData/AllDealsJsonData";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";

const AddUnitModel = () => {
  const getDealsOptionsData = addDealsOptionsData[0] || [];

  const initialValues = {
    project: "",
    estimated_completion_date: "",
    type: "",
    property_status: "",
    totle_area: "",
    selling_price: "",
    installment: "",
    percentag: "",
    date: "",
    amount_aed: "",
    unit_no: "",
    unit_type: "",
    unit_size: "",
    price_per_ft: "",
    sales_price: "",
    down_payment: "",
    three_month: "",
    six_month: "",
    handover: "",
    three_year_plan: "",
    images: "",
  };

  const validationSchema = Yup.object()
    .shape({
      project: Yup.string().required("Field is required."),
      estimated_completion_date: Yup.date()
        .nullable()
        .required("Date is required."),
      type: Yup.string().required("Field is required."),
      property_status: Yup.string().required("Field is required."),
      totle_area: Yup.string().required("Field is required."),
      selling_price: Yup.string().required("Field is required."),
      installment: Yup.string().required("Field is required."),
      percentag: Yup.string().required("Field is required."),
      date: Yup.string().required("Field is required."),
      amount_aed: Yup.string().required("Field is required."),
      unit_no: Yup.string().required("Field is required."),
      unit_type: Yup.string().required("Field is required."),
      unit_size: Yup.string().required("Field is required."),
      price_per_ft: Yup.string().required("Field is required."),
      sales_price: Yup.string().required("Field is required."),
      down_payment: Yup.string().required("Field is required."),
      three_month: Yup.string().required("Field is required."),
      six_month: Yup.string().required("Field is required."),
      handover: Yup.string().required("Field is required."),
      three_year_plan: Yup.string().required("Field is required."),
      images: Yup.string().required("Field is required."),
    })
    .defined();

  const handleSubmit = (e) => {
    console.log(e);
  };

  return (
    <>
      <div className="add-unit-model-containner px-3 py-5">
        <div className="mb-4 border-bottom-1">
          Fieds Marked with (<span className="text-danger"> * </span>) are
          Mandatory
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formik) => {
            const { setFieldValue } = formik;
            return (
              <>
                <Form>
                  <div className="row d-flex  mb-3">
                    <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                      <p className="m-0">
                        Project <span className="text-danger">*</span>
                      </p>
                      <Field
                        className="form-control rounded-0"
                        type="text"
                        placeholder=""
                        name="project"
                      />
                      <ErrorMessage
                        name="project"
                        component={"div"}
                        className="text-danger"
                      />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                      <p className="m-0">
                        Estimated Completion Date{" "}
                        <span className="text-danger">*</span>
                      </p>
                      <Field name="estimated_completion_date">
                        {({ field, form }) => (
                          <CustomDatePicker
                            startDate={field.value}
                            setStartDate={(date) =>
                              form.setFieldValue(
                                "estimated_completion_date",
                                date
                              )
                            }
                            src={"/svg/calendar.png"}
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="estimated_completion_date"
                        component={"div"}
                        className="text-danger"
                      />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                      <p className="m-0">
                        Type <span className="text-danger">*</span>
                      </p>
                      <CustomSelect
                        options={getDealsOptionsData?.select_by_type}
                        placeholder=" - Select  - "
                        onChange={(name, value) => {
                          setFieldValue(name, value);
                        }}
                        name={"type"}
                      />
                      <ErrorMessage
                        name="type"
                        component={"div"}
                        className="text-danger"
                      />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                      <p className="m-0">
                        Property status <span className="text-danger">*</span>
                      </p>
                      <CustomSelect
                        options={getDealsOptionsData?.select_by_type}
                        placeholder=" - Select  - "
                        onChange={(name, value) => {
                          setFieldValue(name, value);
                        }}
                        name={"property_status"}
                      />
                      <ErrorMessage
                        name="property_status"
                        component={"div"}
                        className="text-danger"
                      />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                      <p className="m-0">
                        Total Area(SQ. FT){" "}
                        <span className="text-danger">*</span>
                      </p>
                      <Field
                        className="form-control rounded-0"
                        type="text"
                        placeholder="Totle Area"
                        name="totle_area"
                      />
                      <ErrorMessage
                        name="totle_area"
                        component={"div"}
                        className="text-danger"
                      />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                      <p className="m-0">
                        Selling Price (AED){" "}
                        <span className="text-danger">*</span>
                      </p>
                      <Field
                        className="form-control rounded-0"
                        type="text"
                        placeholder="Selling Price (AED)"
                        name="selling_price"
                      />
                      <ErrorMessage
                        name="selling_price"
                        component={"div"}
                        className="text-danger"
                      />
                    </div>
                  </div>
                  <div className="my-1 mb-3">
                    <div className="my-3">
                      <h3>Instalments</h3>
                    </div>
                    <div className="cansel-submit-note-btn  mt-5 mb-3">
                      <button className="btn btn-secondary my-2">
                        Add Row{" "}
                      </button>

                      <button className="my-2 ml-2 btn btn-light">
                        Delete Row
                      </button>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-6 col-lg-3 mb-2">
                        <p className="m-0">
                          Installment<span className="text-danger">*</span>
                        </p>
                        <Field
                          className="form-control rounded-0"
                          type="text"
                          placeholder="Upon reservation"
                          name="installment"
                        />
                        <ErrorMessage
                          name="installment"
                          component={"div"}
                          className="text-danger"
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-3 mb-2">
                        <p className="m-0">
                          Percentage (%)<span className="text-danger">*</span>
                        </p>
                        <Field
                          className="form-control rounded-0"
                          type="text"
                          placeholder=""
                          name="percentag"
                        />
                        <ErrorMessage
                          name="percentag"
                          component={"div"}
                          className="text-danger"
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-3 mb-2">
                        <p className="m-0">
                          Date <span className="text-danger">*</span>
                        </p>
                        <CustomSelect
                          options={getDealsOptionsData?.select_by_type}
                          placeholder=" - Select  - "
                          onChange={(name, value) => {
                            setFieldValue(name, value);
                          }}
                          name={"date"}
                        />
                        <ErrorMessage
                          name="date"
                          component={"div"}
                          className="text-danger"
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-3 mb-2">
                        <p className="m-0">
                          Amount (AED) (%)<span className="text-danger">*</span>
                        </p>
                        <Field
                          className="form-control rounded-0"
                          type="text"
                          placeholder=""
                          name="amount_aed"
                        />
                        <ErrorMessage
                          name="amount_aed"
                          component={"div"}
                          className="text-danger"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="my-1 mb-3">
                    <div className="my-3">
                      <h3>Unit Model</h3>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <p className="m-0">
                          Unit No. <span className="text-danger">*</span>
                        </p>
                        <Field
                          className="form-control rounded-0"
                          type="text"
                          placeholder=""
                          name="unit_no"
                        />
                        <ErrorMessage
                          name="unit_no"
                          component={"div"}
                          className="text-danger"
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <p className="m-0">
                          Unit Type <span className="text-danger">*</span>
                        </p>
                        <Field
                          className="form-control rounded-0"
                          type="text"
                          placeholder=""
                          name="unit_type"
                        />
                        <ErrorMessage
                          name="unit_type"
                          component={"div"}
                          className="text-danger"
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <p className="m-0">
                          Unit Size <span className="text-danger">*</span>
                        </p>
                        <Field
                          className="form-control rounded-0"
                          type="text"
                          placeholder=""
                          name="unit_size"
                        />
                        <ErrorMessage
                          name="unit_size"
                          component={"div"}
                          className="text-danger"
                        />
                      </div>

                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <p className="m-0">
                          Price Per FT <span className="text-danger">*</span>
                        </p>
                        <Field
                          className="form-control rounded-0"
                          type="text"
                          placeholder=""
                          name="price_per_ft"
                        />
                        <ErrorMessage
                          name="price_per_ft"
                          component={"div"}
                          className="text-danger"
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <p className="m-0">
                          Sales Price <span className="text-danger">*</span>
                        </p>
                        <Field
                          className="form-control rounded-0"
                          type="text"
                          placeholder=""
                          name="sales_price"
                        />
                        <ErrorMessage
                          name="sales_price"
                          component={"div"}
                          className="text-danger"
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <p className="m-0">
                          Down Payment <span className="text-danger">*</span>
                        </p>
                        <Field
                          className="form-control rounded-0"
                          type="text"
                          placeholder=""
                          name="down_payment"
                        />
                        <ErrorMessage
                          name="down_payment"
                          component={"div"}
                          className="text-danger"
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <p className="m-0">
                          3 month <span className="text-danger">*</span>
                        </p>
                        <Field
                          className="form-control rounded-0"
                          type="text"
                          placeholder=""
                          name="three_month"
                        />
                        <ErrorMessage
                          name="three_month"
                          component={"div"}
                          className="text-danger"
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <p className="m-0">
                          6 month <span className="text-danger">*</span>
                        </p>
                        <Field
                          className="form-control rounded-0"
                          type="text"
                          placeholder=""
                          name="six_month"
                        />
                        <ErrorMessage
                          name="six_month"
                          component={"div"}
                          className="text-danger"
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <p className="m-0">
                          Handover <span className="text-danger">*</span>
                        </p>
                        <Field
                          className="form-control rounded-0"
                          type="text"
                          placeholder=""
                          name="handover"
                        />
                        <ErrorMessage
                          name="handover"
                          component={"div"}
                          className="text-danger"
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <p className="m-0">
                          3 Years Plan <span className="text-danger">*</span>
                        </p>
                        <Field
                          className="form-control rounded-0"
                          type="text"
                          placeholder=""
                          name="three_year_plan"
                        />
                        <ErrorMessage
                          name="three_year_plan"
                          component={"div"}
                          className="text-danger"
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <p className="m-0">
                          Image <span className="text-danger">*</span>
                        </p>
                        <Field
                          className="form-control rounded-0"
                          type="file"
                          placeholder=""
                          name="images"
                        />
                        <ErrorMessage
                          name="images"
                          component={"div"}
                          className="text-danger"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="cansel-submit-note-btn text-center mt-5 mb-3">
                    <button type="submit" className="btn btn-secondary mx-2">
                      Submit
                    </button>

                    <Link to="/create-project">
                      <button className="mx-2 btn btn-light">Cancel</button>
                    </Link>
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default AddUnitModel;
