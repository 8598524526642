// TENANTS LIST STATIS DATA...

export const tenantsListData = [
  {
    _id: "660fa7213644bf04fd93e6bd",
    Reference: "BY-D-3989",
    Client_ID: 1045,
    Type: "Rental",
    Created_By: "Binayah Support",
    "Landlord/Seller_Name": "--",
    "Landlord/Seller_Mobile": "test@gmail.com",
    "Landlord/Seller_Email": "--",
    "Landlord/Seller_Nationality": "--",
    Lead_Ref: "--",
    "Tenant/Buyer_Name": "YASMIN SAMIR",
    "Tenant/Buyer_Mobile": "+971 509558791",
    "Tenant/Buyer_Email": "test@gmail.com",
    "Tenant/Buyer_Nationality": "--",
    Listings_Ref: "--",
    Listings_Beds: "--",
    Listings_UnitNo: 316,
    Listings_Unit_Type: "--",
    Listings_Street_No: "--",
    Listings_Floor_No: "--",
    Listings_Category: "Apartment",
    Builtup_Area: "--",
    Plot_Area: "--",
    View: "--",
    Furnished: "--",
    Transaction_Type: "Leased",
    Region: "Dubai",
    Area_Location: "Jumeirah Village Triangle",
    Sub_Area_Location: "La Residence",
    Cheques: "--",
    Price: "95,000.00",
    Deposit: "4,750.00",
    Commission: "4,750.00",
    "Agency_Fee_Landlord/Seller": "0",
    Tenancy_Renewal_Date: "24-01-2025 00:00",
    Tenancy_Start_Date: "25-01-2024 00:00",
    Actual_Deal_Date: "29-03-2024 00:00",
    Deal_Estimated_Date: "--",
    Source_of_Deal: "--",
    Agent1: "Masood Ahmad",
    "Agent_1_Commission%": "--",
    Agent_1_Commission: "--",
    Agent2: "--",
    "Agent_2_Commission%": "--",
    Agent_2_Commission: "--",
    Agent3: "--",
    "Agent_3_Commission%": "--",
    Agent_3_Commission: "--",
    VAT_Amount: 0,
    Commission_VAT_Amount: 237.5,
    Status: "Closed",
    Sub_Status: "Successful",
    External_Referral_Commission: "--",
    Your_Company_Commission: "4,750.00",
    Buyer_Type: "--",
    Finance_Type: "--",
    Date_Added: "29-03-2024 12:10",
    Date_Updated: "29-03-2024 12:10",
  },
  {
    _id: "660fa7213644bf04fd93e6bd",
    Reference: "BY-D-3988",
    Client_ID: 1045,
    Type: "Rental",
    Created_By: "Binayah Support",
    "Landlord/Seller_Name": "--",
    "Landlord/Seller_Mobile": "ashd@gmail.com",
    "Landlord/Seller_Email": "--",
    "Landlord/Seller_Nationality": "--",
    Lead_Ref: "--",
    "Tenant/Buyer_Name": "rawan rami",
    "Tenant/Buyer_Mobile": "+971 526920631",
    "Tenant/Buyer_Email": "ashd@gmail.com",
    "Tenant/Buyer_Nationality": "--",
    Listings_Ref: "--",
    Listings_Beds: "--",
    Listings_UnitNo: 217,
    Listings_Unit_Type: "--",
    Listings_Street_No: "--",
    Listings_Floor_No: "--",
    Listings_Category: "Apartment",
    Builtup_Area: "--",
    Plot_Area: "--",
    View: "--",
    Furnished: "--",
    Transaction_Type: "Renew Leased",
    Region: "Dubai",
    Area_Location: "Jumeirah Village Triangle",
    Sub_Area_Location: "La Residence",
    Cheques: "--",
    Price: "31,250.00",
    Deposit: "--",
    Commission: "1,050.00",
    "Agency_Fee_Landlord/Seller": "0",
    Tenancy_Renewal_Date: "31-07-2024 00:00",
    Tenancy_Start_Date: "01-03-2024 00:00",
    Actual_Deal_Date: "29-03-2024 00:00",
    Deal_Estimated_Date: "--",
    Source_of_Deal: "--",
    Agent1: "Masood Ahmad",
    "Agent_1_Commission%": "--",
    Agent_1_Commission: "--",
    Agent2: "--",
    "Agent_2_Commission%": "--",
    Agent_2_Commission: "--",
    Agent3: "--",
    "Agent_3_Commission%": "--",
    Agent_3_Commission: "--",
    VAT_Amount: 0,
    Commission_VAT_Amount: 52.5,
    Status: "Closed",
    Sub_Status: "Successful",
    External_Referral_Commission: "--",
    Your_Company_Commission: "1,050.00",
    Buyer_Type: "--",
    Finance_Type: "--",
    Date_Added: "29-03-2024 12:07",
    Date_Updated: "29-03-2024 12:07",
  },
  {
    _id: "660fa7213644bf04fd93e6bd",
    Reference: "BY-D-3987",
    Client_ID: 1045,
    Type: "Rental",
    Created_By: "Binayah Support",
    "Landlord/Seller_Name": "--",
    "Landlord/Seller_Mobile": "abcs@gmail.com",
    "Landlord/Seller_Email": "--",
    "Landlord/Seller_Nationality": "--",
    Lead_Ref: "--",
    "Tenant/Buyer_Name": "ZAIN  KHAN",
    "Tenant/Buyer_Mobile": "+971 556143098",
    "Tenant/Buyer_Email": "abcs@gmail.com",
    "Tenant/Buyer_Nationality": "--",
    Listings_Ref: "--",
    Listings_Beds: "--",
    Listings_UnitNo: 426,
    Listings_Unit_Type: "--",
    Listings_Street_No: "--",
    Listings_Floor_No: "--",
    Listings_Category: "Villa",
    Builtup_Area: "--",
    Plot_Area: "--",
    View: "--",
    Furnished: "--",
    Transaction_Type: "Renew Leased",
    Region: "Dubai",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Cheques: "--",
    Price: "1,18,740.00",
    Deposit: "--",
    Commission: "1,050.00",
    "Agency_Fee_Landlord/Seller": "0",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Actual_Deal_Date: "29-03-2024 00:00",
    Deal_Estimated_Date: "--",
    Source_of_Deal: "--",
    Agent1: "Talal Ahmad",
    "Agent_1_Commission%": "--",
    Agent_1_Commission: "--",
    Agent2: "--",
    "Agent_2_Commission%": "--",
    Agent_2_Commission: "--",
    Agent3: "--",
    "Agent_3_Commission%": "--",
    Agent_3_Commission: "--",
    VAT_Amount: 0,
    Commission_VAT_Amount: 52.5,
    Status: "Closed",
    Sub_Status: "Successful",
    External_Referral_Commission: "--",
    Your_Company_Commission: "1,050.00",
    Buyer_Type: "--",
    Finance_Type: "--",
    Date_Added: "29-03-2024 11:49",
    Date_Updated: "29-03-2024 11:51",
  },
  {
    _id: "660fa7213644bf04fd93e6bd",
    Reference: "BY-D-3986",
    Client_ID: 1045,
    Type: "Rental",
    Created_By: "Binayah Support",
    "Landlord/Seller_Name": "--",
    "Landlord/Seller_Mobile": "--",
    "Landlord/Seller_Email": "--",
    "Landlord/Seller_Nationality": "--",
    Lead_Ref: "--",
    "Tenant/Buyer_Name": "SINAN  PURI",
    "Tenant/Buyer_Mobile": "+971 525647369",
    "Tenant/Buyer_Email": "test@gmail.com",
    "Tenant/Buyer_Nationality": "--",
    Listings_Ref: "--",
    Listings_Beds: "--",
    Listings_UnitNo: 1107,
    Listings_Unit_Type: "--",
    Listings_Street_No: "--",
    Listings_Floor_No: "--",
    Listings_Category: "Apartment",
    Builtup_Area: "--",
    Plot_Area: "--",
    View: "--",
    Furnished: "--",
    Transaction_Type: "Renew Leased",
    Region: "Dubai",
    Area_Location: "Al Jaddaf",
    Sub_Area_Location: "Farhad Azizi Residence",
    Cheques: "--",
    Price: "8,000.00",
    Deposit: "--",
    Commission: "1,050.00",
    "Agency_Fee_Landlord/Seller": "0",
    Tenancy_Renewal_Date: "11-03-2024 00:00",
    Tenancy_Start_Date: "12-01-2024 00:00",
    Actual_Deal_Date: "29-03-2024 00:00",
    Deal_Estimated_Date: "--",
    Source_of_Deal: "--",
    Agent1: "Bilal Ahmad",
    "Agent_1_Commission%": "--",
    Agent_1_Commission: "--",
    Agent2: "--",
    "Agent_2_Commission%": "--",
    Agent_2_Commission: "--",
    Agent3: "--",
    "Agent_3_Commission%": "--",
    Agent_3_Commission: "--",
    VAT_Amount: 0,
    Commission_VAT_Amount: 52.5,
    Status: "Closed",
    Sub_Status: "Successful",
    External_Referral_Commission: "--",
    Your_Company_Commission: "1,050.00",
    Buyer_Type: "--",
    Finance_Type: "--",
    Date_Added: "29-03-2024 11:48",
    Date_Updated: "29-03-2024 11:48",
  },
  {
    _id: "660fa7213644bf04fd93e6bd",
    Reference: "BY-D-3985",
    Client_ID: 1045,
    Type: "Rental",
    Created_By: "Binayah Support",
    "Landlord/Seller_Name": "--",
    "Landlord/Seller_Mobile": "--",
    "Landlord/Seller_Email": "--",
    "Landlord/Seller_Nationality": "--",
    Lead_Ref: "--",
    "Tenant/Buyer_Name": " ",
    "Tenant/Buyer_Mobile": "+971 585948656",
    "Tenant/Buyer_Email": "aaa123@gmail.com",
    "Tenant/Buyer_Nationality": "--",
    Listings_Ref: "--",
    Listings_Beds: "--",
    Listings_UnitNo: "G02",
    Listings_Unit_Type: "--",
    Listings_Street_No: "--",
    Listings_Floor_No: "--",
    Listings_Category: "Apartment",
    Builtup_Area: "--",
    Plot_Area: "--",
    View: "--",
    Furnished: "--",
    Transaction_Type: "Leased",
    Region: "Dubai",
    Area_Location: "Jumeirah Village Triangle",
    Sub_Area_Location: "La Residence",
    Cheques: "3",
    Price: "75,000.00",
    Deposit: "3,750.00",
    Commission: "3,750.00",
    "Agency_Fee_Landlord/Seller": "0",
    Tenancy_Renewal_Date: "14-02-2025 00:00",
    Tenancy_Start_Date: "15-02-2024 00:00",
    Actual_Deal_Date: "28-03-2024 00:00",
    Deal_Estimated_Date: "--",
    Source_of_Deal: "--",
    Agent1: "Masood Ahmad",
    "Agent_1_Commission%": "937.5",
    Agent_1_Commission: "25",
    Agent2: "--",
    "Agent_2_Commission%": "--",
    Agent_2_Commission: "--",
    Agent3: "--",
    "Agent_3_Commission%": "--",
    Agent_3_Commission: "--",
    VAT_Amount: 0,
    Commission_VAT_Amount: 187.5,
    Status: "Closed",
    Sub_Status: "Successful",
    External_Referral_Commission: "--",
    Your_Company_Commission: "3,750.00",
    Buyer_Type: "--",
    Finance_Type: "--",
    Date_Added: "28-03-2024 13:17",
    Date_Updated: "28-03-2024 13:17",
  },
  {
    _id: "660fa7213644bf04fd93e6bd",
    Reference: "BY-D-3984",
    Client_ID: 1045,
    Type: "Rental",
    Created_By: "Binayah Support",
    "Landlord/Seller_Name": "--",
    "Landlord/Seller_Mobile": "--",
    "Landlord/Seller_Email": "--",
    "Landlord/Seller_Nationality": "--",
    Lead_Ref: "--",
    "Tenant/Buyer_Name": "Niruk .",
    "Tenant/Buyer_Mobile": "+971 551600291",
    "Tenant/Buyer_Email": "xyz@gmail.com",
    "Tenant/Buyer_Nationality": "--",
    Listings_Ref: "--",
    Listings_Beds: "--",
    Listings_UnitNo: 306,
    Listings_Unit_Type: "--",
    Listings_Street_No: "--",
    Listings_Floor_No: "--",
    Listings_Category: "Apartment",
    Builtup_Area: "--",
    Plot_Area: "--",
    View: "--",
    Furnished: "--",
    Transaction_Type: "--",
    Region: "Dubai",
    Area_Location: "Silicon Oasis",
    Sub_Area_Location: "Axis Silver",
    Cheques: "--",
    Price: "45,150.00",
    Deposit: "--",
    Commission: "1,050.00",
    "Agency_Fee_Landlord/Seller": "0",
    Tenancy_Renewal_Date: "21-11-2024 00:00",
    Tenancy_Start_Date: "22-11-2023 00:00",
    Actual_Deal_Date: "28-03-2024 00:00",
    Deal_Estimated_Date: "--",
    Source_of_Deal: "--",
    Agent1: "Talal Ahmad",
    "Agent_1_Commission%": "--",
    Agent_1_Commission: "--",
    Agent2: "--",
    "Agent_2_Commission%": "--",
    Agent_2_Commission: "--",
    Agent3: "--",
    "Agent_3_Commission%": "--",
    Agent_3_Commission: "--",
    VAT_Amount: 0,
    Commission_VAT_Amount: 52.5,
    Status: "Closed",
    Sub_Status: "Successful",
    External_Referral_Commission: "--",
    Your_Company_Commission: "1,050.00",
    Buyer_Type: "--",
    Finance_Type: "--",
    Date_Added: "28-03-2024 13:09",
    Date_Updated: "28-03-2024 13:09",
  },
  {
    _id: "660fa7213644bf04fd93e6bd",
    Reference: "BY-D-3983",
    Client_ID: 1045,
    Type: "Rental",
    Created_By: "Binayah Support",
    "Landlord/Seller_Name": "--",
    "Landlord/Seller_Mobile": "--",
    "Landlord/Seller_Email": "--",
    "Landlord/Seller_Nationality": "--",
    Lead_Ref: "--",
    "Tenant/Buyer_Name": " ",
    "Tenant/Buyer_Mobile": "+971 529814616",
    "Tenant/Buyer_Email": "ahad@gmail.com",
    "Tenant/Buyer_Nationality": "--",
    Listings_Ref: "--",
    Listings_Beds: "--",
    Listings_UnitNo: 307,
    Listings_Unit_Type: "--",
    Listings_Street_No: "--",
    Listings_Floor_No: "--",
    Listings_Category: "Apartment",
    Builtup_Area: "--",
    Plot_Area: "--",
    View: "--",
    Furnished: "--",
    Transaction_Type: "Renew Leased",
    Region: "Dubai",
    Area_Location: "Jumeirah Village Triangle",
    Sub_Area_Location: "La Residence",
    Cheques: "--",
    Price: "20,415.00",
    Deposit: "--",
    Commission: "1,050.00",
    "Agency_Fee_Landlord/Seller": "0",
    Tenancy_Renewal_Date: "17-07-2024 00:00",
    Tenancy_Start_Date: "17-02-2024 00:00",
    Actual_Deal_Date: "28-03-2024 00:00",
    Deal_Estimated_Date: "--",
    Source_of_Deal: "--",
    Agent1: "Masood Ahmad",
    "Agent_1_Commission%": "--",
    Agent_1_Commission: "--",
    Agent2: "--",
    "Agent_2_Commission%": "--",
    Agent_2_Commission: "--",
    Agent3: "--",
    "Agent_3_Commission%": "--",
    Agent_3_Commission: "--",
    VAT_Amount: 0,
    Commission_VAT_Amount: 52.5,
    Status: "Closed",
    Sub_Status: "Successful",
    External_Referral_Commission: "--",
    Your_Company_Commission: "1,050.00",
    Buyer_Type: "--",
    Finance_Type: "--",
    Date_Added: "28-03-2024 12:38",
    Date_Updated: "28-03-2024 12:38",
  },
  {
    _id: "660fa7213644bf04fd93e6bd",
    Reference: "BY-D-3982",
    Client_ID: 1045,
    Type: "Rental",
    Created_By: "Binayah Support",
    "Landlord/Seller_Name": "--",
    "Landlord/Seller_Mobile": "--",
    "Landlord/Seller_Email": "--",
    "Landlord/Seller_Nationality": "--",
    Lead_Ref: "--",
    "Tenant/Buyer_Name": "ALI AMER SHAKIR",
    "Tenant/Buyer_Mobile": "+971 504589184",
    "Tenant/Buyer_Email": "--",
    "Tenant/Buyer_Nationality": "--",
    Listings_Ref: "--",
    Listings_Beds: "--",
    Listings_UnitNo: 306,
    Listings_Unit_Type: "--",
    Listings_Street_No: "--",
    Listings_Floor_No: "--",
    Listings_Category: "Apartment",
    Builtup_Area: "--",
    Plot_Area: "--",
    View: "--",
    Furnished: "--",
    Transaction_Type: "Leased",
    Region: "Dubai",
    Area_Location: "Jumeirah Village Triangle",
    Sub_Area_Location: "La Residence",
    Cheques: "--",
    Price: "70,000.00",
    Deposit: "3,500.00",
    Commission: "3,500.00",
    "Agency_Fee_Landlord/Seller": "0",
    Tenancy_Renewal_Date: "08-03-2025 00:00",
    Tenancy_Start_Date: "09-03-2024 00:00",
    Actual_Deal_Date: "28-03-2024 00:00",
    Deal_Estimated_Date: "--",
    Source_of_Deal: "--",
    Agent1: "Masood Ahmad",
    "Agent_1_Commission%": "--",
    Agent_1_Commission: "--",
    Agent2: "--",
    "Agent_2_Commission%": "--",
    Agent_2_Commission: "--",
    Agent3: "--",
    "Agent_3_Commission%": "--",
    Agent_3_Commission: "--",
    VAT_Amount: 0,
    Commission_VAT_Amount: 175,
    Status: "Closed",
    Sub_Status: "Successful",
    External_Referral_Commission: "--",
    Your_Company_Commission: "3,500.00",
    Buyer_Type: "--",
    Finance_Type: "--",
    Date_Added: "28-03-2024 12:31",
    Date_Updated: "28-03-2024 12:31",
  },
  {
    _id: "660fa7213644bf04fd93e6bd",
    Reference: "BY-D-3981",
    Client_ID: 1045,
    Type: "Rental",
    Created_By: "Binayah Support",
    "Landlord/Seller_Name": "--",
    "Landlord/Seller_Mobile": "--",
    "Landlord/Seller_Email": "--",
    "Landlord/Seller_Nationality": "--",
    Lead_Ref: "--",
    "Tenant/Buyer_Name": "FABIAN RITTER",
    "Tenant/Buyer_Mobile": "+971 523536658",
    "Tenant/Buyer_Email": "--",
    "Tenant/Buyer_Nationality": "--",
    Listings_Ref: "--",
    Listings_Beds: "--",
    Listings_UnitNo: 156,
    Listings_Unit_Type: "--",
    Listings_Street_No: "--",
    Listings_Floor_No: "--",
    Listings_Category: "Villa",
    Builtup_Area: "--",
    Plot_Area: "--",
    View: "--",
    Furnished: "--",
    Transaction_Type: "Renew Leased",
    Region: "Dubai",
    Area_Location: "Reem",
    Sub_Area_Location: "Mira 5",
    Cheques: "--",
    Price: "1,65,000.00",
    Deposit: "7,900.00",
    Commission: "1,050.00",
    "Agency_Fee_Landlord/Seller": "0",
    Tenancy_Renewal_Date: "30-04-2025 00:00",
    Tenancy_Start_Date: "01-05-2024 00:00",
    Actual_Deal_Date: "28-03-2024 00:00",
    Deal_Estimated_Date: "--",
    Source_of_Deal: "--",
    Agent1: "Talal Ahmad",
    "Agent_1_Commission%": "--",
    Agent_1_Commission: "--",
    Agent2: "--",
    "Agent_2_Commission%": "--",
    Agent_2_Commission: "--",
    Agent3: "--",
    "Agent_3_Commission%": "--",
    Agent_3_Commission: "--",
    VAT_Amount: 0,
    Commission_VAT_Amount: 52.5,
    Status: "Closed",
    Sub_Status: "Successful",
    External_Referral_Commission: "--",
    Your_Company_Commission: "1,050.00",
    Buyer_Type: "--",
    Finance_Type: "--",
    Date_Added: "28-03-2024 12:30",
    Date_Updated: "28-03-2024 12:30",
  },
  {
    _id: "660fa7213644bf04fd93e6bd",
    Reference: "BY-D-3980",
    Client_ID: 1045,
    Type: "Rental",
    Created_By: "Binayah Support",
    "Landlord/Seller_Name": "--",
    "Landlord/Seller_Mobile": "--",
    "Landlord/Seller_Email": "--",
    "Landlord/Seller_Nationality": "--",
    Lead_Ref: "--",
    "Tenant/Buyer_Name": "Belen Ms.",
    "Tenant/Buyer_Mobile": "+971 551525804",
    "Tenant/Buyer_Email": "ahad@gmail.com",
    "Tenant/Buyer_Nationality": "--",
    Listings_Ref: "--",
    Listings_Beds: "--",
    Listings_UnitNo: 307,
    Listings_Unit_Type: "--",
    Listings_Street_No: "--",
    Listings_Floor_No: "--",
    Listings_Category: "Apartment",
    Builtup_Area: "--",
    Plot_Area: "--",
    View: "--",
    Furnished: "--",
    Transaction_Type: "Renew Leased",
    Region: "Dubai",
    Area_Location: "Silicon Oasis",
    Sub_Area_Location: "Axis Silver",
    Cheques: "--",
    Price: "45,150.00",
    Deposit: "--",
    Commission: "1,050.00",
    "Agency_Fee_Landlord/Seller": "0",
    Tenancy_Renewal_Date: "21-11-2024 00:00",
    Tenancy_Start_Date: "22-11-2023 00:00",
    Actual_Deal_Date: "28-03-2024 00:00",
    Deal_Estimated_Date: "--",
    Source_of_Deal: "--",
    Agent1: "Talal Ahmad",
    "Agent_1_Commission%": "--",
    Agent_1_Commission: "--",
    Agent2: "--",
    "Agent_2_Commission%": "--",
    Agent_2_Commission: "--",
    Agent3: "--",
    "Agent_3_Commission%": "--",
    Agent_3_Commission: "--",
    VAT_Amount: 0,
    Commission_VAT_Amount: 52.5,
    Status: "Closed",
    Sub_Status: "Successful",
    External_Referral_Commission: "--",
    Your_Company_Commission: "1,050.00",
    Buyer_Type: "--",
    Finance_Type: "--",
    Date_Added: "28-03-2024 12:28",
    Date_Updated: "28-03-2024 12:28",
  },
];
