import React from "react";
import AddTenants from "./AddTenants";
import CustomBreadcrumb from "../../../Common/customBreadcrumb";

const AddTenantsPage = () => {
  return (
    <>
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Add Tenants"
          home="Dashboard"
          currentPage="Add Tenants"
          addExtraPageTitle="Tenants"
          addExtraPageTitlePath="tenants"
        />
        <AddTenants />
      </div>
    </>
  );
};

export default AddTenantsPage;
