import React from "react";
import CustomBreadcrumb from "../../Common/customBreadcrumb";
import PublicFolderList from "./publicFolderList";

const PublicFolderPage = () => {
  return (
    <div className="container page-containners-main">
      <CustomBreadcrumb
        title="Manage Public Folder"
        home="Dashbord"
        currentPage="Public Folder"
        addExtraPageTitle=""
        addExtraPageTitlePath=""
      />
      <PublicFolderList />
    </div>
  );
};

export default PublicFolderPage;
