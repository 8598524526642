import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { customStyles } from "../../Common/dataTableStyle";
import { developerListData } from "../../../asset/StastucJsonData/developerLogoJsonData";
import Modal from "../../Common/ModelCommon";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";

const DeveloperLogoList = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [showAddModel, setShowAddModel] = useState(false);
  const [showEditModel, setShowEditModel] = useState(false);
  const [getIdWiseEditData, setGetIdWiseEditData] = useState([]);
  const [getId, setId] = useState();

  const initialValues = {
    title: "",
    img: "",
  };
  const initialValuesEdit = {
    title: getIdWiseEditData?.title || "",
    img: "",
  };

  const validationSchema = Yup.object()
    .shape({
      title: Yup.string().required("Field is required."),
      img: Yup.string().required("Field is required."),
    })
    .defined();

  const handleSubmitForm = (e) => {
    console.log(e);
    setShowAddModel(false);
  };
  const handleSubmitEditForm = (e) => {
    console.log(e);
    setShowEditModel(false);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = developerListData.filter((item) =>
    Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const handleCloseAddModel = () => {
    setShowAddModel(false);
  };
  const handleCloseEditModel = () => {
    setShowEditModel(false);
  };

  const getIdWiseData = (id) => {
    const findData = developerListData.find((item) => id === item?._id);
    setGetIdWiseEditData(findData);
  };

  useEffect(() => {
    getIdWiseData(getId);
  }, [getId]);

  const column = [
    {
      name: "sr no.",
      selector: (row, index) => index + 1,
      center: true,
      wrap: true,
      width: "100px",
    },
    {
      name: "title",
      selector: (row) => row.title || " - ",
      center: true,
      wrap: true,
    },
    {
      name: "Image",
      cell: (row) => (
        <>
          <div className="p-3">
            <img width={"50px"} src={row?.image_url} alt="imgs" />
          </div>
        </>
      ),
      center: true,
      wrap: true,
    },
    {
      name: "Edit",
      cell: (row) => (
        <>
          <div
            className="cursor-pointer"
            onClick={() => {
              setId(row?._id);
              setShowEditModel(true);
            }}
          >
            <img width={"20px;"} src="/svg/pencil.png" alt="img-pen" />
          </div>
        </>
      ),
      center: true,
      wrap: true,
      width: "100px",
    },
  ];

  return (
    <>
      <div className="d-flex datatable-header-main">
        <button
          type="button"
          className="btn filter_button add_property_button rounded-0"
          onClick={() => setShowAddModel(true)}
        >
          <i className="fa fa-plus me-2"></i>Add Developer Logo
        </button>
        <div className="searchbox-parant-main ">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearch}
            className="add-search-box-in-datatable rounded-0"
          />
          <i className="fa fa-search"></i>
        </div>
      </div>

      <DataTable
        data={filteredData ? filteredData : developerListData}
        customStyles={customStyles}
        columns={column}
        noHeader={false}
        highlightOnHover
        persistTableHead
        pagination
        searchable
        responsive
        noDataComponent="No data available"
      />

      {/* Add developer model */}

      <div className="model_inner">
        <Modal show={showAddModel} handleClose={handleCloseAddModel}>
          <div className="sdvds">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmitForm}
            >
              {(formik) => {
                const { setFieldValue } = formik;
                return (
                  <>
                    <Form>
                      <h5 className="mb-5">Add Developer Logo</h5>
                      <div>
                        <div className="w-100 mb-3">
                          <p className="m-0 fw-bold">Title </p>
                          <Field
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="title"
                          />
                          <ErrorMessage
                            name="title"
                            component={"div"}
                            className="text-danger"
                          />
                        </div>
                      </div>
                      <div>
                        <div className="w-100 mb-3">
                          <p className="m-0 fw-bold">Image </p>
                          <Field
                            className="form-control"
                            type="file"
                            placeholder=""
                            name="img"
                          />
                          <ErrorMessage
                            name="img"
                            component={"div"}
                            className="text-danger"
                          />
                        </div>
                      </div>
                      <div className="text-center">
                        <button type="submit" className="btn btn-secondary">
                          Submit
                        </button>
                      </div>
                    </Form>
                  </>
                );
              }}
            </Formik>
          </div>
        </Modal>
      </div>

      {/* Edit developer model */}

      <div className="model_inner">
        <Modal show={showEditModel} handleClose={handleCloseEditModel}>
          <div className="sdvds">
            <Formik
              initialValues={initialValuesEdit}
              validationSchema={validationSchema}
              onSubmit={handleSubmitEditForm}
            >
              {() => {
                return (
                  <>
                    <Form>
                      <h5 className="mb-5">Edit Developer Logo</h5>
                      <div>
                        <div className="w-100 mb-3">
                          <p className="m-0 fw-bold">Title </p>
                          <Field
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="title"
                          />
                          <ErrorMessage
                            name="title"
                            component={"div"}
                            className="text-danger"
                          />
                        </div>
                      </div>
                      <div>
                        <div className="w-100 mb-3 text-center">
                          <p className="m-0 fw-bold">Current Image </p>
                          <div>
                            <img
                              className="current-img-show"
                              src={getIdWiseEditData?.image_url}
                              alt="imgs"
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="w-100 mb-3">
                          <p className="m-0 fw-bold">Image </p>
                          <Field
                            className="form-control"
                            type="file"
                            placeholder=""
                            name="img"
                          />
                          <ErrorMessage
                            name="img"
                            component={"div"}
                            className="text-danger"
                          />
                        </div>
                      </div>
                      <div className="text-center">
                        <button type="submit" className="btn btn-secondary">
                          Submit
                        </button>
                      </div>
                    </Form>
                  </>
                );
              }}
            </Formik>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default DeveloperLogoList;
