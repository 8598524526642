import React from "react";
import CustomBreadcrumb from "../../../Common/customBreadcrumb";
import AddOwner from "./AddOwnerList";

const AddOwnerPage = () => {
  return (
    <>
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Add Owner"
          home="Dashboard"
          currentPage="Add Owner"
          addExtraPageTitle="Owner"
          addExtraPageTitlePath="owners"
        />
        <AddOwner />
      </div>
    </>
  );
};

export default AddOwnerPage;
