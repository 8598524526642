import React from "react";
import DataTable from "react-data-table-component";
import { customStyles } from "../../Common/dataTableStyle";
import { WebsiteLeads } from "../../../asset/StastucJsonData/WebsiteLeadsJsonData";

const PaidLeads1List = () => {
  const column = [
    {
      name: "Name",

      selector: (row) => row?.name,
      center: true,
      wrap: true,
    },
    {
      name: "Mobile",
      selector: (row) => row?.mobile,
      center: true,
      wrap: true,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
      center: true,
      wrap: true,
      width: "200px",
    },

    {
      name: "Project Name",
      selector: (row) => row?.project_name,
      center: true,
      wrap: true,
    },
    {
      name: "Message",
      cell: (row) => (
        <div className="text-start pl-2 font-monospace">{row?.message}</div>
      ),
      center: true,
      wrap: true,
      width: "300px",
    },
  ];

  return (
    <>
      <DataTable
        data={WebsiteLeads}
        columns={column}
        customStyles={customStyles}
        fixedHeader={true}
        noHeader={false}
        noDataComponent="No data available"
        pagination
        searchable
        responsive
        highlightOnHover
        persistTableHead
      />
    </>
  );
};

export default PaidLeads1List;
