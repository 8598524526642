import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { customStyles } from "../../Common/dataTableStyle";
import { oldDealsListData } from "../../../asset/StastucJsonData/OldDealsJsonData";
import { Link } from "react-router-dom";

const ListOldDeals = () => {
  const [searchQuery, setSearchQuery] = useState("");

  const oldDealsListDatas = oldDealsListData || [];

  const filteredData = oldDealsListData.filter((item) =>
    Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const column = [
    {
      name: "sr.",
      cell: (row, index) => (
        <>
          <div>{index + 1}</div>
        </>
      ),
      center: true,
      wrap: true,
      width: "50px",
    },
    {
      name: "Created by",
      selector: (row) => row?.Created_By || " - ",
      center: true,
      wrap: true,
    },
    {
      name: "Unit No.",
      selector: (row) => row?.Listings_UnitNo || " - ",
      center: true,
      wrap: true,
      width: "80px",
    },
    {
      name: "Project",
      selector: (row) => row?.Project || " - ",
      center: true,
      wrap: true,
    },
    {
      name: "Deal Price",
      selector: (row) => row?.Price || " - ",
      center: true,
      wrap: true,
    },
    {
      name: "Agent",
      selector: (row) => row?.Agent1 || " - ",
      center: true,
      wrap: true,
    },
    {
      name: "Deal Date",
      selector: (row) => row?.Actual_Deal_Date || " - ",
      center: true,
      wrap: true,
    },
    {
      name: "Category",
      selector: (row) => row?.Listings_Category || " - ",
      center: true,
      wrap: true,
    },
    {
      name: "Beds",
      selector: (row) => row?.Listings_Beds || " - ",
      center: true,
      wrap: true,
      width: "50px",
    },
    {
      name: "Locatio",
      selector: (row) => row?.Area_Location || " - ",
      center: true,
      wrap: true,
    },
    {
      name: "Sub Location",
      selector: (row) => row?.Sub_Area_Location || " - ",
      center: true,
      wrap: true,
    },
    {
      name: "Tenancy Renewal Date",
      selector: (row) => row?.Tenancy_Renewal_Date || " - ",
      center: true,
      wrap: true,
      width: "150px",
    },
    {
      name: "View",
      cell: (row) => (
        <>
          <Link to={`/old-deals-details/${row._id}`}>
            <div className="cursor-pointer">
              <img width={"20px;"} src="/svg/eye.png" alt="img-eye" />
            </div>
          </Link>
        </>
      ),
      center: true,
      wrap: true,
      width: "60px",
    },
  ];

  return (
    <>
      <div className="my-3">
        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-12 mb-2 search-box-datatable-search">
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearch}
              className="add-search-box-in-datatable rounded-0"
            />
            <i className="fa fa-search"></i>
          </div>
        </div>
      </div>
      <DataTable
        data={filteredData ? filteredData : oldDealsListDatas}
        columns={column}
        customStyles={customStyles}
        fixedHeader={true}
        noHeader={false}
        noDataComponent="No data available"
        pagination
        searchable
        responsive
        highlightOnHover
        persistTableHead
      />
    </>
  );
};

export default ListOldDeals;
