import React from "react";
import CustomBreadcrumb from "../../../Common/customBreadcrumb";
import AllDealsDetailsComponent from "./AllDealsDetails";

const AllDealsDetails = () => {
  return (
    <>
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="All Deals Details"
          home="Dashboard"
          currentPage="All Deals Details"
          addExtraPageTitle="All Deals"
          addExtraPageTitlePath="all-deals"
        />
        <AllDealsDetailsComponent />
      </div>
    </>
  );
};

export default AllDealsDetails;
