// CALL LEAD STATIC DATA...

export const callLeadData = [
  {
    caller_id: "+971529285523",
    recording: true,
    call_date: "08-06-2023 17:32",
    call_time: "0:37",
    agent_name: "Bilal Ahmad",
    lead: "BY-L-125146",
    cantact: "BY-O-96928",
    status: "Not answered",
    start_time: "09:17:07",
    end_time: "09:17:44",
    talk_duration: "0:00",
    wait_time: " - ",
    comment:
      "Hii.. how are you?, Ohh... I can't understand what you trying to say. Sorry!,",
    url_recording:
      "https://commondatastorage.googleapis.com/codeskulptor-assets/week7-brrring.m4a",
  },
  {
    caller_id: "+971502110979",
    recording: true,
    call_date: "08-06-2023 17:32",
    call_time: "0:37",
    agent_name: "Bilal Ahmad",
    lead: "BY-L-125146",
    cantact: "BY-O-96928",
    status: "Not answered",
    start_time: "12:16:43",
    end_time: "12:24:09",
    talk_duration: "0:00",
    wait_time: " - ",
    comment: "",
    url_recording:
      "https://commondatastorage.googleapis.com/codeskulptor-assets/week7-brrring.m4a",
  },
  {
    caller_id: "+971585408103",
    recording: false,
    call_date: "08-06-2023 17:32",
    call_time: "0:37",
    agent_name: "Bilal Ahmad",
    lead: "BY-L-125146",
    cantact: "BY-O-96928",
    status: "Not answered",
    start_time: "09:17:07",
    end_time: "09:17:44",
    talk_duration: "0:00",
    wait_time: " - ",
    comment:
      "Hii.. how are you?, Ohh... I can't understand what you trying to say. Sorry!,",
    url_recording: "",
  },
  {
    caller_id: "+33663990304",
    recording: true,
    call_date: "08-06-2023 17:32",
    call_time: "0:37",
    agent_name: "Bilal Ahmad",
    lead: "BY-L-125146",
    cantact: "BY-O-96928",
    status: "Not answered",
    start_time: "12:16:43",
    end_time: "12:24:09",
    talk_duration: "0:00",
    wait_time: " - ",
    comment: "",
    url_recording:
      "https://commondatastorage.googleapis.com/codeskulptor-assets/week7-brrring.m4a",
  },
];

// CALL LEAD FILTER AGENTS OPTIONS...

export const filterOptionsForDataTable = [
  {
    agent_filter_option: [
      {
        id: 1,
        option: "Ahmed A.",
      },
      {
        id: 2,
        option: "Ahmed Subhan",
      },
      {
        id: 3,
        option: "Anil Ashwin",
      },
      {
        id: 4,
        option: "Ashwin Prasaad",
      },
      {
        id: 5,
        option: "Bekhzodbek U.",
      },
      {
        id: 6,
        option: "Bilal Ahmed",
      },
      {
        id: 7,
        option: "Binayah Support",
      },
      {
        id: 8,
        option: "Danish Ahmed",
      },
      {
        id: 9,
        option: "Faizan Khan",
      },
      {
        id: 10,
        option: "Hardip Gadara",
      },
      {
        id: 11,
        option: "Jeff John",
      },
      {
        id: 12,
        option: "Jennifer E.",
      },
    ],
  },
];

// CALL LEAD FILTER STATUS OPTIONS...

export const filterStatusOption = [
  {
    status_option: [
      {
        id: "1",
        option: "Voicemail",
      },
      {
        id: "2",
        option: "Answered",
      },
      {
        id: "3",
        option: "Not Answered",
      },
      {
        id: "4",
        option: "Canceled",
      },
    ],
  },
];
