// ALL LISTING FILTER OPTION STATIC DATA...

export const filterListdata = [
  {
    add_property: [
      {
        id: 1,
        option: "Rental",
      },
      {
        id: 2,
        option: "Sale",
      },
    ],
    filter_by_agent: [
      {
        id: 1,
        option: "Ahmed",
      },
      {
        id: 2,
        option: "Ahmed A.",
      },
      {
        id: 3,
        option: "Ahmmed M",
      },
      {
        id: 4,
        option: "Anastasiia Savaryna",
      },
      {
        id: 5,
        option: "Angel",
      },
      {
        id: 6,
        option: "Ashwin Anil",
      },
      {
        id: 7,
        option: "Aswin Prasaad",
      },
    ],
    filter_by_status: [
      {
        id: 1,
        option: "Published",
      },
      {
        id: 2,
        option: "Unpublished",
      },
      {
        id: 3,
        option: "Pending Approval",
      },
      {
        id: 4,
        option: "Draft",
      },
    ],
    filter_by_portal: [
      {
        id: 1,
        option: "Propertyfinder",
      },
      {
        id: 2,
        option: "Bayut & Dubizzle",
      },
      {
        id: 3,
        option: "Binayah Website",
      },
    ],
    filter_by_property: [
      {
        id: 1,
        option: "Rent",
      },
      {
        id: 2,
        option: "Sale",
      },
    ],
    filter_by_bedrooms: [
      {
        id: 1,
        option: "Studio",
      },
      {
        id: 2,
        option: "1 Bed",
      },
      {
        id: 3,
        option: "2 Beds",
      },
      {
        id: 4,
        option: "3 Beds",
      },
      {
        id: 5,
        option: "4 Beds",
      },
      {
        id: 6,
        option: "5 Beds",
      },
      {
        id: 7,
        option: "6 Beds",
      },
      {
        id: 8,
        option: "7 Beds",
      },
      {
        id: 9,
        option: "8 Beds",
      },
      {
        id: 10,
        option: "9 Beds",
      },
      {
        id: 11,
        option: "10 Beds",
      },
    ],
    filter_by_emitares: [
      {
        id: 1,
        option: "Dubai",
      },
      {
        id: 2,
        option: "Abu Dhabi",
      },
      {
        id: 3,
        option: "Sharjah",
      },
      {
        id: 4,
        option: "Ajman",
      },
      {
        id: 5,
        option: "Umm Al Quwain",
      },
      {
        id: 6,
        option: "Ras Al Khaimah",
      },
      {
        id: 7,
        option: "Fujairah",
      },
      {
        id: 8,
        option: "Al Ain",
      },
    ],

    filter_by_location: [
      {
        id: 1,
        option: "Abu Dhabi Gate City",
      },
      {
        id: 2,
        option: "Abu shagara",
      },
      {
        id: 3,
        option: "Academic City",
      },
      {
        id: 4,
        option: "Afnan District",
      },
      {
        id: 5,
        option: "Ain Ajman",
      },
    ],

    filter_by_sublocation: [
      {
        id: 1,
        option: "1 JBR",
      },
      {
        id: 2,
        option: "10C Building",
      },
      {
        id: 3,
        option: "15 Northside",
      },
      {
        id: 4,
        option: "151 Residence",
      },
      {
        id: 5,
        option: "17 Icon Bay",
      },
      {
        id: 6,
        option: "18 Villas Complex",
      },
      {
        id: 7,
        option: "2 Bedroom Villas",
      },
      {
        id: 8,
        option: "20 Villas Project",
      },
    ],

    filter_by_project: [
      {
        id: 1,
        option: "1 Residences",
      },
      {
        id: 2,
        option: "1095 Residence",
      },
      {
        id: 3,
        option: "118 Downtown",
      },
      {
        id: 4,
        option: "12 Villas Project",
      },
      {
        id: 5,
        option: "17 Villas Project",
      },
      {
        id: 6,
        option: "18 Burj Boulevard",
      },
      {
        id: 7,
        option: "2 Bedroom Villas",
      },
      {
        id: 8,
        option: "21 Villas Project",
      },
      {
        id: 9,
        option: "27 Tower",
      },
    ],

    filter_by_price_range: [
      {
        id: 1,
        option: "AED 30-50k",
      },
      {
        id: 2,
        option: "AED 50-100k",
      },
      {
        id: 3,
        option: "AED 100-200k",
      },
      {
        id: 4,
        option: "AED 200-500k",
      },
    ],
    filter_by_ref_no: [
      {
        id: 1,
        option: "BY-R-13846",
      },
      {
        id: 2,
        option: "BY-R-13839",
      },
      {
        id: 3,
        option: "BY-R-13834",
      },
      {
        id: 4,
        option: "BY-R-13833",
      },
      {
        id: 5,
        option: "BY-R-13847",
      },
      {
        id: 6,
        option: "BY-R-13824",
      },
      {
        id: 7,
        option: "BY-R-13826",
      },
      {
        id: 8,
        option: "BY-R-13842",
      },
    ],

    filter_by_category: [
      {
        id: 1,
        option: "Apartment(RR)",
      },
      {
        id: 2,
        option: "Apartment(RS)",
      },
      {
        id: 3,
        option: "Bulk Rent Units(CR)",
      },
      {
        id: 4,
        option: "Bulk Rent Units(RR)",
      },
      {
        id: 5,
        option: "Bulk Rent Units(CS)",
      },
    ],

    select_agent: [
      {
        id: 1,
        option: "Ahmed",
      },
      {
        id: 2,
        option: "Ahmed A",
      },
      {
        id: 3,
        option: "Ahmed M",
      },
      {
        id: 4,
        option: "Anastasiia Savaryna",
      },
      {
        id: 5,
        option: "Angel",
      },
      {
        id: 6,
        option: "Ashwin Anil",
      },
      {
        id: 7,
        option: "Aswin Prasaad",
      },
    ],

    select_status: [
      {
        id: 1,
        option: "Published",
      },
      {
        id: 2,
        option: "Unpublished",
      },
      {
        id: 3,
        option: "Pending Approval",
      },
      {
        id: 4,
        option: "Draft",
      },
    ],
  },
];

// DATA TABLE LIST STATIC DATA...

export const dataTableListData = [
  {
    user_id: 1,
    created_at: "10-07-2024 23:38",
    updated_at: "11-07-2024 1:38",
    ref_no: "BY-R-13849",
    unit: "725",
    category: "Hotel Appartment",
    location: "Al Furjan",
    s_location: "Azizi Plaza",
    beds: "0",
    price: "46,000",
    agent: "Masood Ahmad",
  },
  {
    user_id: 2,
    created_at: "10-07-2024 23:38",
    updated_at: "11-07-2024 1:38",
    ref_no: "BY-R-13849",
    unit: "725",
    category: "Hotel Appartment",
    location: "Al Furjan",
    s_location: "Azizi Plaza",
    beds: "0",
    price: "46,000",
    agent: "Masood Ahmad",
  },
  {
    user_id: 3,
    created_at: "10-07-2024 23:38",
    updated_at: "11-07-2024 1:38",
    ref_no: "BY-R-13849",
    unit: "725",
    category: "Hotel Appartment",
    location: "Al Furjan",
    s_location: "Azizi Plaza",
    beds: "0",
    price: "46,000",
    agent: "Masood Ahmad",
  },
  {
    user_id: 4,
    created_at: "10-07-2024 23:38",
    updated_at: "11-07-2024 1:38",
    ref_no: "BY-R-13849",
    unit: "725",
    category: "Hotel Appartment",
    location: "Al Furjan",
    s_location: "Azizi Plaza",
    beds: "0",
    price: "46,000",
    agent: "Masood Ahmad",
  },
  {
    user_id: 5,
    created_at: "10-07-2024 23:38",
    updated_at: "11-07-2024 1:38",
    ref_no: "BY-R-13849",
    unit: "725",
    category: "Hotel Appartment",
    location: "Al Furjan",
    s_location: "Azizi Plaza",
    beds: "0",
    price: "46,000",
    agent: "Masood Ahmad",
  },
  {
    user_id: 6,
    created_at: "10-07-2024 23:38",
    updated_at: "11-07-2024 1:38",
    ref_no: "BY-R-13849",
    unit: "725",
    category: "Hotel Appartment",
    location: "Al Furjan",
    s_location: "Azizi Plaza",
    beds: "0",
    price: "46,000",
    agent: "Masood Ahmad",
  },
  {
    user_id: 7,
    created_at: "10-07-2024 23:38",
    updated_at: "11-07-2024 1:38",
    ref_no: "BY-R-13849",
    unit: "725",
    category: "Hotel Appartment",
    location: "Al Furjan",
    s_location: "Azizi Plaza",
    beds: "0",
    price: "46,000",
    agent: "Masood Ahmad",
  },
  {
    user_id: 8,
    created_at: "10-07-2024 23:38",
    updated_at: "11-07-2024 1:38",
    ref_no: "BY-R-13849",
    unit: "725",
    category: "Hotel Appartment",
    location: "Al Furjan",
    s_location: "Azizi Plaza",
    beds: "0",
    price: "46,000",
    agent: "Masood Ahmad",
  },
  {
    user_id: 9,
    created_at: "10-07-2024 23:38",
    updated_at: "11-07-2024 1:38",
    ref_no: "BY-R-13849",
    unit: "725",
    category: "Hotel Appartment",
    location: "Al Furjan",
    s_location: "Azizi Plaza",
    beds: "0",
    price: "46,000",
    agent: "Masood Ahmad",
  },
  {
    user_id: 10,
    created_at: "10-07-2024 23:38",
    updated_at: "11-07-2024 1:38",
    ref_no: "BY-R-13849",
    unit: "725",
    category: "Hotel Appartment",
    location: "Al Furjan",
    s_location: "Azizi Plaza",
    beds: "0",
    price: "46,000",
    agent: "Masood Ahmad",
  },
  {
    user_id: 11,
    created_at: "10-07-2024 23:38",
    updated_at: "11-07-2024 1:38",
    ref_no: "BY-R-13849",
    unit: "725",
    category: "Hotel Appartment",
    location: "Al Furjan",
    s_location: "Azizi Plaza",
    beds: "0",
    price: "46,000",
    agent: "Masood Ahmad",
  },
  {
    user_id: 12,
    created_at: "10-07-2024 23:38",
    updated_at: "11-07-2024 1:38",
    ref_no: "BY-R-13849",
    unit: "725",
    category: "Hotel Appartment",
    location: "Al Furjan",
    s_location: "Azizi Plaza",
    beds: "0",
    price: "46,000",
    agent: "Masood Ahmad",
  },
  {
    user_id: 13,
    created_at: "10-07-2024 23:38",
    updated_at: "11-07-2024 1:38",
    ref_no: "BY-R-13849",
    unit: "725",
    category: "Hotel Appartment",
    location: "Al Furjan",
    s_location: "Azizi Plaza",
    beds: "0",
    price: "46,000",
    agent: "Masood Ahmad",
  },
  {
    user_id: 14,
    created_at: "10-07-2024 23:38",
    updated_at: "11-07-2024 1:38",
    ref_no: "BY-R-13849",
    unit: "725",
    category: "Hotel Appartment",
    location: "Al Furjan",
    s_location: "Azizi Plaza",
    beds: "0",
    price: "46,000",
    agent: "Masood Ahmad",
  },
  {
    user_id: 15,
    created_at: "10-07-2024 23:38",
    updated_at: "11-07-2024 1:38",
    ref_no: "BY-R-13849",
    unit: "725",
    category: "Hotel Appartment",
    location: "Al Furjan",
    s_location: "Azizi Plaza",
    beds: "0",
    price: "46,000",
    agent: "Masood Ahmad",
  },
  {
    user_id: 16,
    created_at: "10-07-2024 23:38",
    updated_at: "11-07-2024 1:38",
    ref_no: "BY-R-13849",
    unit: "725",
    category: "Hotel Appartment",
    location: "Al Furjan",
    s_location: "Azizi Plaza",
    beds: "0",
    price: "46,000",
    agent: "Masood Ahmad",
  },
  {
    user_id: 17,
    created_at: "10-07-2024 23:38",
    updated_at: "11-07-2024 1:38",
    ref_no: "BY-R-13849",
    unit: "725",
    category: "Hotel Appartment",
    location: "Al Furjan",
    s_location: "Azizi Plaza",
    beds: "0",
    price: "46,000",
    agent: "Masood Ahmad",
  },
  {
    user_id: 18,
    created_at: "10-07-2024 23:38",
    updated_at: "11-07-2024 1:38",
    ref_no: "BY-R-13849",
    unit: "725",
    category: "Hotel Appartment",
    location: "Al Furjan",
    s_location: "Azizi Plaza",
    beds: "0",
    price: "46,000",
    agent: "Masood Ahmad",
  },
  {
    user_id: 19,
    created_at: "10-07-2024 23:38",
    updated_at: "11-07-2024 1:38",
    ref_no: "BY-R-13849",
    unit: "555",
    category: "Hotel Appartment",
    location: "Al Furjan",
    s_location: "Azizi Plaza",
    beds: "0",
    price: "46,000",
    agent: "Masood Ahmad",
  },
  {
    user_id: 20,
    created_at: "10-07-2024 23:38",
    updated_at: "11-07-2024 1:38",
    ref_no: "BY-R-13849",
    unit: "444",
    category: "Hotel Appartment",
    location: "Al Furjan",
    s_location: "Azizi Plaza",
    beds: "0",
    price: "46,000",
    agent: "Masood Ahmad",
  },
  {
    user_id: 21,
    created_at: "10-07-2024 23:38",
    updated_at: "11-07-2024 1:38",
    ref_no: "BY-R-13849",
    unit: "333",
    category: "Hotel Appartment",
    location: "Al Furjan",
    s_location: "Azizi Plaza",
    beds: "0",
    price: "46,000",
    agent: "Masood Ahmad",
  },
  {
    user_id: 22,
    created_at: "10-07-2024 23:38",
    updated_at: "11-07-2024 1:38",
    ref_no: "BY-R-13849",
    unit: "222",
    category: "Hotel Appartment",
    location: "Al Furjan",
    s_location: "Azizi Plaza",
    beds: "0",
    price: "46,000",
    agent: "Masood Ahmad",
  },
  {
    user_id: 23,
    created_at: "10-07-2024 23:38",
    updated_at: "11-07-2024 1:38",
    ref_no: "BY-R-13849",
    unit: "111",
    category: "Hotel Appartment",
    location: "Al Furjan",
    s_location: "Azizi Plaza",
    beds: "0",
    price: "46,000",
    agent: "Masood Ahmad",
  },
  {
    user_id: 24,
    created_at: "10-07-2024 23:38",
    updated_at: "11-07-2024 1:38",
    ref_no: "BY-R-13849",
    unit: "725",
    category: "Hotel Appartment",
    location: "Al Furjan",
    s_location: "Azizi Plaza",
    beds: "0",
    price: "46,000",
    agent: "Masood Ahmad",
  },
];
