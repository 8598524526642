import React from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import {
  CommonInputField,
  FormikCanselButton,
  FormikSelectField,
  FormikSubmitButton,
} from "../../../Common/CommonFromikFields";

const EditAllListing = () => {
  const initialValues = {
    lead_id: "",
    property_number: "",
    name: "",
    create_by: "",
    lead_type: "",
    source: "",
    agent: "",
    team_lead: "",
    owner: "",
    email: "",
    phone: "",
    proprty: "",
    status: "",
    sub_status: "",
    priority: "",
    hot_lead: "",
    type: "",
    category: "",
    unit_no: "",
    price: "",
    bedrooms: "",
    emirates: "",
    project: "",
    location: "",
    sub_locations: "",
    country: "",
    transcript: "",
    lead_notes: "",
  };

  const validationSchema = Yup.object()
    .shape({
      lead_id: Yup.string().required("Lead id is required."),
      property_number: Yup.string().required("Property number is required."),
      name: Yup.string().required("Name is required."),
      create_by: Yup.string().required("Create by is required."),
      lead_type: Yup.string().required("Lead type is required."),
      source: Yup.string().required("Source is required."),
      agent: Yup.string().required("agent is required."),
      team_lead: Yup.string().required("Team lead is required."),
      owner: Yup.string().required("Team lead is required."),
      email: Yup.string().email().required("Email is required"),
      phone: Yup.string()
        .matches(/[0-9]/, "Telephone must be a numbers only.")
        .required("Phone number is required."),
      proprty: Yup.string().required("Proprty is required"),
      status: Yup.string().required("status is required"),
      sub_status: Yup.string().required("Sub Status is required"),
      priority: Yup.string().required("Priority is required"),
      hot_lead: Yup.string().required("Priority is required"),
      type: Yup.string().required("Type is required"),
      category: Yup.string().required("Category is required"),
      unit_no: Yup.string().required("Category is required"),
      price: Yup.string().required("Price is required"),
      bedrooms: Yup.string().required("Bedrooms is required"),
      emirates: Yup.string().required("Emirates is required"),
      project: Yup.string().required("Project is required"),
      location: Yup.string().required("Location is required"),
      sub_locations: Yup.string().required("Sub locations is required"),
      country: Yup.string().required("Country is required"),
      transcript: Yup.string().required("Transcript is required"),
      lead_notes: Yup.string().required("Lead notes is required"),
    })
    .defined();

  const handleSubmitForm = (e) => {
    console.log("data", e);
  };

  return (
    <>
      <div>
        <div className="mb-4 border-bottom-1">
          Fieds Marked with (<span className="text-danger"> * </span>) are
          Mandatory
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange
          onSubmit={handleSubmitForm}
        >
          {() => {
            return (
              <>
                <Form>
                  <div className="row">
                    <CommonInputField
                      lable={"Lead Id"}
                      name={"lead_id"}
                      placeholder="BY-R-13849"
                    />

                    <FormikSelectField
                      label={"Property Refference Number"}
                      name="property_number"
                      options={[]}
                      placeholder={"Property Refference Number"}
                    />

                    <CommonInputField
                      lable={"name"}
                      placeholder="Enter Name"
                      name="name"
                    />

                    <FormikSelectField
                      label="Created by"
                      name="create_by"
                      options={[]}
                      placeholder={"Select Create By"}
                    />

                    <FormikSelectField
                      label="Lead Type"
                      name="lead_type"
                      options={[]}
                      placeholder={"Select LeadType"}
                    />

                    <FormikSelectField
                      label="Source"
                      name="source"
                      options={[]}
                      placeholder="Select Source"
                    />

                    <FormikSelectField
                      label="Agent"
                      name="agent"
                      options={[]}
                      placeholder="Select Agent"
                    />

                    <FormikSelectField
                      label="Team Leader"
                      name="team_lead"
                      options={[]}
                      placeholder="Select TeamLead"
                    />

                    <FormikSelectField
                      label="Owner"
                      name="owner"
                      options={[]}
                      placeholder="Select Ownner"
                    />

                    <CommonInputField
                      lable={"Email"}
                      placeholder="Enter Email"
                      name="email"
                    />

                    <CommonInputField
                      lable={"Phone"}
                      placeholder="Enter Phone"
                      name="phone"
                    />

                    <div className="col-lg-4 col-md-6 com-sm-12 mb-2">
                      <p className="m-0">Property </p>
                      <Field
                        className="form-control rounded-0"
                        type="text"
                        placeholder="proprty"
                        name="proprty"
                        as="textarea"
                        rows="1"
                        cols="50"
                      />
                      <ErrorMessage
                        name="proprty"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    <FormikSelectField
                      label="Status"
                      name="status"
                      options={[]}
                      placeholder="Select Status"
                    />

                    <FormikSelectField
                      label="Sub Status"
                      name="sub_status"
                      options={[]}
                      placeholder="Select SubStatus"
                    />

                    <FormikSelectField
                      label="Priority"
                      name="priority"
                      options={[]}
                      placeholder="Select Priority"
                    />

                    <FormikSelectField
                      label="Hot Lead"
                      name="hot_lead"
                      options={[]}
                      placeholder="Select Hot Lead"
                    />

                    <FormikSelectField
                      label="Type"
                      name="type"
                      options={[]}
                      placeholder="Select Type"
                    />

                    <FormikSelectField
                      label="Category"
                      name="category"
                      options={[]}
                      placeholder="Select Category"
                    />

                    <CommonInputField
                      lable="Unit No."
                      name="unit_no"
                      placeholder="Unit No."
                    />

                    <CommonInputField
                      lable="Price"
                      name="price"
                      placeholder="Price"
                    />

                    <FormikSelectField
                      label="Bedrooms"
                      name="bedrooms"
                      options={[]}
                      placeholder="Select Bedrooms"
                    />

                    <FormikSelectField
                      label="Emirates"
                      name="emirates"
                      options={[]}
                      placeholder="Select Emirates"
                    />

                    <FormikSelectField
                      label="Project"
                      name="project"
                      options={[]}
                      placeholder="Select Project"
                    />

                    <FormikSelectField
                      label="Location"
                      name="location"
                      options={[]}
                      placeholder="Select Location"
                    />

                    <FormikSelectField
                      label="Sublocation"
                      name="sub_locations"
                      options={[]}
                      placeholder="Select Sublocation"
                    />

                    <CommonInputField
                      lable="Country"
                      name="country"
                      placeholder="Country"
                    />

                    <CommonInputField
                      lable="Transcript"
                      name="transcript"
                      placeholder="Transcript"
                    />

                    <div className="col-lg-4 col-md-6 com-sm-12 mb-2">
                      <p className="m-0"> Lead Notes </p>
                      <Field
                        className="form-control rounded-0"
                        type="text"
                        placeholder="Email"
                        name="lead_notes"
                        as="textarea"
                        rows="1"
                        cols="50"
                      />
                      <ErrorMessage
                        name="lead_notes"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>
                  <div className="text-center my-5">
                    <FormikSubmitButton />
                    <FormikCanselButton path="/all-listing" />
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default EditAllListing;
