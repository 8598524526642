// PUBLIC FOLDER STATIC LIST DATA...

export const publicFolderListData = [
  {
    _id: "1",
    file_name: "account-6491185_1280.png",
    file_size: "70.81 KB",
  },
  {
    _id: "2",
    file_name: "account-6491185_1280.png",
    file_size: "70.81 KB",
  },
  {
    _id: "3",
    file_name: "account-6491185_1280.png",
    file_size: "70.81 KB",
  },
  {
    _id: "4",
    file_name: "account-6491185_1280.png",
    file_size: "70.81 KB",
  },
  {
    _id: "5",
    file_name: "account-6491185_1280.png",
    file_size: "70.81 KB",
  },
  {
    _id: "6",
    file_name: "account-6491185_1280.png",
    file_size: "70.81 KB",
  },
  {
    _id: "7",
    file_name: "account-6491185_1280.png",
    file_size: "70.81 KB",
  },
  {
    _id: "8",
    file_name: "account-6491185_1280.png",
    file_size: "70.81 KB",
  },
  {
    _id: "9",
    file_name: "account-6491185_1280.png",
    file_size: "70.81 KB",
  },
  {
    _id: "10",
    file_name: "account-6491185_1280.png",
    file_size: "70.81 KB",
  },
];
