import React, { useState } from "react";
import "../../../asset/css/pages/login.css";

const Login = () => {
  const [activeTab, setActiveTab] = useState("v-pills-home");

  const [previousTab, setPreviousTab] = useState(null);

  const handleTabChange = (tab) => {
    setPreviousTab(activeTab);
    setTimeout(() => {
      setActiveTab(tab);
    }, 300);
  };

  return (
    <>
      <div className="main-bg main_login">
        <div className="box-conatiner">
          <div id="a">
            <div className="circle-ripple"></div>
          </div>

          <div className="row height-100 main-login-sub-containner w-100">
            <div className="col-md-6 col-sm-6 show-log-in-form-name">
              {/* <h1 className="heading-left">For Continue Please Login </h1> */}
              <h1 className="heading-left">
                {activeTab === "v-pills-home" ? "Login as admin" : " "}
                {activeTab === "v-pills-profile" ? "Login as agent" : " "}
                {activeTab === "v-pills-messages"
                  ? "Login as team leader"
                  : " "}
                {activeTab === "v-pills-settings"
                  ? "Login as property manager"
                  : " "}
                {activeTab === "v-pills-user" ? "Login as user" : " "}
                {activeTab === "v-pills-sign-up" ? "Sign up user" : " "}
              </h1>
            </div>
            <div className="col-sm-6 col-md-6 login-page-containneres">
              <div className="row component-login-page">
                <div className="col-4 my-5 pr-0 component-login-page-tab-main">
                  <div
                    className="nav flex-column nav-pills"
                    aria-orientation="vertical"
                  >
                    <a
                      className={`nav-link ${
                        activeTab === "v-pills-home" ? "active" : ""
                      }`}
                      onClick={() => handleTabChange("v-pills-home")}
                    >
                      <div className="tooltip-container">
                        <img
                          width={"30px"}
                          src="/svg/admin-img.png"
                          alt="Admin"
                        />
                        <div className="tooltip-text"> Admin</div>
                      </div>
                    </a>
                    <a
                      className={`nav-link ${
                        activeTab === "v-pills-profile" ? "active" : ""
                      }`}
                      onClick={() => handleTabChange("v-pills-profile")}
                    >
                      <div className="tooltip-container">
                        <img width={"30px"} src="/svg/agent.svg" alt="Admin" />
                        <div className="tooltip-text"> Agent</div>
                      </div>
                    </a>
                    <a
                      className={`nav-link ${
                        activeTab === "v-pills-messages" ? "active" : ""
                      }`}
                      onClick={() => handleTabChange("v-pills-messages")}
                    >
                      <div className="tooltip-container">
                        <img width={"30px"} src="/svg/leader.svg" alt="Admin" />
                        <div className="tooltip-text"> Teamleader</div>
                      </div>
                    </a>
                    <a
                      className={`nav-link ${
                        activeTab === "v-pills-settings" ? "active" : ""
                      }`}
                      onClick={() => handleTabChange("v-pills-settings")}
                    >
                      <div className="tooltip-container">
                        <img
                          width={"30px"}
                          src="/svg/businessman.png"
                          alt="Admin"
                        />
                        <div className="tooltip-text"> Property Manager</div>
                      </div>
                    </a>
                    <a
                      className={`nav-link ${
                        activeTab === "v-pills-user" ? "active" : ""
                      }`}
                      onClick={() => handleTabChange("v-pills-user")}
                    >
                      <div className="tooltip-container">
                        <img width={"30px"} src="/svg/user.svg" alt="Admin" />
                        <div className="tooltip-text"> User</div>
                      </div>
                    </a>
                  </div>
                </div>

                <div className="col-8 pl-0 main-login-form-divs">
                  <div className="tab-content">
                    {activeTab === "v-pills-home" && (
                      <div
                        className={`tab-pane fade ${
                          activeTab === "v-pills-home"
                            ? "show active fade-out"
                            : ""
                        } ${previousTab === "v-pills-home" ? "fade-out" : ""}`}
                      >
                        <div className="wrap-login100">
                          <span className="login100-form-title"> Sign In</span>
                          <form className="login100-form validate-form p-l-55 p-r-55 p-t-20">
                            <div
                              className="wrap-input100 validate-input m-b-16"
                              data-validate="Please enter username"
                            >
                              <input
                                className="input100"
                                type="text"
                                name="username"
                                placeholder="Username"
                              />
                              <span className="focus-input100"></span>
                            </div>
                            <div
                              className="wrap-input100 validate-input"
                              data-validate="Please enter password"
                            >
                              <input
                                className="input100"
                                type="password"
                                name="pass"
                                placeholder="Password"
                              />
                              <span className="focus-input100"></span>
                            </div>
                            <div className="text-right p-t-13 p-b-23">
                              <span className="txt1"></span>
                              <a href="#" className="txt2">
                                Forgot Password
                              </a>
                            </div>
                            <div className="container-login100-form-btn">
                              <button className="login100-form-btn">
                                Sign in
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    )}
                    {activeTab === "v-pills-sign-up" && (
                      <div
                        className={`tab-pane fade  ${
                          activeTab === "v-pills-sign-up"
                            ? "show active fade-out"
                            : ""
                        } ${
                          previousTab === "v-pills-sign-up" ? "fade-out" : ""
                        }`}
                      >
                        <div className="wrap-login100">
                          <span className="login100-form-title"> Sign Up </span>
                          <form className="login100-form validate-form p-l-55 p-r-55 p-t-20">
                            <div
                              className="wrap-input100 validate-input mb-2"
                              data-validate="Please enter username"
                            >
                              <input
                                className="input100"
                                type="text"
                                name="username"
                                placeholder="Enter Username"
                              />
                              <span className="focus-input100"></span>
                            </div>

                            <div
                              className="wrap-input100 validate-input mb-2"
                              data-validate="Please enter email"
                            >
                              <input
                                className="input100"
                                type="email"
                                name="pass"
                                placeholder="Enter Email"
                              />
                              <span className="focus-input100"></span>
                            </div>
                            <div
                              className="wrap-input100 validate-input mb-2"
                              data-validate="Please enter Mobile"
                            >
                              <input
                                className="input100"
                                type="text"
                                name="pass"
                                placeholder="Enter Mobile"
                              />
                              <span className="focus-input100"></span>
                            </div>
                            <div
                              className="wrap-input100 validate-input mb-2"
                              data-validate="Please enter password"
                            >
                              <input
                                className="input100"
                                type="password"
                                name="pass"
                                placeholder="Password"
                              />
                              <span className="focus-input100"></span>
                            </div>
                            <div
                              className="wrap-input100 validate-input mb-2"
                              data-validate="Please enter Reset password"
                            >
                              <input
                                className="input100"
                                type="password"
                                name="pass"
                                placeholder="Reset Password"
                              />
                              <span className="focus-input100"></span>
                            </div>
                            <div className="text-right p-t-13 p-b-23">
                              <span className="txt1"> </span>
                              <a href="#" className="txt2">
                                Forgot Password
                              </a>
                            </div>
                            <div className="container-login100-form-btn">
                              <button className="login100-form-btn">
                                Reister
                              </button>
                            </div>

                            <div className="flex-col-c py-2">
                              <span className="txt1 ">
                                You have an account?
                                <a
                                  className="px-2"
                                  onClick={() =>
                                    handleTabChange("v-pills-user")
                                  }
                                >
                                  Sign In Now
                                </a>
                              </span>
                            </div>
                          </form>
                        </div>
                      </div>
                    )}
                    {activeTab === "v-pills-profile" && (
                      <div
                        className={`tab-pane fade ${
                          activeTab === "v-pills-profile"
                            ? "show active fade-out"
                            : ""
                        } ${
                          previousTab === "v-pills-profile" ? "fade-out" : ""
                        }`}
                      >
                        <div className="wrap-login100">
                          <span className="login100-form-title"> Sign In </span>
                          <form className="login100-form validate-form p-l-55 p-r-55 p-t-20">
                            <div
                              className="wrap-input100 validate-input m-b-16"
                              data-validate="Please enter username"
                            >
                              <input
                                className="input100"
                                type="text"
                                name="username"
                                placeholder="Username"
                              />
                              <span className="focus-input100"></span>
                            </div>
                            <div
                              className="wrap-input100 validate-input"
                              data-validate="Please enter password"
                            >
                              <input
                                className="input100"
                                type="password"
                                name="pass"
                                placeholder="Password"
                              />
                              <span className="focus-input100"></span>
                            </div>
                            <div className="text-right p-t-13 p-b-23">
                              <span className="txt1"> </span>
                              <a href="#" className="txt2">
                                Forgot Password
                              </a>
                            </div>
                            <div className="container-login100-form-btn">
                              <button className="login100-form-btn">
                                Sign in
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    )}
                    {activeTab === "v-pills-messages" && (
                      <div
                        className={`tab-pane fade ${
                          activeTab === "v-pills-messages"
                            ? "show active fade-out"
                            : ""
                        } ${
                          previousTab === "v-pills-messages" ? "fade-out" : ""
                        }`}
                      >
                        <div className="wrap-login100">
                          <span className="login100-form-title"> Sign In </span>
                          <form className="login100-form validate-form p-l-55 p-r-55 p-t-20">
                            <div
                              className="wrap-input100 validate-input m-b-16"
                              data-validate="Please enter username"
                            >
                              <input
                                className="input100"
                                type="text"
                                name="username"
                                placeholder="Username"
                              />
                              <span className="focus-input100"></span>
                            </div>
                            <div
                              className="wrap-input100 validate-input"
                              data-validate="Please enter password"
                            >
                              <input
                                className="input100"
                                type="password"
                                name="pass"
                                placeholder="Password"
                              />
                              <span className="focus-input100"></span>
                            </div>
                            <div className="text-right p-t-13 p-b-23">
                              <span className="txt1"> </span>
                              <a href="#" className="txt2">
                                Forgot Password
                              </a>
                            </div>
                            <div className="container-login100-form-btn">
                              <button className="login100-form-btn">
                                Sign in
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    )}
                    {activeTab === "v-pills-settings" && (
                      <div
                        className={`tab-pane fade ${
                          activeTab === "v-pills-settings"
                            ? "show active fade-out"
                            : ""
                        } ${
                          previousTab === "v-pills-settings" ? "fade-out" : ""
                        }`}
                      >
                        <div className="wrap-login100">
                          <span className="login100-form-title"> Sign In </span>
                          <form className="login100-form validate-form p-l-55 p-r-55 p-t-20">
                            <div
                              className="wrap-input100 validate-input m-b-16"
                              data-validate="Please enter username"
                            >
                              <input
                                className="input100"
                                type="text"
                                name="username"
                                placeholder="Username"
                              />
                              <span className="focus-input100"></span>
                            </div>
                            <div
                              className="wrap-input100 validate-input"
                              data-validate="Please enter password"
                            >
                              <input
                                className="input100"
                                type="password"
                                name="pass"
                                placeholder="Password"
                              />
                              <span className="focus-input100"></span>
                            </div>
                            <div className="text-right p-t-13 p-b-23">
                              <span className="txt1"> </span>
                              <a href="#" className="txt2">
                                Forgot Password
                              </a>
                            </div>
                            <div className="container-login100-form-btn">
                              <button className="login100-form-btn">
                                Sign in
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    )}
                    {activeTab === "v-pills-user" && (
                      <div
                        className={`tab-pane fade ${
                          activeTab === "v-pills-user"
                            ? "show active fade-out"
                            : ""
                        } ${previousTab === "v-pills-user" ? "fade-out" : ""}`}
                      >
                        <div className="wrap-login100">
                          <span className="login100-form-title"> Sign In </span>
                          <form className="login100-form validate-form p-l-55 p-r-55 p-t-20">
                            <div
                              className="wrap-input100 validate-input m-b-16"
                              data-validate="Please enter username"
                            >
                              <input
                                className="input100"
                                type="text"
                                name="username"
                                placeholder="Username"
                              />
                              <span className="focus-input100"></span>
                            </div>
                            <div
                              className="wrap-input100 validate-input"
                              data-validate="Please enter password"
                            >
                              <input
                                className="input100"
                                type="password"
                                name="pass"
                                placeholder="Password"
                              />
                              <span className="focus-input100"></span>
                            </div>
                            <div className="text-right p-t-13 p-b-23">
                              <span className="txt1"> </span>
                              <a href="#" className="txt2">
                                Forgot Password
                              </a>
                            </div>
                            <div className="container-login100-form-btn">
                              <button className="login100-form-btn">
                                Sign in
                              </button>
                            </div>
                            <div className="flex-col-c p-t-140 p-b-40">
                              <span className="txt1 p-b-9">
                                Don’t have an account?
                                <a
                                  className=""
                                  id="v-pills-signup-tab"
                                  data-toggle="pill"
                                  href="#v-pills-signup"
                                  role="tab"
                                  aria-controls="v-pills-signup"
                                  aria-selected="false"
                                  onClick={() =>
                                    handleTabChange("v-pills-sign-up")
                                  }
                                >
                                  Sign Up Now
                                </a>
                              </span>
                            </div>
                          </form>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
