import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import CustomSelect from "../../Common/customSelectbox.jsx";
import { customStyles } from "../../Common/dataTableStyle.jsx";
import CustomBreadcrumb from "../../Common/customBreadcrumb.jsx";
import CustomDatePicker from "../../Common/DatePickerCommon.jsx";
import { filterListdata } from "../../../asset/StastucJsonData/allListingJson.jsx";
import { dataTableListData } from "../../../asset/StastucJsonData/allListingJson.jsx";

const ListAllListing = () => {
  const [selectedValue, setSelectedValue] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const navigate = useNavigate();

  const filterListdatas = filterListdata[0] || [];

  const handleSelectChange = (e) => {
    setSelectedValue(e);
  };
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = dataTableListData.filter((item) =>
    Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const column = [
    {
      name: "Status",
      width: "50px",
      cell: (row) => (
        <div className="text-center">
          <i class="fa fa-circle-check text-success" title="Published"></i>
        </div>
      ),
      center: true,
      wrap: true,
    },
    {
      name: "Portal Status",
      width: "91px",
      cell: (row) => (
        <div className="text-center">
          <img width={"20px;"} src="/svg/pf_active.png" alt="img" />
          <img width={"20px;"} src="/svg/dubzille_active.png" alt="img" />
        </div>
      ),
      center: true,
      wrap: true,
    },
    {
      name: "Created at",
      selector: (row) => row?.created_at,
      center: true,
      wrap: true,
    },
    {
      name: "Updated at",
      selector: (row) => row?.updated_at,
      center: true,
      wrap: true,
    },
    {
      name: "Ref. No.",
      width: "80px",
      selector: (row) => row?.ref_no,
      center: true,
      wrap: true,
    },
    {
      name: "Unit",
      width: "50px",
      selector: (row) => row?.unit,
      center: true,
      wrap: true,
    },
    {
      name: "Category",
      selector: (row) => row?.category,
      center: true,
      wrap: true,
    },
    {
      name: "Location",
      selector: (row) => row?.location,
      center: true,
      wrap: true,
    },
    {
      name: "S-Location",
      selector: (row) => row?.s_location,
      center: true,
      wrap: true,
    },
    {
      name: "Beds",
      width: "50px",
      selector: (row) => row?.beds,
      center: true,
      wrap: true,
    },
    {
      name: "Price",
      width: "60px",
      selector: (row) => row?.price,
      center: true,
      wrap: true,
    },
    {
      name: "Agent",
      selector: (row) => row?.agent,
      center: true,
      wrap: true,
    },
    {
      name: "A.E. Status",
      width: "75px",
      cell: (row) => (
        <div className="text-center">
          <img width={"20px;"} src="/svg/approve.png" alt="img" />
        </div>
      ),
      center: true,
      wrap: true,
    },
    {
      name: "D&C",
      width: "60px",
      cell: (row) => (
        <div className="text-center">
          <img width={"20px;"} src="/svg/doc.png" alt="img" />
          <img width={"20px;"} src="/svg/cheque.png" alt="img" />
        </div>
      ),
      center: true,
      wrap: true,
    },
    {
      name: "Edit",
      width: "40px",
      cell: (row) => (
        <>
          <Link to={`/edit-all-listing/${row?.user_id}`}>
            <div className="text-center cursor-pointer">
              <img width={"20px;"} src="/svg/pencil.png" alt="img" />
            </div>
          </Link>
        </>
      ),
      center: true,
      wrap: true,
    },
    {
      name: "Duplicate",
      width: "70px",
      cell: (row) => (
        <div className="text-center">
          <img width={"20px;"} src="/svg/copy.png" alt="img" />
        </div>
      ),
      center: true,
      wrap: true,
    },
    {
      name: "PDF&JPG",
      width: "60px",
      cell: (row) => (
        <div className="text-center">
          <img width={"20px;"} src="/svg/file.png" alt="img" />
          <img width={"20px;"} src="/svg/jpg.png" alt="img" />
        </div>
      ),
      center: true,
      wrap: true,
    },
  ];

  return (
    <>
      <div class="container page-containners-main ">
        <CustomBreadcrumb
          title="All Listing"
          home="Dashboard"
          currentPage="All Listing"
          addExtraPageTitle=""
          addExtraPageTitlePath=""
        />

        <div className="all-list-main-containner">
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-6 mb-2">
              <CustomSelect
                options={filterListdatas.add_property}
                placeholder={"Add Property"}
                value={selectedValue}
                onChange={handleSelectChange}
              />
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 mb-2">
              <CustomDatePicker
                startDate={startDate}
                setStartDate={setStartDate}
                src={"/svg/calendar.png"}
              />
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 mb-2">
              <CustomDatePicker
                startDate={endDate}
                setStartDate={setEndDate}
                src={"/svg/calendar.png"}
              />
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 mb-2">
              <CustomSelect
                options={filterListdatas.filter_by_agent}
                placeholder={"Filter By Agent"}
                value={selectedValue}
                onChange={handleSelectChange}
              />
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 mb-2">
              <CustomSelect
                options={filterListdatas.filter_by_status}
                placeholder={"Filter By Status"}
                value={selectedValue}
                onChange={handleSelectChange}
              />
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 mb-2">
              <CustomSelect
                options={filterListdatas.filter_by_portal}
                placeholder={"Filter By Portal"}
                value={selectedValue}
                onChange={handleSelectChange}
              />
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 mb-2">
              <CustomSelect
                options={filterListdatas.filter_by_property}
                placeholder={"Filter By Property"}
                value={selectedValue}
                onChange={handleSelectChange}
              />
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 mb-2">
              <CustomSelect
                options={filterListdatas.filter_by_bedrooms}
                placeholder={"Filter By Bedrooms"}
                value={selectedValue}
                onChange={handleSelectChange}
              />
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 mb-2">
              <CustomSelect
                options={filterListdatas.filter_by_emitares}
                placeholder={"Filter By Emirates"}
                value={selectedValue}
                onChange={handleSelectChange}
              />
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 mb-2">
              <CustomSelect
                options={filterListdatas.filter_by_location}
                placeholder={"Filter By Location"}
                value={selectedValue}
                onChange={handleSelectChange}
              />
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 mb-2">
              <CustomSelect
                options={filterListdatas.filter_by_sublocation}
                placeholder={"Filter By Sublocation"}
                value={selectedValue}
                onChange={handleSelectChange}
              />
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 mb-2">
              <CustomSelect
                options={filterListdatas.filter_by_project}
                placeholder={"Filter By Project"}
                value={selectedValue}
                onChange={handleSelectChange}
              />
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 mb-2">
              <CustomSelect
                options={filterListdatas.filter_by_price_range}
                placeholder={"Filter By Price Range"}
                value={selectedValue}
                onChange={handleSelectChange}
              />
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 mb-2">
              <CustomSelect
                options={filterListdatas.filter_by_ref_no}
                placeholder={"Filter By Ref. No."}
                value={selectedValue}
                onChange={handleSelectChange}
              />
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 mb-2">
              <CustomSelect
                options={filterListdatas.filter_by_category}
                placeholder={"Filter By Category"}
                value={selectedValue}
                onChange={handleSelectChange}
              />
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 mb-2">
              <input
                type="number"
                id="propunitno"
                name="propunitno"
                placeholder="Search Unit No."
                className="custom-select unit-number-list"
              />
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 mb-2">
              <input
                type="number"
                id="searchtrans"
                name="searchtrans"
                placeholder="Search Transaction No."
                className="custom-select unit-number-list"
              />
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 mb-2">
              <CustomSelect
                options={filterListdatas.select_agent}
                placeholder={"Select Agent"}
                value={selectedValue}
                onChange={handleSelectChange}
              />
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 mb-2">
              <CustomSelect
                options={filterListdatas.select_status}
                placeholder={"Select Status"}
                value={selectedValue}
                onChange={handleSelectChange}
              />
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 mb-2">
              <button
                type="button"
                class="btn filter_button listing_button custom-select unit-number-list"
              >
                <i class="fa fa-file-pdf-o me-2"></i>Update Fields
              </button>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 mb-2">
              <button
                type="button"
                class="btn filter_button listing_button custom-select unit-number-list"
              >
                <i class="fa fa-file-pdf-o me-2"></i>Export To CSV
              </button>
            </div>
          </div>
        </div>

        <div className="d-flex datatable-header-main">
          <button
            type="button"
            className="btn filter_button add_property_button rounded-0"
            onClick={() => navigate("/add-all-listing")}
          >
            <i className="fa fa-plus me-2"></i>Add New List
          </button>
          <div className="searchbox-parant-main ">
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearch}
              className="add-search-box-in-datatable rounded-0"
            />
            <i className="fa fa-search"></i>
          </div>
        </div>
        <div className="datatable-containner">
          <DataTable
            data={filteredData ? filteredData : dataTableListData}
            noDataComponent="No data available"
            customStyles={customStyles}
            highlightOnHover
            noHeader={false}
            persistTableHead
            columns={column}
            pagination
            searchable
            responsive
            fixedHeader={true}
          />
        </div>
      </div>
    </>
  );
};

export default ListAllListing;
