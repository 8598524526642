// CREATE DUBIZZLE LEADS STATIC DATA...

export const DubizzleLeadsData = [
  {
    property_id: "f079a5bcb76b4e5894f3aa857b7214f8",
    property_refrance: "BY-R-13850",
    name: "James",
    mobile: "995555408236",
    type: "whatsapp_views",
    date: "01-Aug-2024",
  },
  {
    property_id: "161a9a288f864d82b9c1fdb9c2ad789f",
    property_refrance: "BY-R-13841",
    name: "Meet Vij",
    mobile: "971555787311",
    type: "whatsapp_views",
    date: "31-Jul-2024",
  },
  {
    property_id: "e2e4aae0af2e4696a091f43744675d74",
    property_refrance: "BY-S-13655",
    name: "Binayah Properties LLC",
    mobile: "971549988811",
    type: "whatsapp_views",
    date: "31-Jul-2024",
  },
  {
    property_id: "2f6d5fa28cac4a2aa6cae3f09420b5e4",
    property_refrance: "BY-R-13850",
    name: "Sameera",
    mobile: "971544423210",
    type: "whatsapp_views",
    date: "31-Jul-2024",
  },
  {
    property_id: "e85331b679c247519aa73acb4fbf1bde",
    property_refrance: "BY-R-13852",
    name: "Sam",
    mobile: "96171350598",
    type: "whatsapp_views",
    date: "31-Jul-2024",
  },
  {
    property_id: "8bbd332b2b904bd3aaf4f85ea593c830",
    property_refrance: "BY-S-13644",
    name: "Noha Galal",
    mobile: "971563850558",
    type: "whatsapp_views",
    date: "31-Jul-2024",
  },
  {
    property_id: "c946060da4af40fa8ee6e1365624f739",
    property_refrance: "BY-R-13839",
    name: "Capital Western Real Estate",
    mobile: "971586428432",
    type: "whatsapp_views",
    date: "30-Jul-2024",
  },
  {
    property_id: "5e6987ac592946b9bdd1abd6e10ce637",
    property_refrance: "BY-R-13842",
    name: "Andrew Konovalov",
    mobile: "971558758211",
    type: "whatsapp_views",
    date: "29-Jul-2024",
  },
  {
    property_id: "ed510f13e19c436fbd2108beaa38e773",
    property_refrance: "BY-R-13841",
    name: "MHB",
    mobile: "971543018018",
    type: "whatsapp_views",
    date: "29-Jul-2024",
  },
  {
    property_id: "02f8b595b99b4a098bf9c54c5e0476c6",
    property_refrance: "BY-S-13605",
    name: "Mohanad",
    mobile: "971552989787",
    type: "whatsapp_views",
    date: "29-Jul-2024",
  },
  {
    property_id: "b2cdb2989c2249718a7e456e803b9ba2",
    property_refrance: "BY-R-13847",
    name: "H",
    mobile: "971522040846",
    type: "whatsapp_views",
    date: "29-Jul-2024",
  },
  {
    property_id: "62c610df090f4988b50441f3445e0a12",
    property_refrance: "BY-S-13644",
    name: "Yuliia Kozhurenko",
    mobile: "971505195103",
    type: "whatsapp_views",
    date: "29-Jul-2024",
  },
];
