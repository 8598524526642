import React from "react";
import PaidLeads2List from "./PaidLeads2List";
import CustomBreadcrumb from "../../Common/customBreadcrumb";

const PaidLeads2 = () => {
  return (
    <>
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Manage Paid Leads 2"
          home="Dashboard"
          currentPage="Paid Leads 2"
          addExtraPageTitle="All Leads"
          addExtraPageTitlePath="all-leads"
        />
        <PaidLeads2List />
      </div>
    </>
  );
};

export default PaidLeads2;
