import React from "react";
import CustomBreadcrumb from "../../../Common/customBreadcrumb";
import OldDealsDetails from "./OldDealsDetails";

const OldDealsDetailsPage = () => {
  return (
    <>
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Old Deals Details"
          home="Dashboard"
          currentPage="Old Deals Details"
          addExtraPageTitle="Old Deals"
          addExtraPageTitlePath="old-deals"
        />
        <OldDealsDetails />
      </div>
    </>
  );
};

export default OldDealsDetailsPage;
