import React, { useEffect, useState } from "react";
import "../../../../asset/css/pages/tenants.css";
import CustomDatePicker from "../../../Common/DatePickerCommon";
import CustomSelect from "../../../Common/customSelectbox";
import { Link, useParams } from "react-router-dom";
import { tenantsListData } from "../../../../asset/StastucJsonData/TenantsJsonData";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";

const EditTenantsList = () => {
  const [startDate, setStartDate] = useState();
  const [selectType, setSelectType] = useState(0);
  const [getIdWiseDatas, setGetIdWiseDatas] = useState([]);

  let { id } = useParams();

  const getIdWiseData = (id) => {
    const findData = tenantsListData.find((item) => id === item?._id);
    setGetIdWiseDatas(findData);
  };

  const options = [
    {
      is_approved: [
        {
          id: 1,
          option: "Yes",
        },
        {
          id: 2,
          option: "Partially",
        },
      ],
      is_approved_document: [
        {
          id: 1,
          option: "No",
        },
        {
          id: 2,
          option: "Yes",
        },
      ],
    },
  ];

  console.log("getIdWiseDatas", getIdWiseDatas);

  const initialValues = {
    tenant_id: "",
    tenant_type: "",
    tenant_name: "",
    email: "",
    emirates_id: "",
    passport: "",
    passports: "",
    trade_license: "",
    no_of_cheque: "",
    primary_no: "",
    secondary_no: "",
    nationality: "",
    is_document_to_tenant: "",
    is_approved: "",
    date_of_birth: "",
    passport_expiry_birth: "",
    emirates_id_expiry_date: "",
    trade_license_expiry_date: "",
    tradecy_contract_start_date: "",
    tradecy_contract_end_date: "",
  };

  const validateYupSchema = Yup.object()
    .shape({
      tenant_id: Yup.string().required("Field is required."),
      tenant_type: Yup.string().required("Field is required."),
      tenant_name: Yup.string().required("Field is required."),
      email: Yup.string().required("Field is required."),
      emirates_id: Yup.string().required("Field is required."),
      passport: Yup.string().required("Field is required."),
      passports: Yup.string().required("Field is required."),
      trade_license: Yup.string().required("Field is required."),
      no_of_cheque: Yup.string().required("Field is required."),
      primary_no: Yup.string().required("Field is required."),
      secondary_no: Yup.string().required("Field is required."),
      nationality: Yup.string().required("Field is required."),
      is_document_to_tenant: Yup.string().required("Field is required."),
      is_approved: Yup.string().required("Field is required."),
      date_of_birth: Yup.date().nullable().required("Date is required"),
      passport_expiry_birth: Yup.date().nullable().required("Date is required"),
      emirates_id_expiry_date: Yup.date()
        .nullable()
        .required("Date is required"),
      trade_license_expiry_date: Yup.date()
        .nullable()
        .required("Date is required"),
      tradecy_contract_start_date: Yup.date()
        .nullable()
        .required("Date is required"),
      tradecy_contract_end_date: Yup.date()
        .nullable()
        .required("Date is required"),
    })
    .defined();

  const handleSubmitForm = (e) => {
    console.log(e);
  };

  useEffect(() => {
    getIdWiseData(id);
  }, [id]);

  return (
    <>
      <div className="add-tenants mb-5">
        <Formik
          initialValues={initialValues}
          validationSchema={validateYupSchema}
          onSubmit={handleSubmitForm}
        >
          {(formik) => {
            const { setFieldValue } = formik;
            return (
              <>
                <Form>
                  <div className="row my-3">
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                      <p className="m-0">
                        Tenant Id <span className="text-danger">*</span>
                      </p>
                      <Field
                        className="form-control rounded-0"
                        type="text"
                        placeholder="Enter Tenant Id"
                        name="tenant_id"
                      />
                      <ErrorMessage
                        name="tenant_id"
                        component={"div"}
                        className="text-danger"
                      />
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                      <p className="m-0">
                        Tenant Type <span className="text-danger">*</span>
                      </p>
                      <Field
                        className="form-control rounded-0"
                        type="text"
                        placeholder="Enter Tenant Type"
                        name="tenant_type"
                      />
                      <ErrorMessage
                        name="tenant_type"
                        component={"div"}
                        className="text-danger"
                      />
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                      <p className="m-0">
                        Tenant Name <span className="text-danger">*</span>
                      </p>
                      <Field
                        className="form-control rounded-0"
                        type="text"
                        placeholder="Enter Tenant Name"
                        name="tenant_name"
                      />
                      <ErrorMessage
                        name="tenant_name"
                        component={"div"}
                        className="text-danger"
                      />
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                      <p className="m-0">
                        Email <span className="text-danger">*</span>
                      </p>
                      <Field
                        className="form-control rounded-0"
                        type="text"
                        placeholder="Enter Email"
                        name="email"
                      />
                      <ErrorMessage
                        name="email"
                        component={"div"}
                        className="text-danger"
                      />
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                      <p className="m-0">
                        Emirates ID Front <span className="text-danger">*</span>
                      </p>
                      <Field
                        className="form-control rounded-0"
                        type="text"
                        placeholder="Enter Emirates ID Front"
                        name="emirates_id"
                      />
                      <ErrorMessage
                        name="emirates_id"
                        component={"div"}
                        className="text-danger"
                      />
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                      <p className="m-0">
                        Passport <span className="text-danger">*</span>
                      </p>
                      <Field
                        className="form-control document-type rounded-0"
                        type="file"
                        placeholder="Enter Passport"
                        name="passport"
                      />
                      <ErrorMessage
                        name="passport"
                        component={"div"}
                        className="text-danger"
                      />
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                      <p className="m-0">
                        Passports <span className="text-danger">*</span>
                      </p>
                      <Field
                        className="form-control document-type rounded-0"
                        type="file"
                        placeholder="Enter Passport"
                        name="passports"
                      />
                      <ErrorMessage
                        name="passports"
                        component={"div"}
                        className="text-danger"
                      />
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                      <p className="m-0">
                        Trade License <span className="text-danger">*</span>
                      </p>
                      <Field
                        className="form-control document-type rounded-0"
                        type="file"
                        placeholder="Enter Trade License"
                        name="trade_license"
                      />
                      <ErrorMessage
                        name="trade_license"
                        component={"div"}
                        className="text-danger"
                      />
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                      <p className="m-0">
                        No. of Cheque <span className="text-danger">*</span>
                      </p>
                      <Field
                        className="form-control rounded-0"
                        type="text"
                        placeholder="Enter No. of Cheque"
                        name="no_of_cheque"
                      />
                      <ErrorMessage
                        name="no_of_cheque"
                        component={"div"}
                        className="text-danger"
                      />
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                      <p className="m-0">
                        Primary mobile number
                        <span className="text-danger">*</span>
                      </p>
                      <Field
                        className="form-control rounded-0"
                        type="text"
                        placeholder="Enter Primary No."
                        name="primary_no"
                      />
                      <ErrorMessage
                        name="primary_no"
                        component={"div"}
                        className="text-danger"
                      />
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                      <p className="m-0">
                        Secondary mobile number{" "}
                        <span className="text-danger">*</span>
                      </p>
                      <Field
                        className="form-control rounded-0"
                        type="text"
                        placeholder="Enter Secondary No."
                        name="secondary_no"
                      />
                      <ErrorMessage
                        name="secondary_no"
                        component={"div"}
                        className="text-danger"
                      />
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                      <p className="m-0">
                        Nationality <span className="text-danger">*</span>
                      </p>
                      <Field
                        className="form-control rounded-0"
                        type="text"
                        placeholder="Enter Nationality"
                        name="nationality"
                      />
                      <ErrorMessage
                        name="nationality"
                        component={"div"}
                        className="text-danger"
                      />
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3 mb-4 ">
                      <p className="m-0">
                        Is Document to Tenant ?{" "}
                        <span className="text-danger">*</span>
                      </p>
                      <CustomSelect
                        options={options[0]?.is_approved}
                        placeholder=" - Select - "
                        value={selectType}
                        onChange={(name, value) => {
                          setFieldValue(name, value);
                        }}
                        name={"is_document_to_tenant"}
                      />
                      <ErrorMessage
                        name="is_document_to_tenant"
                        component={"div"}
                        className="text-danger"
                      />
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3 mb-4 ">
                      <p className="m-0">
                        Is Approved ? <span className="text-danger">*</span>
                      </p>
                      <CustomSelect
                        options={options[0]?.is_approved_document}
                        placeholder=" - Select - "
                        value={selectType}
                        onChange={(name, value) => {
                          setFieldValue(name, value);
                        }}
                        name={"is_approved"}
                      />
                      <ErrorMessage
                        name="is_approved"
                        component={"div"}
                        className="text-danger"
                      />
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3 mb-4">
                      <p className="m-0">
                        Date Of Birth <span className="text-danger">*</span>
                      </p>
                      <Field name="date_of_birth">
                        {({ field, form }) => (
                          <CustomDatePicker
                            startDate={field.value}
                            setStartDate={(date) =>
                              form.setFieldValue("date_of_birth", date)
                            }
                            src={"/svg/calendar.png"}
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="date_of_birth"
                        component={"div"}
                        className="text-danger"
                      />
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3 mb-4">
                      <p className="m-0">
                        Passport Expiry Date
                        <span className="text-danger">*</span>
                      </p>
                      <Field name="passport_expiry_birth">
                        {({ field, form }) => (
                          <CustomDatePicker
                            startDate={field.value}
                            setStartDate={(date) =>
                              form.setFieldValue("passport_expiry_birth", date)
                            }
                            src={"/svg/calendar.png"}
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="passport_expiry_birth"
                        component={"div"}
                        className="text-danger"
                      />
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3 mb-4">
                      <p className="m-0">
                        Emirates ID Expiry Date
                        <span className="text-danger">*</span>
                      </p>
                      <Field name="emirates_id_expiry_date">
                        {({ field, form }) => (
                          <CustomDatePicker
                            startDate={field.value}
                            setStartDate={(date) =>
                              form.setFieldValue(
                                "emirates_id_expiry_date",
                                date
                              )
                            }
                            src={"/svg/calendar.png"}
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="emirates_id_expiry_date"
                        component={"div"}
                        className="text-danger"
                      />
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3 mb-4">
                      <p className="m-0">
                        Trade license Expiry date
                        <span className="text-danger">*</span>
                      </p>
                      <Field name="trade_license_expiry_date">
                        {({ field, form }) => (
                          <CustomDatePicker
                            startDate={field.value}
                            setStartDate={(date) =>
                              form.setFieldValue(
                                "trade_license_expiry_date",
                                date
                              )
                            }
                            src={"/svg/calendar.png"}
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="trade_license_expiry_date"
                        component={"div"}
                        className="text-danger"
                      />
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3 mb-4">
                      <p className="m-0">
                        Tenancy Contract Start Date
                        <span className="text-danger">*</span>
                      </p>
                      <Field name="tradecy_contract_start_date">
                        {({ field, form }) => (
                          <CustomDatePicker
                            startDate={field.value}
                            setStartDate={(date) =>
                              form.setFieldValue(
                                "tradecy_contract_start_date",
                                date
                              )
                            }
                            src={"/svg/calendar.png"}
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="tradecy_contract_start_date"
                        component={"div"}
                        className="text-danger"
                      />
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3 mb-4">
                      <p className="m-0">
                        Tenancy Contract End Date
                        <span className="text-danger">*</span>
                      </p>
                      <Field name="tradecy_contract_end_date">
                        {({ field, form }) => (
                          <CustomDatePicker
                            startDate={field.value}
                            setStartDate={(date) =>
                              form.setFieldValue(
                                "tradecy_contract_end_date",
                                date
                              )
                            }
                            src={"/svg/calendar.png"}
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="tradecy_contract_end_date"
                        component={"div"}
                        className="text-danger"
                      />
                    </div>
                  </div>
                  <div className="cansel-submit-note-btn text-center mt-5 mb-3">
                    <button type="submit" className="btn btn-secondary mx-2">
                      Submit
                    </button>

                    <Link to="/tenants">
                      <button className="mx-2 btn btn-light">Cancel</button>
                    </Link>
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default EditTenantsList;
