import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { customStyles } from "../../Common/dataTableStyle";
import Modal from "../../Common/ModelCommon";
import { featuresListData } from "../../../asset/StastucJsonData/fraturesJsonData";
import CustomSelect from "../../Common/customSelectbox";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";

const FeaturesMasterList = () => {
  // declare state...
  const [searchQuery, setSearchQuery] = useState("");
  const [showAddModel, setShowAddModel] = useState(false);
  const [showEditModel, setShowEditModel] = useState(false);
  const [getDataIdWise, setGetDataIdWise] = useState([]);
  const [selectId, setSelectId] = useState();
  const [selectType, setSelectType] = useState();

  // Validation...

  const validationSchema = Yup.object({
    features: Yup.string().required("Features is required."),
    selectType: Yup.string().required("SelectType is required."),
  });
  // Handle Add Features...
  const handleAddForm = (e) => {
    console.log("add data", e);
    setShowAddModel(false);
  };
  // Handle Edit Features...
  const handleEditForm = (e) => {
    console.log("add data", e);
    setShowEditModel(false);
  };
  // Hendle search functionality...

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = featuresListData.filter((item) =>
    Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  // Add and Edit location model close functionality....

  const handleCloseAddModel = () => {
    setShowAddModel(false);
  };
  const handleCloseEditModel = () => {
    setShowEditModel(false);
  };

  // Get data id wise...

  const getIdWiseData = (id) => {
    const findData = featuresListData.find((user_id) => id === user_id?._id);
    setGetDataIdWise(findData);
  };

  // Use Effect...
  useEffect(() => {
    getIdWiseData(selectId);
  }, [selectId]);

  const options = [
    {
      display_feature: [
        {
          id: 1,
          option: "Yes",
        },
        {
          id: 2,
          option: "No",
        },
      ],
    },
  ];

  const column = [
    {
      name: "Sr.",
      selector: (row, index) => index + 1,
      width: "100px",
      center: true,
      wrap: true,
    },
    {
      name: "Feature",
      cell: (row) => <div className="pl-2">{row?.features}</div>,
      center: false,
      wrap: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <div
            onClick={() => {
              setShowEditModel(true);
              setSelectId(row?._id);
            }}
            className="cursor-pointer"
          >
            <img width={"20px;"} src="/svg/pencil.png" alt="img" />
          </div>
        </>
      ),
      width: "60px",
      center: true,
      wrap: true,
    },
  ];

  return (
    <>
      <div className="d-flex datatable-header-main">
        <button
          type="button"
          className="btn filter_button add_property_button rounded-0"
          onClick={() => setShowAddModel(true)}
        >
          <i className="fa fa-plus me-2"></i>Add New Feature
        </button>
        <div className="searchbox-parant-main ">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearch}
            className="add-search-box-in-datatable rounded-0"
          />
          <i className="fa fa-search"></i>
        </div>
      </div>

      <DataTable
        columns={column}
        data={filteredData ? filteredData : featuresListData}
        customStyles={customStyles}
        fixedHeader={true}
        noHeader={false}
        noDataComponent="No data available"
        pagination
        searchable
        responsive
        highlightOnHover
        persistTableHead
      />

      {/* Add model */}
      <div className="model_inner feature-page">
        <Modal show={showAddModel} handleClose={handleCloseAddModel}>
          <div className="sdvds">
            <h5 className="mb-5">Add Feature</h5>
            <Formik
              initialValues={{
                features: "",
                selectType: selectType ? selectType : "",
              }}
              validationSchema={validationSchema}
              onSubmit={handleAddForm}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <div className="w-100 mb-3">
                    <p className="m-0 fw-bold">Feature</p>
                    <Field
                      className="form-control"
                      type="text"
                      placeholder="Enter Feature"
                      name="features"
                    />
                    <ErrorMessage
                      name="features"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="col-12 mb-4">
                    <p className="m-0 fw-bold">
                      Is Display In Filter ?
                      <span className="text-danger">*</span>
                    </p>
                    <CustomSelect
                      options={options[0]?.display_feature}
                      placeholder=" - Select - "
                      value={values?.selectType}
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                      }}
                      name="selectType"
                    />
                    <ErrorMessage
                      name="selectType"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="text-center">
                    <button type="submit" className="btn btn-secondary">
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal>
      </div>
      {/* Edit Model */}
      <div className="model_inner feature-page">
        <Modal show={showEditModel} handleClose={handleCloseEditModel}>
          <div className="sdvds">
            <h5 className="mb-5">Edit Feature</h5>
            <Formik
              initialValues={{
                features: getDataIdWise?.features || "",
                selectType: selectType || "",
              }}
              validationSchema={validationSchema}
              onSubmit={handleEditForm}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <div className="w-100 mb-3">
                    <p className="m-0 fw-bold">Feature</p>
                    <Field
                      className="form-control"
                      type="text"
                      placeholder="Enter Feature"
                      name="features"
                    />
                    {console.log("values", values)}
                    <ErrorMessage
                      name="features"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="col-12 mb-4">
                    <p className="m-0 fw-bold">
                      Is Display In Filter ?
                      <span className="text-danger">*</span>
                    </p>
                    <CustomSelect
                      options={options[0]?.display_feature}
                      placeholder=" - Select - "
                      value={values?.selectType}
                      onChange={(name, value) => {
                        setSelectType(value);
                        setFieldValue(name, value);
                      }}
                      name="selectType"
                    />
                    <ErrorMessage
                      name="selectType"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="text-center">
                    <button type="submit" className="btn btn-secondary">
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default FeaturesMasterList;
