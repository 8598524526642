// OLD LEAD STATIC DATA...

export const oldLeadDataTableData = [
  {
    _id: "664c68670ec5501cdd16de20",
    Ref: "BY-L-125078",
    Type: "Tenant",
    Status: "Open",
    Sub_Status: "Not yet contacted",
    Priority: "Normal",
    Contact_Ref: "BY-O-96868",
    First_name: "Zoia",
    Mobile_No: "+380671258888",
    Email_Address: "zoiadziuba27@gmail.com",
    Category: "Apartment",
    Emirate: "Dubai",
    Location: "Downtown Dubai",
    Sub_location: "The Address Residences Dubai Opera Tower 1",
    Unit_Type: "Apartment",
    Unit_No: "4609=",
    Min_Beds: 2,
    Max_Beds: 2,
    Min_Price: 430000,
    Max_Price: 430000,
    Min_Area: 1189,
    Max_Area: 1189,
    Listing_Ref: "BY-R-13784",
    Source: "Propertyfinder.ae",
    Agent1: "Alena Iuzhaninova",
    Created_By: "Alena Iuzhaninova",
    Is_Phone_Lead: "No",
    Enquiry_Date: "30/03/2024 18:29:45",
    Date_Added: "30/03/2024 18:29:45",
    Date_Updated: "30/03/2024 18:29:45",
    Agent_Referral: 0,
    Share_this_lead: 0,
    Notes:
      "(1) 2024-03-30 18:29- Username: Auto Import - Notes: This lead is auto imported from Propertyfinder.ae (email_lead, property), original enquiry: (Hi, I found your property with ref: BY-R-13784 on Property Finder. Please contact me. Thank you.)",
    Initial_Contact: "--",
    teamleader: "",
  },
  {
    _id: "664c68670ec5501cdd16de21",
    Ref: "BY-L-125123",
    Type: "Not Specified",
    Status: "Open",
    Sub_Status: "In progress",
    Priority: "Normal",
    Contact_Ref: "BY-O-96907",
    Mobile_No: "+971522944533",
    Category: "0",
    Min_Beds: 0,
    Max_Beds: 0,
    Min_Price: 0,
    Max_Price: 0,
    Min_Area: 0,
    Max_Area: 0,
    Source: "Propertyfinder.ae",
    Agent1: "Alena Iuzhaninova",
    Created_By: "Alena Iuzhaninova",
    Is_Phone_Lead: "Yes",
    Enquiry_Date: "03/04/2024 11:35:25",
    Date_Added: "03/04/2024 11:37:14",
    Date_Updated: "03/04/2024 11:35:25",
    Agent_Referral: 0,
    Share_this_lead: 0,
    Notes:
      "(1) 2024-04-03 11:35- Username: Auto Import - Notes: This lead is auto imported from Propertyfinder.ae  (Call Status: Answered, Agent: Alena Iuzhaninova, Call Time: 03-04-2024 11:35:25)",
    Initial_Contact: "--",
    teamleader: "",
  },
  {
    _id: "664c68670ec5501cdd16de22",
    Ref: "BY-L-125128",
    Type: "Not Specified",
    Status: "Open",
    Sub_Status: "Not yet contacted",
    Priority: "Normal",
    Contact_Ref: "BY-O-96912",
    Phone_No: "+97142282868",
    Category: "0",
    Min_Beds: 0,
    Max_Beds: 0,
    Min_Price: 0,
    Max_Price: 0,
    Min_Area: 0,
    Max_Area: 0,
    Source: "Propertyfinder.ae",
    Agent1: "Alena Iuzhaninova",
    Created_By: "Alena Iuzhaninova",
    Is_Phone_Lead: "Yes",
    Enquiry_Date: "04/04/2024 14:20:41",
    Date_Added: "04/04/2024 14:21:33",
    Date_Updated: "04/04/2024 14:20:41",
    Agent_Referral: 0,
    Share_this_lead: 0,
    Notes:
      "(1) 2024-04-04 14:20- Username: Auto Import - Notes: This lead is auto imported from Propertyfinder.ae  (Call Status: Canceled, Agent: Alena Iuzhaninova, Call Time: 04-04-2024 14:20:41)",
    Initial_Contact: "--",
    teamleader: "",
  },
  {
    _id: "664c68670ec5501cdd16de23",
    Ref: "BY-L-125138",
    Type: "Not Specified",
    Status: "Open",
    Sub_Status: "Not yet contacted",
    Priority: "Normal",
    Contact_Ref: "BY-O-96922",
    Mobile_No: "+971502285119",
    Category: "0",
    Min_Beds: 0,
    Max_Beds: 0,
    Min_Price: 0,
    Max_Price: 0,
    Min_Area: 0,
    Max_Area: 0,
    Source: "Propertyfinder.ae",
    Agent1: "Alena Iuzhaninova",
    Created_By: "Alena Iuzhaninova",
    Is_Phone_Lead: "Yes",
    Enquiry_Date: "05/04/2024 15:03:48",
    Date_Added: "05/04/2024 15:04:13",
    Date_Updated: "05/04/2024 15:03:48",
    Agent_Referral: 0,
    Share_this_lead: 0,
    Notes:
      "(1) 2024-04-05 15:03- Username: Auto Import - Notes: This lead is auto imported from Propertyfinder.ae  (Call Status: Canceled, Agent: Alena Iuzhaninova, Call Time: 05-04-2024 15:03:48)",
    Initial_Contact: "--",
    teamleader: "",
  },
  {
    _id: "664c68670ec5501cdd16de24",
    Ref: "BY-L-125142",
    Type: "Not Specified",
    Status: "Open",
    Sub_Status: "Not yet contacted",
    Priority: "Normal",
    Contact_Ref: "BY-O-96925",
    Mobile_No: "+971561721053",
    Category: "0",
    Min_Beds: 0,
    Max_Beds: 0,
    Min_Price: 0,
    Max_Price: 0,
    Min_Area: 0,
    Max_Area: 0,
    Source: "Propertyfinder.ae",
    Agent1: "Alena Iuzhaninova",
    Created_By: "Alena Iuzhaninova",
    Is_Phone_Lead: "Yes",
    Enquiry_Date: "06/04/2024 12:29:13",
    Date_Added: "06/04/2024 12:30:14",
    Date_Updated: "06/04/2024 12:29:13",
    Agent_Referral: 0,
    Share_this_lead: 0,
    Notes:
      "(1) 2024-04-06 12:29- Username: Auto Import - Notes: This lead is auto imported from Propertyfinder.ae  (Call Status: Canceled, Agent: Alena Iuzhaninova, Call Time: 06-04-2024 12:29:13)",
    Initial_Contact: "--",
    teamleader: "",
  },
  {
    _id: "664c68670ec5501cdd16de25",
    Ref: "BY-L-125148",
    Type: "Not Specified",
    Status: "Open",
    Sub_Status: "Not yet contacted",
    Priority: "Normal",
    Contact_Ref: "BY-O-96930",
    Mobile_No: "+971557446782",
    Category: "0",
    Min_Beds: 0,
    Max_Beds: 0,
    Min_Price: 0,
    Max_Price: 0,
    Min_Area: 0,
    Max_Area: 0,
    Source: "Propertyfinder.ae",
    Agent1: "Alena Iuzhaninova",
    Created_By: "Alena Iuzhaninova",
    Is_Phone_Lead: "Yes",
    Enquiry_Date: "08/04/2024 19:35:51",
    Date_Added: "08/04/2024 19:36:42",
    Date_Updated: "08/04/2024 19:35:51",
    Agent_Referral: 0,
    Share_this_lead: 0,
    Notes:
      "(1) 2024-04-08 19:35- Username: Auto Import - Notes: This lead is auto imported from Propertyfinder.ae  (Call Status: Not Answered, Agent: Alena Iuzhaninova, Call Time: 08-04-2024 19:35:51)",
    Initial_Contact: "--",
    teamleader: "",
  },
  {
    _id: "664c68670ec5501cdd16de26",
    Ref: "BY-L-125149",
    Type: "Not Specified",
    Status: "Open",
    Sub_Status: "Not yet contacted",
    Priority: "Normal",
    Contact_Ref: "BY-O-96931",
    Mobile_No: "+971585041154",
    Category: "0",
    Min_Beds: 0,
    Max_Beds: 0,
    Min_Price: 0,
    Max_Price: 0,
    Min_Area: 0,
    Max_Area: 0,
    Source: "Propertyfinder.ae",
    Agent1: "Alena Iuzhaninova",
    Created_By: "Alena Iuzhaninova",
    Is_Phone_Lead: "Yes",
    Enquiry_Date: "09/04/2024 12:36:44",
    Date_Added: "09/04/2024 12:37:33",
    Date_Updated: "09/04/2024 12:36:44",
    Agent_Referral: 0,
    Share_this_lead: 0,
    Notes:
      "(1) 2024-04-09 12:36- Username: Auto Import - Notes: This lead is auto imported from Propertyfinder.ae  (Call Status: Not Answered, Agent: Alena Iuzhaninova, Call Time: 09-04-2024 12:36:44)",
    Initial_Contact: "--",
    teamleader: "",
  },
  {
    _id: "664c68670ec5501cdd16de27",
    Ref: "BY-L-125150",
    Type: "Not Specified",
    Status: "Open",
    Sub_Status: "Not yet contacted",
    Priority: "Normal",
    Contact_Ref: "BY-O-96932",
    Mobile_No: "+971504988023",
    Category: "0",
    Min_Beds: 0,
    Max_Beds: 0,
    Min_Price: 0,
    Max_Price: 0,
    Min_Area: 0,
    Max_Area: 0,
    Source: "Propertyfinder.ae",
    Agent1: "Alena Iuzhaninova",
    Created_By: "Alena Iuzhaninova",
    Is_Phone_Lead: "Yes",
    Enquiry_Date: "09/04/2024 16:09:27",
    Date_Added: "09/04/2024 16:10:02",
    Date_Updated: "09/04/2024 16:09:27",
    Agent_Referral: 0,
    Share_this_lead: 0,
    Notes:
      "(1) 2024-04-09 16:09- Username: Auto Import - Notes: This lead is auto imported from Propertyfinder.ae  (Call Status: Canceled, Agent: Alena Iuzhaninova, Call Time: 09-04-2024 16:09:27)",
    Initial_Contact: "--",
    teamleader: "",
  },
  {
    _id: "664c68670ec5501cdd16de28",
    Ref: "BY-L-125162",
    Type: "Not Specified",
    Status: "Open",
    Sub_Status: "Not yet contacted",
    Priority: "Normal",
    Contact_Ref: "BY-O-96949",
    Mobile_No: "+971562112297",
    Category: "0",
    Min_Beds: 0,
    Max_Beds: 0,
    Min_Price: 0,
    Max_Price: 0,
    Min_Area: 0,
    Max_Area: 0,
    Source: "Propertyfinder.ae",
    Agent1: "Alena Iuzhaninova",
    Created_By: "Alena Iuzhaninova",
    Is_Phone_Lead: "Yes",
    Enquiry_Date: "14/04/2024 11:35:20",
    Date_Added: "14/04/2024 11:36:02",
    Date_Updated: "14/04/2024 11:35:20",
    Agent_Referral: 0,
    Share_this_lead: 0,
    Notes:
      "(1) 2024-04-14 11:35- Username: Auto Import - Notes: This lead is auto imported from Propertyfinder.ae  (Call Status: Canceled, Agent: Alena Iuzhaninova, Call Time: 14-04-2024 11:35:20)",
    Initial_Contact: "--",
    teamleader: "",
  },
  {
    _id: "664c68b60ec5501cdd16de2a",
    Ref: "BY-L-120321",
    Type: "Buyer",
    Status: "Open",
    Sub_Status: "Not yet contacted",
    Priority: "Normal",
    Contact_Ref: "BY-O-92748",
    First_name: "PJI",
    Last_Name: "`",
    Mobile_No: "+919136502100",
    Category: "0",
    Emirate: "Dubai",
    Max_Beds: 0,
    Min_Price: 0,
    Max_Price: 0,
    Min_Area: 0,
    Max_Area: 0,
    Source: "Other Online",
    Agent1: "Ashwin Anil",
    Created_By: "Sheena Mae",
    Is_Phone_Lead: "No",
    Enquiry_Date: "04/11/2023 12:56:00",
    Date_Added: "04/11/2023 12:57:11",
    Date_Updated: "06/11/2023 16:18:16",
    Agent_Referral: 0,
    Share_this_lead: 0,
    Notes:
      "(1) 04-11-2023 12:57- Username: Sheena Mae - Notes: 04-11-2023 PJI 919136502100  can you share me the details for 25h project Ashwin new",
    Initial_Contact: "--",
    teamleader: "Aswin Prasaad",
  },
  {
    _id: "664c68b60ec5501cdd16de2aa",
    Ref: "BY-L-120321",
    Type: "Buyer",
    Status: "Open",
    Sub_Status: "Not yet contacted",
    Priority: "Normal",
    Contact_Ref: "BY-O-92748",
    First_name: "PJI",
    Last_Name: "`",
    Mobile_No: "+919136502100",
    Category: "0",
    Emirate: "Dubai",
    Max_Beds: 0,
    Min_Price: 0,
    Max_Price: 0,
    Min_Area: 0,
    Max_Area: 0,
    Source: "Other Online",
    Agent1: "Ashwin Anil",
    Created_By: "Sheena Mae",
    Is_Phone_Lead: "No",
    Enquiry_Date: "04/11/2023 12:56:00",
    Date_Added: "04/11/2023 12:57:11",
    Date_Updated: "06/11/2023 16:18:16",
    Agent_Referral: 0,
    Share_this_lead: 0,
    Notes:
      "(1) 04-11-2023 12:57- Username: Sheena Mae - Notes: 04-11-2023 PJI 919136502100  can you share me the details for 25h project Ashwin new",
    Initial_Contact: "--",
    teamleader: "Aswin Prasaad",
  },
  {
    _id: "664c68b60ec5501cdd16de2ab",
    Ref: "BY-L-120321",
    Type: "Buyer",
    Status: "Open",
    Sub_Status: "Not yet contacted",
    Priority: "Normal",
    Contact_Ref: "BY-O-92748",
    First_name: "PJI",
    Last_Name: "`",
    Mobile_No: "+919136502100",
    Category: "0",
    Emirate: "Dubai",
    Max_Beds: 0,
    Min_Price: 0,
    Max_Price: 0,
    Min_Area: 0,
    Max_Area: 0,
    Source: "Other Online",
    Agent1: "Ashwin Anil",
    Created_By: "Sheena Mae",
    Is_Phone_Lead: "No",
    Enquiry_Date: "04/11/2023 12:56:00",
    Date_Added: "04/11/2023 12:57:11",
    Date_Updated: "06/11/2023 16:18:16",
    Agent_Referral: 0,
    Share_this_lead: 0,
    Notes:
      "(1) 04-11-2023 12:57- Username: Sheena Mae - Notes: 04-11-2023 PJI 919136502100  can you share me the details for 25h project Ashwin new",
    Initial_Contact: "--",
    teamleader: "Aswin Prasaad",
  },
];

export const oldLeadOptionsData = [
  {
    filter_by_source: [
      {
        id: 1,
        option: "Agent",
      },
      {
        id: 2,
        option: "Property Finder",
      },
      {
        id: 3,
        option: "Bayut",
      },
      {
        id: 4,
        option: "Dibizzle",
      },
      {
        id: 5,
        option: "Call",
      },
      {
        id: 6,
        option: "Email",
      },
      {
        id: 7,
        option: "WA Chat Bot",
      },
      {
        id: 8,
        option: "Facebook",
      },
      {
        id: 9,
        option: "Website",
      },
      {
        id: 10,
        option: "Instagram",
      },
      {
        id: 11,
        option: "Reference",
      },
      {
        id: 12,
        option: "Walk In",
      },
      {
        id: 13,
        option: "Live Chat",
      },
      {
        id: 14,
        option: "Other Online",
      },
    ],
    filter_by_status: [
      {
        id: 0,
        option: "Open",
      },
      {
        id: 2,
        option: "Close",
      },
      {
        id: 3,
        option: "Not Specified",
      },
    ],
    filter_by_sub_status: [
      {
        id: 1,
        option: "In Progress",
      },
      {
        id: 2,
        option: "Successful",
      },
      {
        id: 3,
        option: "Unsuccessful",
      },
      {
        id: 4,
        option: "Not Yet Contacted",
      },
      {
        id: 5,
        option: "Called No Reply",
      },
      {
        id: 6,
        option: "Follow Up",
      },
    ],
    filter_by_lead_type: [
      {
        id: 1,
        option: "Agent",
      },
      {
        id: 2,
        option: "Tenant",
      },
      {
        id: 3,
        option: "Buyer",
      },
      {
        id: 4,
        option: "Landlord",
      },
      {
        id: 5,
        option: "Seller",
      },
    ],
    filter_by_bed_rooms: [
      {
        id: 1,
        option: "Studio",
      },
      {
        id: 2,
        option: "2 Bed",
      },
      {
        id: 3,
        option: "3 Beds",
      },
      {
        id: 4,
        option: "4 Beds",
      },
      {
        id: 5,
        option: "5 Beds",
      },
      {
        id: 6,
        option: "6 Beds",
      },
      {
        id: 7,
        option: "7 Beds",
      },
      {
        id: 8,
        option: "8 Beds",
      },
      {
        id: 9,
        option: "9 Beds",
      },
      {
        id: 10,
        option: "10 Beds",
      },
      {
        id: 11,
        option: "11 Beds",
      },
      {
        id: 12,
        option: "12 Beds",
      },
      {
        id: 13,
        option: "13 Beds",
      },
      {
        id: 14,
        option: "14 Beds",
      },
      {
        id: 15,
        option: "15 Beds",
      },
    ],
    filter_by_agent: [
      {
        id: 0,
        option: "Ahamed Ali",
      },
      {
        id: 1,
        option: "Bilal",
      },
      {
        id: 2,
        option: "Akaram",
      },
      {
        id: 3,
        option: "Binayah",
      },
    ],
    filter_by_team_leader: [
      {
        id: 1,
        option: "Ashwin Prashad",
      },
      {
        id: 2,
        option: "Binayah Support",
      },
      {
        id: 3,
        option: "Hardip Gandara",
      },
      {
        id: 4,
        option: "Michel",
      },
      {
        id: 5,
        option: "Ryan",
      },
      {
        id: 6,
        option: "Yasser Ahmed",
      },
    ],
    filter_by_category: [
      { id: 1, option: "Villa (CR)" },
      { id: 2, option: "Penthouse (RR)" },
      { id: 3, option: "Duplex (RR)" },
      { id: 4, option: "Office (RR)" },
      { id: 5, option: "Retail (CR)" },
      { id: 6, option: "Hotel Apartment (CS)" },
    ],
    filter_by_location: [
      { id: 1, option: "Abu Dhabi Gate City" },
      { id: 2, option: "Abu Shagara" },
      { id: 3, option: "Academic City" },
      { id: 4, option: "Afnan District" },
      { id: 5, option: "Ain Ajman" },
      { id: 6, option: "Ain Al Faydah" },
    ],
    filter_by_sublocation: [
      { id: 1, option: "1 JBR" },
      { id: 2, option: "10C Building" },
      { id: 3, option: "15 Northside" },
      { id: 4, option: "151 Residence" },
      { id: 5, option: "17 Icon Bay" },
      { id: 6, option: "18 Villas Complex" },
      { id: 7, option: "2 Bedroom Villas" },
    ],
    filter_by_Priority: [
      {
        id: 1,
        option: "Urgent",
      },
      {
        id: 2,
        option: "High",
      },
      {
        id: 3,
        option: "Low",
      },
      {
        id: 4,
        option: "Normal",
      },
    ],
    filter_by_min_bed_rooms: [
      {
        id: 1,
        option: "Studio",
      },
      {
        id: 2,
        option: "2 Bed",
      },
      {
        id: 3,
        option: "3 Beds",
      },
      {
        id: 4,
        option: "4 Beds",
      },
      {
        id: 5,
        option: "5 Beds",
      },
      {
        id: 6,
        option: "6 Beds",
      },
      {
        id: 7,
        option: "7 Beds",
      },
      {
        id: 8,
        option: "8 Beds",
      },
      {
        id: 9,
        option: "9 Beds",
      },
      {
        id: 10,
        option: "10 Beds",
      },
      {
        id: 11,
        option: "11 Beds",
      },
      {
        id: 12,
        option: "12 Beds",
      },
      {
        id: 13,
        option: "13 Beds",
      },
      {
        id: 14,
        option: "14 Beds",
      },
      {
        id: 15,
        option: "15 Beds",
      },
    ],
    filter_by_max_bed_rooms: [
      {
        id: 1,
        option: "Studio",
      },
      {
        id: 2,
        option: "2 Bed",
      },
      {
        id: 3,
        option: "3 Beds",
      },
      {
        id: 4,
        option: "4 Beds",
      },
      {
        id: 5,
        option: "5 Beds",
      },
      {
        id: 6,
        option: "6 Beds",
      },
      {
        id: 7,
        option: "7 Beds",
      },
      {
        id: 8,
        option: "8 Beds",
      },
      {
        id: 9,
        option: "9 Beds",
      },
      {
        id: 10,
        option: "10 Beds",
      },
      {
        id: 11,
        option: "11 Beds",
      },
      {
        id: 12,
        option: "12 Beds",
      },
      {
        id: 13,
        option: "13 Beds",
      },
      {
        id: 14,
        option: "14 Beds",
      },
      {
        id: 15,
        option: "15 Beds",
      },
    ],
    filter_by_Emirates: [
      {
        id: 1,
        option: "Dubai",
      },
      {
        id: 2,
        option: "Abu Dhabi",
      },
      {
        id: 3,
        option: "Sharjah",
      },
      {
        id: 4,
        option: "Ajman",
      },
      {
        id: 5,
        option: "Umm Al Quwain",
      },
      {
        id: 6,
        option: "Al Ain",
      },
    ],
  },
];
