import React from "react";
import "../../../asset/css/common/model.css";
import CustomBreadcrumb from "../../Common/customBreadcrumb";
import DeveloperLogoList from "./DeveloperLogoList";

const DeveloperLogoPage = () => {
  return (
    <div className="container page-containners-main">
      <CustomBreadcrumb
        title="Manage Developer Logo"
        home="Dashbord"
        currentPage="Developer Logo"
        addExtraPageTitle=""
        addExtraPageTitlePath=""
      />
      <DeveloperLogoList />
    </div>
  );
};

export default DeveloperLogoPage;
