import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { customStyles } from "../../Common/dataTableStyle";
import Modal from "../../Common/ModelCommon";
import { salesOfferListData } from "../../../asset/StastucJsonData/salesOfferJsonData";
import CustomSelect from "../../Common/customSelectbox";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";

const SalesOfferList = () => {
  // declare state...
  const [searchQuery, setSearchQuery] = useState("");
  const [showAddModel, setShowAddModel] = useState(false);
  const [showEditModel, setShowEditModel] = useState(false);
  const [getDataIdWise, setGetDataIdWise] = useState([]);
  const [selectId, setSelectId] = useState();
  const [selectType, setSelectType] = useState();

  const initialValues = {
    project_logo: "",
    developer_logo: "",
    ref_no: "",
    unit_no: "",
    policy_type: "",
    account_name: "",
    bank: "",
    account_no: "",
    swift_code: "",
  };

  const validationSchema = Yup.object()
    .shape({
      project_logo: Yup.string().required("Field is required."),
      developer_logo: Yup.string().required("Field is required."),
      ref_no: Yup.string().required("Field is required."),
      unit_no: Yup.string().required("Field is required."),
      policy_type: Yup.string().required("Field is required."),
      account_name: Yup.string().required("Field is required."),
      bank: Yup.string().required("Field is required."),
      account_no: Yup.string().required("Field is required."),
      swift_code: Yup.string().required("Field is required."),
    })
    .defined();

  const handleSubmitAddForm = (e) => {
    console.log(e);
    setShowAddModel(false);
  };

  // Hendle search functionality...

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = salesOfferListData.filter((item) =>
    Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  // Add and Edit location model close functionality....

  const handleCloseAddModel = () => {
    setShowAddModel(false);
  };
  const handleCloseEditModel = () => {
    setShowEditModel(false);
  };

  // Get data id wise...

  const getIdWiseData = (id) => {
    const findData = salesOfferListData.find((user_id) => id === user_id?._id);
    setGetDataIdWise(findData);
  };

  // Use Effect...
  useEffect(() => {
    getIdWiseData(selectId);
  }, [selectId]);

  const column = [
    {
      name: "Sr.",
      selector: (row, index) => index + 1,
      center: true,
      wrap: true,
      width: "100px",
    },
    {
      name: "Ref No.",
      cell: (row) => <div className="pl-2">{row?.ref_no}</div>,
      center: false,
      wrap: true,
    },
    {
      name: "Unit Number",
      cell: (row) => <div className="pl-2">{row?.unit_no}</div>,
      center: false,
      wrap: true,
    },
    {
      name: "Edit",
      cell: (row) => (
        <>
          <div
            onClick={() => {
              setShowEditModel(true);
              setSelectId(row?._id);
            }}
            className="cursor-pointer"
          >
            <img width={"20px;"} src="/svg/pencil.png" alt="img" />
          </div>
        </>
      ),
      center: true,
      wrap: true,
      width: "100px",
    },
    {
      name: "View PDF",
      cell: (row) => (
        <>
          <div className="cursor-pointer">
            <img width={"20px;"} src="/svg/file.png" alt="img" />
          </div>
        </>
      ),
      center: true,
      wrap: true,
      width: "100px",
    },
  ];

  return (
    <>
      <div className="d-flex datatable-header-main">
        <button
          type="button"
          className="btn filter_button add_property_button rounded-0"
          onClick={() => setShowAddModel(true)}
        >
          <i className="fa fa-plus me-2"></i>Add Sales Offer
        </button>
        <div className="searchbox-parant-main ">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearch}
            className="add-search-box-in-datatable rounded-0"
          />
          <i className="fa fa-search"></i>
        </div>
      </div>

      <DataTable
        columns={column}
        data={filteredData ? filteredData : salesOfferListData}
        customStyles={customStyles}
        fixedHeader={true}
        noHeader={false}
        noDataComponent="No data available"
        pagination
        searchable
        responsive
        highlightOnHover
        persistTableHead
      />

      {/* Add model */}

      <Modal show={showAddModel} handleClose={handleCloseAddModel}>
        <div className="sdvds">
          <h5 className="mb-5 text-center fw-bold">Add Sales Offer</h5>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmitAddForm}
          >
            {(formik) => {
              const { setFieldValue } = formik;
              return (
                <>
                  <Form>
                    <div className="row">
                      <div className="col-lg-4 col-md-6 col-sm-12 mb-2">
                        <p className="m-0">
                          Project Logo <span className="text-danger">*</span>
                        </p>
                        <CustomSelect
                          options={[]}
                          placeholder=" - Select  - "
                          value={selectType}
                          onChange={(name, value) => {
                            setFieldValue(name, value);
                          }}
                          name={"project_logo"}
                        />
                        <ErrorMessage
                          name="project_logo"
                          component={"div"}
                          className="text-danger"
                        />
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12 mb-2">
                        <p className="m-0">
                          Developer Logo <span className="text-danger">*</span>
                        </p>
                        <CustomSelect
                          options={[]}
                          placeholder=" - Select  - "
                          value={selectType}
                          onChange={(name, value) => {
                            setFieldValue(name, value);
                          }}
                          name={"developer_logo"}
                        />
                        <ErrorMessage
                          name="developer_logo"
                          component={"div"}
                          className="text-danger"
                        />
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12 mb-2">
                        <p className="m-0 ">Ref No.</p>
                        <Field
                          name="ref_no"
                          className="form-control"
                          type="text"
                          placeholder="Enter Ref "
                        />
                        <ErrorMessage
                          name="ref_no"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12 mb-2">
                        <p className="m-0">
                          Unit No.<span className="text-danger">*</span>
                        </p>
                        <CustomSelect
                          options={[]}
                          placeholder=" - Select  - "
                          value={selectType}
                          onChange={(name, value) => {
                            setFieldValue(name, value);
                          }}
                          name="unit_no"
                        />
                        <ErrorMessage
                          name="unit_no"
                          component={"div"}
                          className="text-danger"
                        />
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12 mb-2">
                        <p className="m-0 ">Policy Type</p>
                        <Field
                          name="policy_type"
                          className="form-control"
                          type="text"
                          placeholder="Enter policy"
                        />
                        <ErrorMessage
                          name="policy_type"
                          component={"div"}
                          className="text-danger"
                        />
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12 mb-2">
                        <p className="m-0 ">Account Name</p>
                        <Field
                          name="account_name"
                          className="form-control"
                          type="text"
                          placeholder="Enter account name"
                        />
                        <ErrorMessage
                          name="account_name"
                          component={"div"}
                          className="text-danger"
                        />
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12 mb-2">
                        <p className="m-0 ">Bank</p>
                        <Field
                          name="bank"
                          className="form-control"
                          type="text"
                          placeholder="Enter Bank"
                        />
                        <ErrorMessage
                          name="bank"
                          component={"div"}
                          className="text-danger"
                        />
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12 mb-2">
                        <p className="m-0 ">Account No.</p>
                        <Field
                          name="account_no"
                          className="form-control"
                          type="text"
                          placeholder="Enter account no"
                        />
                        <ErrorMessage
                          name="account_no"
                          component={"div"}
                          className="text-danger"
                        />
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12 mb-2">
                        <p className="m-0 ">Swift Code</p>
                        <Field
                          name="swift_code"
                          className="form-control"
                          type="text"
                          placeholder="Enter swift code"
                        />
                        <ErrorMessage
                          name="swift_code"
                          component={"div"}
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="text-center mt-5 mb-5">
                      <button type="submit" className="btn btn-secondary">
                        Submit
                      </button>
                    </div>
                  </Form>
                </>
              );
            }}
          </Formik>
        </div>
      </Modal>

      {/* Edit Model */}

      <Modal show={showEditModel} handleClose={handleCloseEditModel}>
        <div className="sdvds">
          <h5 className="mb-5 text-center fw-bold">Edit Sales Offer</h5>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmitAddForm}
          >
            {(formik) => {
              const { setFieldValue } = formik;
              return (
                <>
                  <Form>
                    <div className="row">
                      <div className="col-lg-4 col-md-6 col-sm-12 mb-2">
                        <p className="m-0">
                          Project Logo <span className="text-danger">*</span>
                        </p>
                        <CustomSelect
                          options={[]}
                          placeholder=" - Select  - "
                          value={selectType}
                          onChange={(name, value) => {
                            setFieldValue(name, value);
                          }}
                          name={"project_logo"}
                        />
                        <ErrorMessage
                          name="project_logo"
                          component={"div"}
                          className="text-danger"
                        />
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12 mb-2">
                        <p className="m-0">
                          Developer Logo <span className="text-danger">*</span>
                        </p>
                        <CustomSelect
                          options={[]}
                          placeholder=" - Select  - "
                          value={selectType}
                          onChange={(name, value) => {
                            setFieldValue(name, value);
                          }}
                          name={"developer_logo"}
                        />
                        <ErrorMessage
                          name="developer_logo"
                          component={"div"}
                          className="text-danger"
                        />
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12 mb-2">
                        <p className="m-0 ">Ref No.</p>
                        <Field
                          name="ref_no"
                          className="form-control"
                          type="text"
                          placeholder="Enter Ref "
                        />
                        <ErrorMessage
                          name="ref_no"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12 mb-2">
                        <p className="m-0">
                          Unit No.<span className="text-danger">*</span>
                        </p>
                        <CustomSelect
                          options={[]}
                          placeholder=" - Select  - "
                          value={selectType}
                          onChange={(name, value) => {
                            setFieldValue(name, value);
                          }}
                          name="unit_no"
                        />
                        <ErrorMessage
                          name="unit_no"
                          component={"div"}
                          className="text-danger"
                        />
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12 mb-2">
                        <p className="m-0 ">Policy Type</p>
                        <Field
                          name="policy_type"
                          className="form-control"
                          type="text"
                          placeholder="Enter policy"
                        />
                        <ErrorMessage
                          name="policy_type"
                          component={"div"}
                          className="text-danger"
                        />
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12 mb-2">
                        <p className="m-0 ">Account Name</p>
                        <Field
                          name="account_name"
                          className="form-control"
                          type="text"
                          placeholder="Enter account name"
                        />
                        <ErrorMessage
                          name="account_name"
                          component={"div"}
                          className="text-danger"
                        />
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12 mb-2">
                        <p className="m-0 ">Bank</p>
                        <Field
                          name="bank"
                          className="form-control"
                          type="text"
                          placeholder="Enter Bank"
                        />
                        <ErrorMessage
                          name="bank"
                          component={"div"}
                          className="text-danger"
                        />
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12 mb-2">
                        <p className="m-0 ">Account No.</p>
                        <Field
                          name="account_no"
                          className="form-control"
                          type="text"
                          placeholder="Enter account no"
                        />
                        <ErrorMessage
                          name="account_no"
                          component={"div"}
                          className="text-danger"
                        />
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12 mb-2">
                        <p className="m-0 ">Swift Code</p>
                        <Field
                          name="swift_code"
                          className="form-control"
                          type="text"
                          placeholder="Enter swift code"
                        />
                        <ErrorMessage
                          name="swift_code"
                          component={"div"}
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="text-center mt-5 mb-5">
                      <button type="submit" className="btn btn-secondary">
                        Submit
                      </button>
                    </div>
                  </Form>
                </>
              );
            }}
          </Formik>
        </div>
      </Modal>
    </>
  );
};

export default SalesOfferList;
