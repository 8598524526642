import React from "react";
import CustomBreadcrumb from "../../../Common/customBreadcrumb";
import AddAllLead from "./AddAllLead";

const AddAllLeadPage = () => {
  return (
    <>
      <div className="container page-containners-main ">
        <CustomBreadcrumb
          title="Add All Lead"
          home="Dashboard"
          currentPage="Add All Leads"
          addExtraPageTitle="All Leads"
          addExtraPageTitlePath="all-leads"
        />
        <AddAllLead />
      </div>
    </>
  );
};

export default AddAllLeadPage;
