// All Deals Static data...

export const allDealsData = [
  {
    _id: "660fa7213644bf04fd93e6bb",
    Reference: "BY-D-3989",
    Client_ID: 1045,
    Type: "Rental",
    Created_By: "Binayah Support",
    "Landlord/Seller_Name": "--",
    "Landlord/Seller_Mobile": "--",
    "Landlord/Seller_Email": "--",
    "Landlord/Seller_Nationality": "--",
    Lead_Ref: "--",
    "Tenant/Buyer_Name": "YASMIN SAMIR",
    "Tenant/Buyer_Mobile": "+971 509558791",
    "Tenant/Buyer_Email": "--",
    "Tenant/Buyer_Nationality": "--",
    Listings_Ref: "--",
    Listings_Beds: "--",
    Listings_UnitNo: 316,
    Listings_Unit_Type: "--",
    Listings_Street_No: "--",
    Listings_Floor_No: "--",
    Listings_Category: "Apartment",
    Builtup_Area: "--",
    Plot_Area: "--",
    View: "--",
    Furnished: "--",
    Transaction_Type: "Leased",
    Region: "Dubai",
    Area_Location: "Jumeirah Village Triangle",
    Sub_Area_Location: "La Residence",
    Cheques: "--",
    Price: "95,000.00",
    Deposit: "4,750.00",
    Commission: "4,750.00",
    "Agency_Fee_Landlord/Seller": "0",
    Tenancy_Renewal_Date: "24-01-2025 00:00",
    Tenancy_Start_Date: "25-01-2024 00:00",
    Actual_Deal_Date: "29-03-2024 00:00",
    Deal_Estimated_Date: "--",
    Source_of_Deal: "--",
    Agent1: "Masood Ahmad",
    "Agent_1_Commission%": "--",
    Agent_1_Commission: "--",
    Agent2: "--",
    "Agent_2_Commission%": "--",
    Agent_2_Commission: "--",
    Agent3: "--",
    "Agent_3_Commission%": "--",
    Agent_3_Commission: "--",
    VAT_Amount: 0,
    Commission_VAT_Amount: 237.5,
    Status: "Closed",
    Sub_Status: "Successful",
    External_Referral_Commission: "--",
    Your_Company_Commission: "4,750.00",
    Buyer_Type: "--",
    Finance_Type: "--",
    Date_Added: "29-03-2024 12:10",
    Date_Updated: "29-03-2024 12:10",
  },
  {
    _id: "660fa7213644bf04fd93e6bc",
    Reference: "BY-D-3988",
    Client_ID: 1045,
    Type: "Rental",
    Created_By: "Binayah Support",
    "Landlord/Seller_Name": "--",
    "Landlord/Seller_Mobile": "--",
    "Landlord/Seller_Email": "--",
    "Landlord/Seller_Nationality": "--",
    Lead_Ref: "--",
    "Tenant/Buyer_Name": "rawan rami",
    "Tenant/Buyer_Mobile": "+971 526920631",
    "Tenant/Buyer_Email": "--",
    "Tenant/Buyer_Nationality": "--",
    Listings_Ref: "--",
    Listings_Beds: "--",
    Listings_UnitNo: 217,
    Listings_Unit_Type: "--",
    Listings_Street_No: "--",
    Listings_Floor_No: "--",
    Listings_Category: "Apartment",
    Builtup_Area: "--",
    Plot_Area: "--",
    View: "--",
    Furnished: "--",
    Transaction_Type: "Renew Leased",
    Region: "Dubai",
    Area_Location: "Jumeirah Village Triangle",
    Sub_Area_Location: "La Residence",
    Cheques: "--",
    Price: "31,250.00",
    Deposit: "--",
    Commission: "1,050.00",
    "Agency_Fee_Landlord/Seller": "0",
    Tenancy_Renewal_Date: "31-07-2024 00:00",
    Tenancy_Start_Date: "01-03-2024 00:00",
    Actual_Deal_Date: "29-03-2024 00:00",
    Deal_Estimated_Date: "--",
    Source_of_Deal: "--",
    Agent1: "Masood Ahmad",
    "Agent_1_Commission%": "--",
    Agent_1_Commission: "--",
    Agent2: "--",
    "Agent_2_Commission%": "--",
    Agent_2_Commission: "--",
    Agent3: "--",
    "Agent_3_Commission%": "--",
    Agent_3_Commission: "--",
    VAT_Amount: 0,
    Commission_VAT_Amount: 52.5,
    Status: "Closed",
    Sub_Status: "Successful",
    External_Referral_Commission: "--",
    Your_Company_Commission: "1,050.00",
    Buyer_Type: "--",
    Finance_Type: "--",
    Date_Added: "29-03-2024 12:07",
    Date_Updated: "29-03-2024 12:07",
  },
  {
    _id: "660fa7213644bf04fd93e6bd",
    Reference: "BY-D-3987",
    Client_ID: 1045,
    Type: "Rental",
    Created_By: "Binayah Support",
    "Landlord/Seller_Name": "--",
    "Landlord/Seller_Mobile": "--",
    "Landlord/Seller_Email": "--",
    "Landlord/Seller_Nationality": "--",
    Lead_Ref: "--",
    "Tenant/Buyer_Name": "ZAIN  KHAN",
    "Tenant/Buyer_Mobile": "+971 556143098",
    "Tenant/Buyer_Email": "--",
    "Tenant/Buyer_Nationality": "--",
    Listings_Ref: "--",
    Listings_Beds: "--",
    Listings_UnitNo: 426,
    Listings_Unit_Type: "--",
    Listings_Street_No: "--",
    Listings_Floor_No: "--",
    Listings_Category: "Villa",
    Builtup_Area: "--",
    Plot_Area: "--",
    View: "--",
    Furnished: "--",
    Transaction_Type: "Renew Leased",
    Region: "Dubai",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Cheques: "--",
    Price: "1,18,740.00",
    Deposit: "--",
    Commission: "1,050.00",
    "Agency_Fee_Landlord/Seller": "0",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Actual_Deal_Date: "29-03-2024 00:00",
    Deal_Estimated_Date: "--",
    Source_of_Deal: "--",
    Agent1: "Talal Ahmad",
    "Agent_1_Commission%": "--",
    Agent_1_Commission: "--",
    Agent2: "--",
    "Agent_2_Commission%": "--",
    Agent_2_Commission: "--",
    Agent3: "--",
    "Agent_3_Commission%": "--",
    Agent_3_Commission: "--",
    VAT_Amount: 0,
    Commission_VAT_Amount: 52.5,
    Status: "Closed",
    Sub_Status: "Successful",
    External_Referral_Commission: "--",
    Your_Company_Commission: "1,050.00",
    Buyer_Type: "--",
    Finance_Type: "--",
    Date_Added: "29-03-2024 11:49",
    Date_Updated: "29-03-2024 11:51",
  },
  {
    _id: "660fa7213644bf04fd93e6be",
    Reference: "BY-D-3986",
    Client_ID: 1045,
    Type: "Rental",
    Created_By: "Binayah Support",
    "Landlord/Seller_Name": "--",
    "Landlord/Seller_Mobile": "--",
    "Landlord/Seller_Email": "--",
    "Landlord/Seller_Nationality": "--",
    Lead_Ref: "--",
    "Tenant/Buyer_Name": "SINAN  PURI",
    "Tenant/Buyer_Mobile": "+971 525647369",
    "Tenant/Buyer_Email": "--",
    "Tenant/Buyer_Nationality": "--",
    Listings_Ref: "--",
    Listings_Beds: "--",
    Listings_UnitNo: 1107,
    Listings_Unit_Type: "--",
    Listings_Street_No: "--",
    Listings_Floor_No: "--",
    Listings_Category: "Apartment",
    Builtup_Area: "--",
    Plot_Area: "--",
    View: "--",
    Furnished: "--",
    Transaction_Type: "Renew Leased",
    Region: "Dubai",
    Area_Location: "Al Jaddaf",
    Sub_Area_Location: "Farhad Azizi Residence",
    Cheques: "--",
    Price: "8,000.00",
    Deposit: "--",
    Commission: "1,050.00",
    "Agency_Fee_Landlord/Seller": "0",
    Tenancy_Renewal_Date: "11-03-2024 00:00",
    Tenancy_Start_Date: "12-01-2024 00:00",
    Actual_Deal_Date: "29-03-2024 00:00",
    Deal_Estimated_Date: "--",
    Source_of_Deal: "--",
    Agent1: "Bilal Ahmad",
    "Agent_1_Commission%": "--",
    Agent_1_Commission: "--",
    Agent2: "--",
    "Agent_2_Commission%": "--",
    Agent_2_Commission: "--",
    Agent3: "--",
    "Agent_3_Commission%": "--",
    Agent_3_Commission: "--",
    VAT_Amount: 0,
    Commission_VAT_Amount: 52.5,
    Status: "Closed",
    Sub_Status: "Successful",
    External_Referral_Commission: "--",
    Your_Company_Commission: "1,050.00",
    Buyer_Type: "--",
    Finance_Type: "--",
    Date_Added: "29-03-2024 11:48",
    Date_Updated: "29-03-2024 11:48",
  },
  {
    _id: "660fa7213644bf04fd93e6bf",
    Reference: "BY-D-3985",
    Client_ID: 1045,
    Type: "Rental",
    Created_By: "Binayah Support",
    "Landlord/Seller_Name": "--",
    "Landlord/Seller_Mobile": "--",
    "Landlord/Seller_Email": "--",
    "Landlord/Seller_Nationality": "--",
    Lead_Ref: "--",
    "Tenant/Buyer_Name": " ",
    "Tenant/Buyer_Mobile": "+971 585948656",
    "Tenant/Buyer_Email": "--",
    "Tenant/Buyer_Nationality": "--",
    Listings_Ref: "--",
    Listings_Beds: "--",
    Listings_UnitNo: "G02",
    Listings_Unit_Type: "--",
    Listings_Street_No: "--",
    Listings_Floor_No: "--",
    Listings_Category: "Apartment",
    Builtup_Area: "--",
    Plot_Area: "--",
    View: "--",
    Furnished: "--",
    Transaction_Type: "Leased",
    Region: "Dubai",
    Area_Location: "Jumeirah Village Triangle",
    Sub_Area_Location: "La Residence",
    Cheques: "3",
    Price: "75,000.00",
    Deposit: "3,750.00",
    Commission: "3,750.00",
    "Agency_Fee_Landlord/Seller": "0",
    Tenancy_Renewal_Date: "14-02-2025 00:00",
    Tenancy_Start_Date: "15-02-2024 00:00",
    Actual_Deal_Date: "28-03-2024 00:00",
    Deal_Estimated_Date: "--",
    Source_of_Deal: "--",
    Agent1: "Masood Ahmad",
    "Agent_1_Commission%": "937.5",
    Agent_1_Commission: "25",
    Agent2: "--",
    "Agent_2_Commission%": "--",
    Agent_2_Commission: "--",
    Agent3: "--",
    "Agent_3_Commission%": "--",
    Agent_3_Commission: "--",
    VAT_Amount: 0,
    Commission_VAT_Amount: 187.5,
    Status: "Closed",
    Sub_Status: "Successful",
    External_Referral_Commission: "--",
    Your_Company_Commission: "3,750.00",
    Buyer_Type: "--",
    Finance_Type: "--",
    Date_Added: "28-03-2024 13:17",
    Date_Updated: "28-03-2024 13:17",
  },
  {
    _id: "660fa7213644bf04fd93e6c0",
    Reference: "BY-D-3984",
    Client_ID: 1045,
    Type: "Rental",
    Created_By: "Binayah Support",
    "Landlord/Seller_Name": "--",
    "Landlord/Seller_Mobile": "--",
    "Landlord/Seller_Email": "--",
    "Landlord/Seller_Nationality": "--",
    Lead_Ref: "--",
    "Tenant/Buyer_Name": "Niruk .",
    "Tenant/Buyer_Mobile": "+971 551600291",
    "Tenant/Buyer_Email": "--",
    "Tenant/Buyer_Nationality": "--",
    Listings_Ref: "--",
    Listings_Beds: "--",
    Listings_UnitNo: 306,
    Listings_Unit_Type: "--",
    Listings_Street_No: "--",
    Listings_Floor_No: "--",
    Listings_Category: "Apartment",
    Builtup_Area: "--",
    Plot_Area: "--",
    View: "--",
    Furnished: "--",
    Transaction_Type: "--",
    Region: "Dubai",
    Area_Location: "Silicon Oasis",
    Sub_Area_Location: "Axis Silver",
    Cheques: "--",
    Price: "45,150.00",
    Deposit: "--",
    Commission: "1,050.00",
    "Agency_Fee_Landlord/Seller": "0",
    Tenancy_Renewal_Date: "21-11-2024 00:00",
    Tenancy_Start_Date: "22-11-2023 00:00",
    Actual_Deal_Date: "28-03-2024 00:00",
    Deal_Estimated_Date: "--",
    Source_of_Deal: "--",
    Agent1: "Talal Ahmad",
    "Agent_1_Commission%": "--",
    Agent_1_Commission: "--",
    Agent2: "--",
    "Agent_2_Commission%": "--",
    Agent_2_Commission: "--",
    Agent3: "--",
    "Agent_3_Commission%": "--",
    Agent_3_Commission: "--",
    VAT_Amount: 0,
    Commission_VAT_Amount: 52.5,
    Status: "Closed",
    Sub_Status: "Successful",
    External_Referral_Commission: "--",
    Your_Company_Commission: "1,050.00",
    Buyer_Type: "--",
    Finance_Type: "--",
    Date_Added: "28-03-2024 13:09",
    Date_Updated: "28-03-2024 13:09",
  },
  {
    _id: "660fa7213644bf04fd93e6c1",
    Reference: "BY-D-3983",
    Client_ID: 1045,
    Type: "Rental",
    Created_By: "Binayah Support",
    "Landlord/Seller_Name": "--",
    "Landlord/Seller_Mobile": "--",
    "Landlord/Seller_Email": "--",
    "Landlord/Seller_Nationality": "--",
    Lead_Ref: "--",
    "Tenant/Buyer_Name": " ",
    "Tenant/Buyer_Mobile": "+971 529814616",
    "Tenant/Buyer_Email": "--",
    "Tenant/Buyer_Nationality": "--",
    Listings_Ref: "--",
    Listings_Beds: "--",
    Listings_UnitNo: 307,
    Listings_Unit_Type: "--",
    Listings_Street_No: "--",
    Listings_Floor_No: "--",
    Listings_Category: "Apartment",
    Builtup_Area: "--",
    Plot_Area: "--",
    View: "--",
    Furnished: "--",
    Transaction_Type: "Renew Leased",
    Region: "Dubai",
    Area_Location: "Jumeirah Village Triangle",
    Sub_Area_Location: "La Residence",
    Cheques: "--",
    Price: "20,415.00",
    Deposit: "--",
    Commission: "1,050.00",
    "Agency_Fee_Landlord/Seller": "0",
    Tenancy_Renewal_Date: "17-07-2024 00:00",
    Tenancy_Start_Date: "17-02-2024 00:00",
    Actual_Deal_Date: "28-03-2024 00:00",
    Deal_Estimated_Date: "--",
    Source_of_Deal: "--",
    Agent1: "Masood Ahmad",
    "Agent_1_Commission%": "--",
    Agent_1_Commission: "--",
    Agent2: "--",
    "Agent_2_Commission%": "--",
    Agent_2_Commission: "--",
    Agent3: "--",
    "Agent_3_Commission%": "--",
    Agent_3_Commission: "--",
    VAT_Amount: 0,
    Commission_VAT_Amount: 52.5,
    Status: "Closed",
    Sub_Status: "Successful",
    External_Referral_Commission: "--",
    Your_Company_Commission: "1,050.00",
    Buyer_Type: "--",
    Finance_Type: "--",
    Date_Added: "28-03-2024 12:38",
    Date_Updated: "28-03-2024 12:38",
  },
  {
    _id: "660fa7213644bf04fd93e6c2",
    Reference: "BY-D-3982",
    Client_ID: 1045,
    Type: "Rental",
    Created_By: "Binayah Support",
    "Landlord/Seller_Name": "--",
    "Landlord/Seller_Mobile": "--",
    "Landlord/Seller_Email": "--",
    "Landlord/Seller_Nationality": "--",
    Lead_Ref: "--",
    "Tenant/Buyer_Name": "ALI AMER SHAKIR",
    "Tenant/Buyer_Mobile": "+971 504589184",
    "Tenant/Buyer_Email": "--",
    "Tenant/Buyer_Nationality": "--",
    Listings_Ref: "--",
    Listings_Beds: "--",
    Listings_UnitNo: 306,
    Listings_Unit_Type: "--",
    Listings_Street_No: "--",
    Listings_Floor_No: "--",
    Listings_Category: "Apartment",
    Builtup_Area: "--",
    Plot_Area: "--",
    View: "--",
    Furnished: "--",
    Transaction_Type: "Leased",
    Region: "Dubai",
    Area_Location: "Jumeirah Village Triangle",
    Sub_Area_Location: "La Residence",
    Cheques: "--",
    Price: "70,000.00",
    Deposit: "3,500.00",
    Commission: "3,500.00",
    "Agency_Fee_Landlord/Seller": "0",
    Tenancy_Renewal_Date: "08-03-2025 00:00",
    Tenancy_Start_Date: "09-03-2024 00:00",
    Actual_Deal_Date: "28-03-2024 00:00",
    Deal_Estimated_Date: "--",
    Source_of_Deal: "--",
    Agent1: "Masood Ahmad",
    "Agent_1_Commission%": "--",
    Agent_1_Commission: "--",
    Agent2: "--",
    "Agent_2_Commission%": "--",
    Agent_2_Commission: "--",
    Agent3: "--",
    "Agent_3_Commission%": "--",
    Agent_3_Commission: "--",
    VAT_Amount: 0,
    Commission_VAT_Amount: 175,
    Status: "Closed",
    Sub_Status: "Successful",
    External_Referral_Commission: "--",
    Your_Company_Commission: "3,500.00",
    Buyer_Type: "--",
    Finance_Type: "--",
    Date_Added: "28-03-2024 12:31",
    Date_Updated: "28-03-2024 12:31",
  },
  {
    _id: "660fa7213644bf04fd93e6c3",
    Reference: "BY-D-3981",
    Client_ID: 1045,
    Type: "Rental",
    Created_By: "Binayah Support",
    "Landlord/Seller_Name": "--",
    "Landlord/Seller_Mobile": "--",
    "Landlord/Seller_Email": "--",
    "Landlord/Seller_Nationality": "--",
    Lead_Ref: "--",
    "Tenant/Buyer_Name": "FABIAN RITTER",
    "Tenant/Buyer_Mobile": "+971 523536658",
    "Tenant/Buyer_Email": "--",
    "Tenant/Buyer_Nationality": "--",
    Listings_Ref: "--",
    Listings_Beds: "--",
    Listings_UnitNo: 156,
    Listings_Unit_Type: "--",
    Listings_Street_No: "--",
    Listings_Floor_No: "--",
    Listings_Category: "Villa",
    Builtup_Area: "--",
    Plot_Area: "--",
    View: "--",
    Furnished: "--",
    Transaction_Type: "Renew Leased",
    Region: "Dubai",
    Area_Location: "Reem",
    Sub_Area_Location: "Mira 5",
    Cheques: "--",
    Price: "1,65,000.00",
    Deposit: "7,900.00",
    Commission: "1,050.00",
    "Agency_Fee_Landlord/Seller": "0",
    Tenancy_Renewal_Date: "30-04-2025 00:00",
    Tenancy_Start_Date: "01-05-2024 00:00",
    Actual_Deal_Date: "28-03-2024 00:00",
    Deal_Estimated_Date: "--",
    Source_of_Deal: "--",
    Agent1: "Talal Ahmad",
    "Agent_1_Commission%": "--",
    Agent_1_Commission: "--",
    Agent2: "--",
    "Agent_2_Commission%": "--",
    Agent_2_Commission: "--",
    Agent3: "--",
    "Agent_3_Commission%": "--",
    Agent_3_Commission: "--",
    VAT_Amount: 0,
    Commission_VAT_Amount: 52.5,
    Status: "Closed",
    Sub_Status: "Successful",
    External_Referral_Commission: "--",
    Your_Company_Commission: "1,050.00",
    Buyer_Type: "--",
    Finance_Type: "--",
    Date_Added: "28-03-2024 12:30",
    Date_Updated: "28-03-2024 12:30",
  },
  {
    _id: "660fa7213644bf04fd93e6c4",
    Reference: "BY-D-3980",
    Client_ID: 1045,
    Type: "Rental",
    Created_By: "Binayah Support",
    "Landlord/Seller_Name": "--",
    "Landlord/Seller_Mobile": "--",
    "Landlord/Seller_Email": "--",
    "Landlord/Seller_Nationality": "--",
    Lead_Ref: "--",
    "Tenant/Buyer_Name": "Belen Ms.",
    "Tenant/Buyer_Mobile": "+971 551525804",
    "Tenant/Buyer_Email": "--",
    "Tenant/Buyer_Nationality": "--",
    Listings_Ref: "--",
    Listings_Beds: "--",
    Listings_UnitNo: 307,
    Listings_Unit_Type: "--",
    Listings_Street_No: "--",
    Listings_Floor_No: "--",
    Listings_Category: "Apartment",
    Builtup_Area: "--",
    Plot_Area: "--",
    View: "--",
    Furnished: "--",
    Transaction_Type: "Renew Leased",
    Region: "Dubai",
    Area_Location: "Silicon Oasis",
    Sub_Area_Location: "Axis Silver",
    Cheques: "--",
    Price: "45,150.00",
    Deposit: "--",
    Commission: "1,050.00",
    "Agency_Fee_Landlord/Seller": "0",
    Tenancy_Renewal_Date: "21-11-2024 00:00",
    Tenancy_Start_Date: "22-11-2023 00:00",
    Actual_Deal_Date: "28-03-2024 00:00",
    Deal_Estimated_Date: "--",
    Source_of_Deal: "--",
    Agent1: "Talal Ahmad",
    "Agent_1_Commission%": "--",
    Agent_1_Commission: "--",
    Agent2: "--",
    "Agent_2_Commission%": "--",
    Agent_2_Commission: "--",
    Agent3: "--",
    "Agent_3_Commission%": "--",
    Agent_3_Commission: "--",
    VAT_Amount: 0,
    Commission_VAT_Amount: 52.5,
    Status: "Closed",
    Sub_Status: "Successful",
    External_Referral_Commission: "--",
    Your_Company_Commission: "1,050.00",
    Buyer_Type: "--",
    Finance_Type: "--",
    Date_Added: "28-03-2024 12:28",
    Date_Updated: "28-03-2024 12:28",
  },
  {
    _id: "660fa7213644bf04fd93e6c5",
    Reference: "BY-D-3979",
    Client_ID: 1045,
    Type: "Rental",
    Created_By: "Binayah Support",
    "Landlord/Seller_Name": "--",
    "Landlord/Seller_Mobile": "--",
    "Landlord/Seller_Email": "--",
    "Landlord/Seller_Nationality": "--",
    Lead_Ref: "--",
    "Tenant/Buyer_Name": "Mohammad .",
    "Tenant/Buyer_Mobile": "+971 504478551",
    "Tenant/Buyer_Email": "--",
    "Tenant/Buyer_Nationality": "--",
    Listings_Ref: "--",
    Listings_Beds: "--",
    Listings_UnitNo: 189,
    Listings_Unit_Type: "--",
    Listings_Street_No: "--",
    Listings_Floor_No: "--",
    Listings_Category: "Villa",
    Builtup_Area: "--",
    Plot_Area: "--",
    View: "--",
    Furnished: "--",
    Transaction_Type: "Renew Leased",
    Region: "Dubai",
    Area_Location: "Reem",
    Sub_Area_Location: "Mira 1",
    Cheques: "--",
    Price: "1,40,000.00",
    Deposit: "6,250.00",
    Commission: "1,050.00",
    "Agency_Fee_Landlord/Seller": "0",
    Tenancy_Renewal_Date: "30-09-2024 00:00",
    Tenancy_Start_Date: "01-10-2023 00:00",
    Actual_Deal_Date: "28-03-2024 00:00",
    Deal_Estimated_Date: "--",
    Source_of_Deal: "--",
    Agent1: "Talal Ahmad",
    "Agent_1_Commission%": "--",
    Agent_1_Commission: "--",
    Agent2: "--",
    "Agent_2_Commission%": "--",
    Agent_2_Commission: "--",
    Agent3: "--",
    "Agent_3_Commission%": "--",
    Agent_3_Commission: "--",
    VAT_Amount: 0,
    Commission_VAT_Amount: 52.5,
    Status: "Closed",
    Sub_Status: "Successful",
    External_Referral_Commission: "--",
    Your_Company_Commission: "1,050.00",
    Buyer_Type: "--",
    Finance_Type: "--",
    Date_Added: "28-03-2024 12:23",
    Date_Updated: "28-03-2024 12:23",
  },
];

// ADD AND EDIT SELECT BOX FILTER DATA...

export const addDealsOptionsData = [
  {
    select_by_type: [
      {
        id: 1,
        option: "Rental",
      },
      {
        id: 2,
        option: "sale",
      },
    ],
    transaction_type: [
      {
        id: 1,
        option: "Sale",
      },
      {
        id: 2,
        option: "Resale",
      },
      {
        id: 3,
        option: "Leased",
      },
      {
        id: 4,
        option: "Renew Leased",
      },
      {
        id: 5,
        option: "Reserved",
      },
      {
        id: 6,
        option: "Renew Reserved",
      },
      {
        id: 7,
        option: "Off Plan",
      },
    ],
    category_type: [
      {
        id: 1,
        option: "Sheena",
      },
      {
        id: 3,
        option: "Von",
      },
      {
        id: 4,
        option: "Trisha",
      },
      {
        id: 5,
        option: "Property Manager",
      },
    ],
    listing_ref: [
      {
        id: 1,
        option: " BY-D-3989",
      },
      {
        id: 2,
        option: "BY-D-3988",
      },
      {
        id: 3,
        option: "BY-D-3987",
      },
      {
        id: 4,
        option: "BY-D-3986",
      },
      {
        id: 5,
        option: "BY-D-3985",
      },
      {
        id: 6,
        option: "BY-D-3984",
      },
      {
        id: 6,
        option: "BY-D-3983",
      },
      {
        id: 7,
        option: "BY-D-3982",
      },
      {
        id: 8,
        option: "BY-D-3981",
      },
      {
        id: 9,
        option: "BY-D-3980",
      },
      {
        id: 10,
        option: "BY-D-3979",
      },
      {
        id: 11,
        option: "BY-D-3978",
      },
      {
        id: 12,
        option: "BY-D-3977",
      },
      {
        id: 13,
        option: "BY-D-3976",
      },
      {
        id: 14,
        option: "BY-D-3975",
      },
      {
        id: 15,
        option: "BY-D-3974",
      },
      {
        id: 16,
        option: "BY-D-3973",
      },
      {
        id: 17,
        option: "BY-D-3972",
      },
      {
        id: 18,
        option: "BY-D-3971",
      },
      {
        id: 19,
        option: "BY-D-3970",
      },
      {
        id: 20,
        option: "BY-D-3969",
      },
      {
        id: 21,
        option: "BY-D-3968",
      },
      {
        id: 22,
        option: "BY-D-3968",
      },
      {
        id: 23,
        option: "BY-D-3967",
      },
      {
        id: 24,
        option: "BY-D-3966",
      },
      {
        id: 25,
        option: "BY-D-3965",
      },
    ],
    lead_ref: [
      {
        id: 1,
        option: " BY-D-3989",
      },
      {
        id: 2,
        option: "BY-D-3988",
      },
      {
        id: 3,
        option: "BY-D-3987",
      },
      {
        id: 4,
        option: "BY-D-3986",
      },
      {
        id: 5,
        option: "BY-D-3985",
      },
      {
        id: 6,
        option: "BY-D-3984",
      },
      {
        id: 6,
        option: "BY-D-3983",
      },
      {
        id: 7,
        option: "BY-D-3982",
      },
      {
        id: 8,
        option: "BY-D-3981",
      },
      {
        id: 9,
        option: "BY-D-3980",
      },
      {
        id: 10,
        option: "BY-D-3979",
      },
      {
        id: 11,
        option: "BY-D-3978",
      },
      {
        id: 12,
        option: "BY-D-3977",
      },
      {
        id: 13,
        option: "BY-D-3976",
      },
      {
        id: 14,
        option: "BY-D-3975",
      },
      {
        id: 15,
        option: "BY-D-3974",
      },
      {
        id: 16,
        option: "BY-D-3973",
      },
      {
        id: 17,
        option: "BY-D-3972",
      },
      {
        id: 18,
        option: "BY-D-3971",
      },
      {
        id: 19,
        option: "BY-D-3970",
      },
      {
        id: 20,
        option: "BY-D-3969",
      },
      {
        id: 21,
        option: "BY-D-3968",
      },
      {
        id: 22,
        option: "BY-D-3968",
      },
      {
        id: 23,
        option: "BY-D-3967",
      },
      {
        id: 24,
        option: "BY-D-3966",
      },
      {
        id: 25,
        option: "BY-D-3965",
      },
    ],
    tenant_option: [
      {
        id: 1,
        option: "Prajeesh",
      },
      {
        id: 2,
        option: "Priya Kant",
      },
      {
        id: 3,
        option: "Shinan Puri",
      },
      {
        id: 4,
        option: "Mark Jams",
      },
    ],
    owner_landlord: [
      {
        id: 1,
        option: "devOwner2",
      },
      {
        id: 2,
        option: "dev3Owner",
      },
      {
        id: 3,
        option: "Massod",
      },
      {
        id: 4,
        option: "Van",
      },
      {
        id: 5,
        option: "pr",
      },
      {
        id: 6,
        option: "Trisha",
      },
    ],
    source_option: [
      {
        id: 1,
        option: "Property Finder",
      },
      {
        id: 2,
        option: "Bayut",
      },
      {
        id: 3,
        option: "Call",
      },
      {
        id: 4,
        option: "Email",
      },
      {
        id: 5,
        option: "Others",
      },
    ],
    status_option: [
      {
        id: 1,
        option: "Open",
      },
      {
        id: 2,
        option: "Closed",
      },
      {
        id: 2,
        option: "Not Specified",
      },
    ],
    sub_status: [
      {
        id: 1,
        option: "Successfull",
      },
      {
        id: 2,
        option: "UnSuccessfull",
      },
      {
        id: 3,
        option: "Dishput",
      },
      {
        id: 4,
        option: "Not Specified",
      },
    ],
  },
];

// Ref No Option data...

export const refNoOptionsData = [
  {
    refOptions: [
      {
        id: 1,
        option: " BY-D-3989",
      },
      {
        id: 2,
        option: "BY-D-3988",
      },
      {
        id: 3,
        option: "BY-D-3987",
      },
      {
        id: 4,
        option: "BY-D-3986",
      },
      {
        id: 5,
        option: "BY-D-3985",
      },
      {
        id: 6,
        option: "BY-D-3984",
      },
      {
        id: 6,
        option: "BY-D-3983",
      },
      {
        id: 7,
        option: "BY-D-3982",
      },
      {
        id: 8,
        option: "BY-D-3981",
      },
      {
        id: 9,
        option: "BY-D-3980",
      },
      {
        id: 10,
        option: "BY-D-3979",
      },
      {
        id: 11,
        option: "BY-D-3978",
      },
      {
        id: 12,
        option: "BY-D-3977",
      },
      {
        id: 13,
        option: "BY-D-3976",
      },
      {
        id: 14,
        option: "BY-D-3975",
      },
      {
        id: 15,
        option: "BY-D-3974",
      },
      {
        id: 16,
        option: "BY-D-3973",
      },
      {
        id: 17,
        option: "BY-D-3972",
      },
      {
        id: 18,
        option: "BY-D-3971",
      },
      {
        id: 19,
        option: "BY-D-3970",
      },
      {
        id: 20,
        option: "BY-D-3969",
      },
      {
        id: 21,
        option: "BY-D-3968",
      },
      {
        id: 22,
        option: "BY-D-3968",
      },
      {
        id: 23,
        option: "BY-D-3967",
      },
      {
        id: 24,
        option: "BY-D-3966",
      },
      {
        id: 25,
        option: "BY-D-3965",
      },
    ],
  },
];
