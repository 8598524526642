import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { oldLeadDataTableData } from "../../../asset/StastucJsonData/OldLeadJsonData";
import CustomBreadcrumb from "../../Common/customBreadcrumb";
import { oldLeadOptionsData } from "../../../asset/StastucJsonData/OldLeadJsonData";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import {
  CommonInputField,
  FormikCanselButton,
  FormikSelectField,
  FormikSubmitButton,
} from "../../Common/CommonFromikFields";

const EditOldLead = () => {
  const [selectIdWiseData, setSelectIdWiseData] = useState("");

  const OldLeadOptionFilterData = oldLeadOptionsData[0] || [];

  let { id } = useParams();

  const initialValues = {
    ref: selectIdWiseData?.Ref || "",
    listing_ref: selectIdWiseData?.Listing_Ref || "",
    filter_by_lead_type: selectIdWiseData?.filter_by_lead_type || "",
    status: selectIdWiseData?.Status || "",
    sub_status: selectIdWiseData?.Sub_Status || "",
    first_name: selectIdWiseData?.First_name || "",
    last_name: selectIdWiseData?.Last_name || "",
    create_by: selectIdWiseData?.Created_By || "",
    source: selectIdWiseData?.filter_by_source || "",
    email: selectIdWiseData?.Email_Address || "",
    mobile: selectIdWiseData?.Mobile_No || "",
    phone: selectIdWiseData?.Phone_No || "",
    priority: selectIdWiseData?.filter_by_Priority || "",
    hot: selectIdWiseData?.Hot || "",
    contact_company: selectIdWiseData?.Contact_Company || "",
    min_bedrooms: selectIdWiseData?.filter_by_min_bed_rooms || "",
    max_bedrooms: selectIdWiseData?.filter_by_max_bed_rooms || "",
    min_price: selectIdWiseData?.Min_Price || "",
    max_price: selectIdWiseData?.Max_Price || "",
    min_area: selectIdWiseData?.Min_Area || "",
    max_area: selectIdWiseData?.Max_Area || "",
    unit_type: selectIdWiseData?.Unit_Type || "",
    unit_no: selectIdWiseData?.Unit_No || "",
    category: selectIdWiseData?.filter_by_category || "",
    emirates: selectIdWiseData?.filter_by_Emirates || "",
    location: selectIdWiseData?.filter_by_location || "",
    sub_location: selectIdWiseData?.filter_by_sublocation || "",
    city: selectIdWiseData?.City || "",
    country: selectIdWiseData?.Country || "",
    phone_lead: selectIdWiseData?.Is_Phone_Lead || "",
    Property_Requirements_2: selectIdWiseData?.Property_Requirements_2 || "",
    Property_Requirements_3: selectIdWiseData?.Property_Requirements_3 || "",
    Property_Requirements_4: selectIdWiseData?.Property_Requirements_4 || "",
    other_source_lead: selectIdWiseData?.other_source_lead || "",
    initial_contact: selectIdWiseData?.initial_contact || "",
    finance: selectIdWiseData?.Finance || "",
    enquiry_date: selectIdWiseData?.Enquiry_Date || "",
    date_added: selectIdWiseData?.Date_Added || "",
    date_updated: selectIdWiseData?.Date_Updated || "",
    notes: selectIdWiseData?.Notes || "",
  };

  const validationSchema = Yup.object()
    .shape({
      ref: Yup.string().required("Field is required."),
      listing_ref: Yup.string().required("Field is required."),
      filter_by_lead_type: Yup.string().required("Field is required."),
      status: Yup.string().required("Field is required."),
      sub_status: Yup.string().required("Field is required."),
      first_name: Yup.string().required("Field is required."),
      last_name: Yup.string().required("Field is required."),
      create_by: Yup.string().required("Field is required."),
      source: Yup.string().required("Field is required."),
      email: Yup.string().required("Field is required."),
      mobile: Yup.string().required("Field is required."),
      phone: Yup.string().required("Field is required."),
      priority: Yup.string().required("Field is required."),
      hot: Yup.string().required("Field is required."),
      contact_company: Yup.string().required("Field is required."),
      min_bedrooms: Yup.string().required("Field is required."),
      max_bedrooms: Yup.string().required("Field is required."),
      min_price: Yup.string().required("Field is required."),
      max_price: Yup.string().required("Field is required."),
      min_area: Yup.string().required("Field is required."),
      max_area: Yup.string().required("Field is required."),
      unit_type: Yup.string().required("Field is required."),
      unit_no: Yup.string().required("Field is required."),
      category: Yup.string().required("Field is required."),
      emirates: Yup.string().required("Field is required."),
      location: Yup.string().required("Field is required."),
      sub_location: Yup.string().required("Field is required."),
      city: Yup.string().required("Field is required."),
      country: Yup.string().required("Field is required."),
      phone_lead: Yup.string().required("Field is required."),
      Property_Requirements_2: Yup.string().required("Field is required."),
      Property_Requirements_3: Yup.string().required("Field is required."),
      Property_Requirements_4: Yup.string().required("Field is required."),
      other_source_lead: Yup.string().required("Field is required."),
      initial_contact: Yup.string().required("Field is required."),
      finance: Yup.string().required("Field is required."),
      enquiry_date: Yup.string().required("Field is required."),
      date_added: Yup.string().required("Field is required."),
      date_updated: Yup.string().required("Field is required."),
      notes: Yup.string().required("Field is required."),
    })
    .defined();

  const getIdWiseData = (id) => {
    const findData = oldLeadDataTableData.find(
      (user_id) => id === user_id?._id
    );
    setSelectIdWiseData(findData);
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    console.log("datadatadatadata", e);
  };

  useEffect(() => {
    if (id) {
      getIdWiseData(id);
    }
  }, [id]);

  return (
    <>
      <div className="container page-containners-main  edit-old-lead-detail">
        <CustomBreadcrumb
          title="Edit Old Lead"
          home="Dashboard"
          currentPage="Edit Old Lead"
          addExtraPageTitle="All Leads"
          addExtraPageTitlePath="all-leads"
        />
        <div className="px-3 py-5">
          <div className="mb-4 border-bottom-1">
            Fieds Marked with (<span className="text-danger"> * </span>) are
            Mandatory
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmitForm}
            enableReinitialize={true}
          >
            {(formik) => {
              const { setFieldValue } = formik;
              return (
                <>
                  <Form>
                    <div className="row mb-3">
                      <CommonInputField
                        lable={"Ref"}
                        placeholder="Enter Ref"
                        name="ref"
                      />
                      <CommonInputField
                        lable={"Listing Ref"}
                        placeholder="Enter Listing Ref"
                        name="listing_ref"
                      />
                      <FormikSelectField
                        label="Type"
                        name="filter_by_lead_type"
                        options={OldLeadOptionFilterData?.filter_by_lead_type}
                        placeholder=" - Select Type - "
                      />
                      <FormikSelectField
                        label="Status"
                        name="status"
                        options={OldLeadOptionFilterData?.filter_by_status}
                        placeholder=" - Select Status - "
                      />
                      <FormikSelectField
                        label="Substatus"
                        name="sub_status"
                        options={OldLeadOptionFilterData?.filter_by_sub_status}
                        placeholder=" - Select Substatus - "
                      />
                      <CommonInputField
                        lable={"First Name"}
                        placeholder="Enter First Name"
                        name="first_name"
                      />
                      <CommonInputField
                        lable={"Last Name"}
                        placeholder="Enter Last Name"
                        name="last_name"
                      />
                      <CommonInputField
                        lable={"Source"}
                        placeholder="Enter Source"
                        name="create_by"
                      />
                      <FormikSelectField
                        label="Source"
                        name="source"
                        options={OldLeadOptionFilterData?.filter_by_source}
                        placeholder=" - Select Source - "
                      />
                      <CommonInputField
                        lable={"Email"}
                        placeholder="Enter Email"
                        name="email"
                      />
                      <CommonInputField
                        lable={"Mobile No."}
                        placeholder="Enter Mobile No."
                        name="mobile"
                      />
                      <CommonInputField
                        lable={"Phone No."}
                        placeholder="Enter Phone No."
                        name="phone"
                      />
                      <FormikSelectField
                        label="Source"
                        name="source"
                        options={OldLeadOptionFilterData?.filter_by_source}
                        placeholder=" - Select Source - "
                      />
                      <FormikSelectField
                        label="Source"
                        name="source"
                        options={OldLeadOptionFilterData?.filter_by_source}
                        placeholder=" - Select Source - "
                      />
                      <FormikSelectField
                        label="Priority"
                        name="priority"
                        options={OldLeadOptionFilterData?.filter_by_Priority}
                        placeholder=" - Select Priority - "
                      />
                      <CommonInputField
                        lable={"Hot"}
                        placeholder="Enter Hot"
                        name="hot"
                      />
                      <CommonInputField
                        lable={"Contact Company"}
                        placeholder="Enter Contact Company"
                        name="contact_company"
                      />
                      <FormikSelectField
                        label="Min Bedrooms"
                        name="min_bedrooms"
                        options={
                          OldLeadOptionFilterData?.filter_by_min_bed_rooms
                        }
                        placeholder=" - Select Min Bedrooms - "
                      />
                      <FormikSelectField
                        label="Max Bedrooms"
                        name="max_bedrooms"
                        options={
                          OldLeadOptionFilterData?.filter_by_min_bed_rooms
                        }
                        placeholder=" - Select Max Bedrooms - "
                      />

                      <CommonInputField
                        lable={"Min Price"}
                        placeholder="Enter Min Price"
                        name="min_price"
                      />
                      <CommonInputField
                        lable={"Max Price"}
                        placeholder="Enter Max Price"
                        name="max_price"
                      />
                      <CommonInputField
                        lable={"Max Price"}
                        placeholder="Enter Max Price"
                        name="max_price"
                      />
                      <CommonInputField
                        lable={"Min Area"}
                        placeholder="Enter Min Area"
                        name="min_area"
                      />
                      <CommonInputField
                        lable={"Max Area"}
                        placeholder="Enter Max Area"
                        name="max_area"
                      />
                      <CommonInputField
                        lable={"Unit Type"}
                        placeholder="Enter Unit Type"
                        name="unit_type"
                      />
                      <CommonInputField
                        lable={"Unit No"}
                        placeholder="Enter Unit No"
                        name="unit_no"
                      />
                      <FormikSelectField
                        label="Category"
                        name="category"
                        options={OldLeadOptionFilterData?.filter_by_category}
                        placeholder=" - Select Category - "
                      />
                      <FormikSelectField
                        label="Emirates"
                        name="emirates"
                        options={OldLeadOptionFilterData?.filter_by_Emirates}
                        placeholder=" - Select Emirates - "
                      />
                      <FormikSelectField
                        label="Location"
                        name="location"
                        options={OldLeadOptionFilterData?.filter_by_location}
                        placeholder=" - Select Location - "
                      />
                      <FormikSelectField
                        label="SubLocation"
                        name="sub_location"
                        options={OldLeadOptionFilterData?.filter_by_sublocation}
                        placeholder=" - Select SubLocation - "
                      />
                      <CommonInputField
                        lable={"City"}
                        placeholder="Enter City"
                        name="city"
                      />
                      <CommonInputField
                        lable={"Country"}
                        placeholder="Enter Country"
                        name="country"
                      />
                      <CommonInputField
                        lable={"Is Phone Lead"}
                        placeholder="Enter Is Phone Lead"
                        name="phone_leas"
                      />
                      <CommonInputField
                        lable={"Property Requirements 2"}
                        placeholder="Enter Property Requirements 2"
                        name="Property_Requirements_2"
                      />
                      <CommonInputField
                        lable={"Property Requirements 3"}
                        placeholder="Enter Property Requirements 3"
                        name="Property_Requirements_3"
                      />
                      <CommonInputField
                        lable={"Property Requirements 4"}
                        placeholder="Enter Property Requirements 4"
                        name="Property_Requirements_4"
                      />
                      <CommonInputField
                        lable={"Other Source of Lead"}
                        placeholder="Enter Spurce Lead"
                        name="other_source_lead"
                      />
                      <CommonInputField
                        lable={"Initial Contact"}
                        placeholder="Enter Initial Contact"
                        name="initial_contact"
                      />
                      <CommonInputField
                        lable={"Finance"}
                        placeholder="Enter Finance"
                        name="finance"
                      />
                      <CommonInputField
                        lable={"Enquiry Date"}
                        placeholder="Enter Enquiry Date"
                        name="enquiry_date"
                      />
                      <CommonInputField
                        lable={"Date Added"}
                        placeholder="Enter Date Added"
                        name="date_added"
                      />
                      <CommonInputField
                        lable={"Date Updated"}
                        placeholder="Enter Date Updated"
                        name="date_updated"
                      />
                      <div className="col-12 mb-2">
                        <p className="m-0">
                          Notes
                          <span className="text-danger">*</span>
                        </p>
                        <Field
                          className="form-control rounded-0"
                          type="text"
                          placeholder="Enter Mobile No."
                          name="notes"
                          as="textarea"
                        />
                        <ErrorMessage
                          name="notes"
                          component={"div"}
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="text-center mt-5 mb-3">
                      <FormikSubmitButton />
                      <FormikCanselButton path="/old-leads" />
                      {/* <button type="submit" className="btn btn-secondary mx-2">
                        Submit
                      </button>
                      <Link to="/old-leads">
                        <button className="mx-2 btn btn-light">Cancel</button>
                      </Link> */}
                    </div>
                  </Form>
                </>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default EditOldLead;
