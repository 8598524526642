import React from "react";
import CustomBreadcrumb from "../../Common/customBreadcrumb";
import AllDealsList from "./AllDealsList";

const AllDeals = () => {
  return (
    <>
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Manage All Deals"
          home="Dashboard"
          currentPage="All Deals"
          addExtraPageTitle="Contacts"
          addExtraPageTitlePath="contacts"
        />
        <AllDealsList />
      </div>
    </>
  );
};

export default AllDeals;
