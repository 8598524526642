import React from "react";
import CustomBreadcrumb from "../../../Common/customBreadcrumb";
import AddAllListing from "./AddAllListing";

const AddAllListingPage = () => {
  return (
    <>
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Add All Listing"
          home="Dashboard"
          currentPage="Add All Listing"
          addExtraPageTitle="All Listing"
          addExtraPageTitlePath="all-listing"
        />
        <AddAllListing />
      </div>
    </>
  );
};

export default AddAllListingPage;
