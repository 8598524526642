import React, { useState, useEffect } from "react";
import "../../asset/css/common/model.css";

const Modal = ({ show, handleClose, children }) => {
  const [isVisible, setIsVisible] = useState(show);

  useEffect(() => {
    if (show) {
      setIsVisible(true);
    }
  }, [show]);

  const handleAnimationEnd = () => {
    if (!show) {
      setIsVisible(false);
    }
  };

  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return isVisible ? (
    <div className={showHideClassName} onAnimationEnd={handleAnimationEnd}>
      <section className="modal-main">
        <button className="modal-close-button" onClick={handleClose}>
          &times;
        </button>
        {children}
      </section>
    </div>
  ) : null;
};

export default Modal;
