import React from "react";
import CustomBreadcrumb from "../../Common/customBreadcrumb";
import ContactsList from "./ContactsList";

const Contacts = () => {
  return (
    <>
      <div className="container my-3">
        <CustomBreadcrumb
          title="Manage Contacts"
          home="Dashboard"
          currentPage="Manage Contacts"
          addExtraPageTitle="Contacts"
          addExtraPageTitlePath="contacts"
        />
        <ContactsList />
      </div>
    </>
  );
};

export default Contacts;
