import React from "react";
import "../../../asset/css/common/model.css";
import SubLocationList from "./SubLocationList";
import CustomBreadcrumb from "../../Common/customBreadcrumb";

const SubLocation = () => {
  return (
    <div className="container page-containners-main">
      <CustomBreadcrumb
        title="Manage Sub Location"
        home="Dashboard"
        currentPage="Sub Location"
        addExtraPageTitle="All Leads"
        addExtraPageTitlePath="all-leads"
      />
      <SubLocationList />
    </div>
  );
};

export default SubLocation;
