// CONTACT STATIC DATA...

export const ContactsData = [
  {
    _id: "66bf25c912efdfe1e29fe98f",
    name: "Majid Muhammad Latif",
    refid: "BY-L-15497",
    email: "majidmlatif@gmail.com",
    mobile: "+966552541137",
    dob: " - ",
    contactid: "BY-O-19910",
    created_at: "2024-08-16T10:11:21.348Z",
    __v: 0,
  },
  {
    _id: "66bf24cc12efdfe1e29fc552",
    name: "French Builders",
    refid: "BY-L-15496",
    email: "",
    mobile: "971508409323",
    dob: " - ",
    contactid: "BY-O-19909",
    created_at: "2024-08-16T10:07:08.769Z",
    __v: 0,
  },
  {
    _id: "66bf243412efdfe1e29f72f9",
    name: "Rexcyl Cataloctocan",
    refid: "BY-L-15495",
    email: "rcataloctocan@gmail.com",
    mobile: "+971582459645",
    dob: " - ",
    contactid: "BY-O-19908",
    created_at: "2024-08-16T10:04:36.017Z",
    __v: 0,
  },
  {
    _id: "66bf1ec712efdfe1e29dc4ae",
    name: "JM",
    refid: "BY-L-15494",
    email: "",
    mobile: "971557508668",
    dob: " - ",
    contactid: "BY-O-19907",
    created_at: "2024-08-16T09:41:27.406Z",
    __v: 0,
  },
  {
    _id: "66bf1a9912efdfe1e29c75aa",
    name: "TACHE SALON LLC",
    refid: "BY-T-00963",
    email: "m.a.bataineh@gmail.com",
    mobile: "+971566867134",
    contactid: "BY-O-19906",
    created_at: "2024-08-16T09:23:37.710Z",
    __v: 0,
  },
  {
    _id: "66bf197e12efdfe1e29c48bb",
    name: "SETHU RAJA ",
    refid: "BY-T-00962",
    email: "sethu31@gmail.com",
    mobile: "+971509574318",
    contactid: "BY-O-19905",
    created_at: "2024-08-16T09:18:54.970Z",
    __v: 0,
  },
  {
    _id: "66bf172212efdfe1e29b8d91",
    name: "Bizmate",
    refid: "BY-L-15493",
    email: "bahruzakhundov699@gmail.com",
    mobile: "+971581783815",
    dob: " - ",
    contactid: "BY-O-19904",
    created_at: "2024-08-16T09:08:50.010Z",
    __v: 0,
  },
  {
    _id: "66bf164b12efdfe1e29b273d",
    name: "pf_user",
    refid: "BY-L-15492",
    email: "",
    mobile: "+917510608234",
    dob: " - ",
    contactid: "BY-O-19903",
    created_at: "2024-08-16T09:05:15.407Z",
    __v: 0,
  },
  {
    _id: "66bf15e112efdfe1e29b1e28",
    name: "pf_user",
    refid: "BY-L-15491",
    email: "",
    mobile: "+971504166007",
    dob: " - ",
    contactid: "BY-O-19902",
    created_at: "2024-08-16T09:03:29.098Z",
    __v: 0,
  },
  {
    _id: "66bf0b9512efdfe1e297f7e3",
    name: "KARIM TEMIMI",
    refid: "BY-T-00961",
    email: "temimikarim25@gmail.com",
    mobile: "+971501018699",
    contactid: "BY-O-19901",
    created_at: "2024-08-16T08:19:33.688Z",
    __v: 0,
  },
];


// OLD CONTACTS STATIC DATAS...

export const oldContactsData = [
  {
    _id: "660fda5b3644bf04fd96b9a4",
    Ref: "BY-O-9999",
    Gender: "- -",
    First_Name: "Mr",
    Last_Name: "Jerry",
    Company: "- -",
    Home_Address1: "- -",
    Home_Address2: "- -",
    Home_City: "- -",
    Home_State: "- -",
    Home_Country: "- -",
    Home_ZipCode: "- -",
    Personal_Phone: "- -",
    Work_Phone: "- -",
    Home_Fax: "- -",
    Home_PO_Box: "- -",
    Personal_Mobile: "+971 505565532",
    Personal_Email: "- -",
    Work_Email: "- -",
    Date_Of_Birth: "- -",
    Designation: "- -",
    Nationality: "- -",
    Religion: "- -",
    Title: "- -",
    Work_Mobile: "- -",
    Assigned_To: "Sheena Mae",
    Updated: "09-06-2013",
    Other_Phone: "- -",
    Other_Mobile: "- -",
    Work_Fax: "- -",
    Other_Fax: "- -",
    Other_Email: "- -",
    Company_PO_Box: "- -",
    Company_Address1: "- -",
    Company_Address2: "- -",
    Company_city: "- -",
    Company_State: "- -",
    Company_Country: "- -",
    Company_ZipCode: "- -",
    Native_Language: "- -",
    Second_Language: "- -",
    Contact_Source: "- -",
    Contact_Type: "- -",
    Created_Date: "09-06-2013",
    Created_By: "Sheena Mae",
    Subscribed_Marketing_Email: "Yes",
  },
  {
    _id: "660fda5b3644bf04fd96b9a5",
    Ref: "BY-O-9998",
    Gender: "- -",
    First_Name: "Mr",
    Last_Name: "Aman",
    Company: "- -",
    Home_Address1: "- -",
    Home_Address2: "- -",
    Home_City: "- -",
    Home_State: "- -",
    Home_Country: "- -",
    Home_ZipCode: "- -",
    Personal_Phone: "- -",
    Work_Phone: "- -",
    Home_Fax: "- -",
    Home_PO_Box: "- -",
    Personal_Mobile: "+971 506553243",
    Personal_Email: "- -",
    Work_Email: "- -",
    Date_Of_Birth: "- -",
    Designation: "- -",
    Nationality: "- -",
    Religion: "- -",
    Title: "- -",
    Work_Mobile: "- -",
    Assigned_To: "Sheena Mae",
    Updated: "09-06-2013",
    Other_Phone: "- -",
    Other_Mobile: "- -",
    Work_Fax: "- -",
    Other_Fax: "- -",
    Other_Email: "- -",
    Company_PO_Box: "- -",
    Company_Address1: "- -",
    Company_Address2: "- -",
    Company_city: "- -",
    Company_State: "- -",
    Company_Country: "- -",
    Company_ZipCode: "- -",
    Native_Language: "- -",
    Second_Language: "- -",
    Contact_Source: "- -",
    Contact_Type: "- -",
    Created_Date: "09-06-2013",
    Created_By: "Sheena Mae",
    Subscribed_Marketing_Email: "Yes",
  },
  {
    _id: "660fda5b3644bf04fd96b9a6",
    Ref: "BY-O-9997",
    Gender: "- -",
    First_Name: "Mr",
    Last_Name: "Mark",
    Company: "- -",
    Home_Address1: "- -",
    Home_Address2: "- -",
    Home_City: "- -",
    Home_State: "- -",
    Home_Country: "- -",
    Home_ZipCode: "- -",
    Personal_Phone: "- -",
    Work_Phone: "- -",
    Home_Fax: "- -",
    Home_PO_Box: "- -",
    Personal_Mobile: "+971 502632514",
    Personal_Email: "- -",
    Work_Email: "- -",
    Date_Of_Birth: "- -",
    Designation: "- -",
    Nationality: "- -",
    Religion: "- -",
    Title: "- -",
    Work_Mobile: "- -",
    Assigned_To: "Sheena Mae",
    Updated: "09-06-2013",
    Other_Phone: "- -",
    Other_Mobile: "- -",
    Work_Fax: "- -",
    Other_Fax: "- -",
    Other_Email: "- -",
    Company_PO_Box: "- -",
    Company_Address1: "- -",
    Company_Address2: "- -",
    Company_city: "- -",
    Company_State: "- -",
    Company_Country: "- -",
    Company_ZipCode: "- -",
    Native_Language: "- -",
    Second_Language: "- -",
    Contact_Source: "- -",
    Contact_Type: "- -",
    Created_Date: "09-06-2013",
    Created_By: "Sheena Mae",
    Subscribed_Marketing_Email: "Yes",
  },
  {
    _id: "660fda5b3644bf04fd96b9a7",
    Ref: "BY-O-9996",
    Gender: "- -",
    First_Name: "Mr",
    Last_Name: "Dharmesh",
    Company: "- -",
    Home_Address1: "- -",
    Home_Address2: "- -",
    Home_City: "- -",
    Home_State: "- -",
    Home_Country: "- -",
    Home_ZipCode: "- -",
    Personal_Phone: "- -",
    Work_Phone: "- -",
    Home_Fax: "- -",
    Home_PO_Box: "- -",
    Personal_Mobile: "+971 504270778",
    Personal_Email: "- -",
    Work_Email: "- -",
    Date_Of_Birth: "- -",
    Designation: "- -",
    Nationality: "- -",
    Religion: "- -",
    Title: "- -",
    Work_Mobile: "- -",
    Assigned_To: "Sheena Mae",
    Updated: "09-06-2013",
    Other_Phone: "- -",
    Other_Mobile: "- -",
    Work_Fax: "- -",
    Other_Fax: "- -",
    Other_Email: "- -",
    Company_PO_Box: "- -",
    Company_Address1: "- -",
    Company_Address2: "- -",
    Company_city: "- -",
    Company_State: "- -",
    Company_Country: "- -",
    Company_ZipCode: "- -",
    Native_Language: "- -",
    Second_Language: "- -",
    Contact_Source: "- -",
    Contact_Type: "- -",
    Created_Date: "09-06-2013",
    Created_By: "Sheena Mae",
    Subscribed_Marketing_Email: "Yes",
  },
  {
    _id: "660fda5b3644bf04fd96b9a8",
    Ref: "BY-O-9995",
    Gender: "- -",
    First_Name: "Ms.Linda ",
    Last_Name: "Pickup",
    Company: "- -",
    Home_Address1: "- -",
    Home_Address2: "- -",
    Home_City: "- -",
    Home_State: "- -",
    Home_Country: "- -",
    Home_ZipCode: "- -",
    Personal_Phone: "- -",
    Work_Phone: "- -",
    Home_Fax: "- -",
    Home_PO_Box: "- -",
    Personal_Mobile: "+971 507888723",
    Personal_Email: "- -",
    Work_Email: "- -",
    Date_Of_Birth: "- -",
    Designation: "- -",
    Nationality: "- -",
    Religion: "- -",
    Title: "- -",
    Work_Mobile: "- -",
    Assigned_To: "Sheena Mae",
    Updated: "09-06-2013",
    Other_Phone: "- -",
    Other_Mobile: "- -",
    Work_Fax: "- -",
    Other_Fax: "- -",
    Other_Email: "- -",
    Company_PO_Box: "- -",
    Company_Address1: "- -",
    Company_Address2: "- -",
    Company_city: "- -",
    Company_State: "- -",
    Company_Country: "- -",
    Company_ZipCode: "- -",
    Native_Language: "- -",
    Second_Language: "- -",
    Contact_Source: "- -",
    Contact_Type: "- -",
    Created_Date: "09-06-2013",
    Created_By: "Sheena Mae",
    Subscribed_Marketing_Email: "Yes",
  },
  {
    _id: "660fda5b3644bf04fd96b9a9",
    Ref: "BY-O-9994",
    Gender: "- -",
    First_Name: "Ms",
    Last_Name: "Sarah",
    Company: "- -",
    Home_Address1: "- -",
    Home_Address2: "- -",
    Home_City: "- -",
    Home_State: "- -",
    Home_Country: "- -",
    Home_ZipCode: "- -",
    Personal_Phone: "- -",
    Work_Phone: "- -",
    Home_Fax: "- -",
    Home_PO_Box: "- -",
    Personal_Mobile: "+971 554554031",
    Personal_Email: "- -",
    Work_Email: "- -",
    Date_Of_Birth: "- -",
    Designation: "- -",
    Nationality: "- -",
    Religion: "- -",
    Title: "- -",
    Work_Mobile: "- -",
    Assigned_To: "Sheena Mae",
    Updated: "09-06-2013",
    Other_Phone: "- -",
    Other_Mobile: "- -",
    Work_Fax: "- -",
    Other_Fax: "- -",
    Other_Email: "- -",
    Company_PO_Box: "- -",
    Company_Address1: "- -",
    Company_Address2: "- -",
    Company_city: "- -",
    Company_State: "- -",
    Company_Country: "- -",
    Company_ZipCode: "- -",
    Native_Language: "- -",
    Second_Language: "- -",
    Contact_Source: "- -",
    Contact_Type: "- -",
    Created_Date: "09-06-2013",
    Created_By: "Sheena Mae",
    Subscribed_Marketing_Email: "Yes",
  },
  {
    _id: "660fda5b3644bf04fd96b9aa",
    Ref: "BY-O-9993",
    Gender: "- -",
    First_Name: "Mr",
    Last_Name: "Mothe",
    Company: "- -",
    Home_Address1: "- -",
    Home_Address2: "- -",
    Home_City: "- -",
    Home_State: "- -",
    Home_Country: "- -",
    Home_ZipCode: "- -",
    Personal_Phone: "- -",
    Work_Phone: "- -",
    Home_Fax: "- -",
    Home_PO_Box: "- -",
    Personal_Mobile: "+971 501461504",
    Personal_Email: "- -",
    Work_Email: "- -",
    Date_Of_Birth: "- -",
    Designation: "- -",
    Nationality: "- -",
    Religion: "- -",
    Title: "- -",
    Work_Mobile: "- -",
    Assigned_To: "Sheena Mae",
    Updated: "09-06-2013",
    Other_Phone: "- -",
    Other_Mobile: "- -",
    Work_Fax: "- -",
    Other_Fax: "- -",
    Other_Email: "- -",
    Company_PO_Box: "- -",
    Company_Address1: "- -",
    Company_Address2: "- -",
    Company_city: "- -",
    Company_State: "- -",
    Company_Country: "- -",
    Company_ZipCode: "- -",
    Native_Language: "- -",
    Second_Language: "- -",
    Contact_Source: "- -",
    Contact_Type: "- -",
    Created_Date: "09-06-2013",
    Created_By: "Sheena Mae",
    Subscribed_Marketing_Email: "Yes",
  },
  {
    _id: "660fd9f13644bf04fd963d6a",
    Ref: "BY-O-9992",
    Gender: "- -",
    First_Name: "Mr",
    Last_Name: "Imran",
    Company: "- -",
    Home_Address1: "- -",
    Home_Address2: "- -",
    Home_City: "- -",
    Home_State: "- -",
    Home_Country: "- -",
    Home_ZipCode: "- -",
    Personal_Phone: "- -",
    Work_Phone: "- -",
    Home_Fax: "- -",
    Home_PO_Box: "- -",
    Personal_Mobile: "+971 508511901",
    Personal_Email: "- -",
    Work_Email: "- -",
    Date_Of_Birth: "- -",
    Designation: "- -",
    Nationality: "- -",
    Religion: "- -",
    Title: "- -",
    Work_Mobile: "- -",
    Assigned_To: "Sheena Mae",
    Updated: "22-10-2018",
    Other_Phone: "- -",
    Other_Mobile: "- -",
    Work_Fax: "- -",
    Other_Fax: "- -",
    Other_Email: "- -",
    Company_PO_Box: "- -",
    Company_Address1: "- -",
    Company_Address2: "- -",
    Company_city: "- -",
    Company_State: "- -",
    Company_Country: "- -",
    Company_ZipCode: "- -",
    Native_Language: "- -",
    Second_Language: "- -",
    Contact_Source: "- -",
    Contact_Type: "- -",
    Created_Date: "09-06-2013",
    Created_By: "Sheena Mae",
    Subscribed_Marketing_Email: "Yes",
  },
  {
    _id: "660fda5b3644bf04fd96b9ab",
    Ref: "BY-O-9991",
    Gender: "- -",
    First_Name: "Mr",
    Last_Name: "Zubin",
    Company: "- -",
    Home_Address1: "- -",
    Home_Address2: "- -",
    Home_City: "- -",
    Home_State: "- -",
    Home_Country: "- -",
    Home_ZipCode: "- -",
    Personal_Phone: "42555614",
    Work_Phone: "- -",
    Home_Fax: "- -",
    Home_PO_Box: "- -",
    Personal_Mobile: "- -",
    Personal_Email: "- -",
    Work_Email: "- -",
    Date_Of_Birth: "- -",
    Designation: "- -",
    Nationality: "- -",
    Religion: "- -",
    Title: "- -",
    Work_Mobile: "- -",
    Assigned_To: "Sheena Mae",
    Updated: "09-06-2013",
    Other_Phone: "- -",
    Other_Mobile: "- -",
    Work_Fax: "- -",
    Other_Fax: "- -",
    Other_Email: "- -",
    Company_PO_Box: "- -",
    Company_Address1: "- -",
    Company_Address2: "- -",
    Company_city: "- -",
    Company_State: "- -",
    Company_Country: "- -",
    Company_ZipCode: "- -",
    Native_Language: "- -",
    Second_Language: "- -",
    Contact_Source: "- -",
    Contact_Type: "- -",
    Created_Date: "09-06-2013",
    Created_By: "Sheena Mae",
    Subscribed_Marketing_Email: "Yes",
  },
  {
    _id: "660fda5b3644bf04fd96b9ac",
    Ref: "BY-O-9990",
    Gender: "- -",
    First_Name: "Mr",
    Last_Name: "Irfan",
    Company: "- -",
    Home_Address1: "- -",
    Home_Address2: "- -",
    Home_City: "- -",
    Home_State: "- -",
    Home_Country: "- -",
    Home_ZipCode: "- -",
    Personal_Phone: "- -",
    Work_Phone: "- -",
    Home_Fax: "- -",
    Home_PO_Box: "- -",
    Personal_Mobile: "+971 505013432",
    Personal_Email: "- -",
    Work_Email: "- -",
    Date_Of_Birth: "- -",
    Designation: "- -",
    Nationality: "- -",
    Religion: "- -",
    Title: "- -",
    Work_Mobile: "- -",
    Assigned_To: "Sheena Mae",
    Updated: "09-06-2013",
    Other_Phone: "- -",
    Other_Mobile: "- -",
    Work_Fax: "- -",
    Other_Fax: "- -",
    Other_Email: "- -",
    Company_PO_Box: "- -",
    Company_Address1: "- -",
    Company_Address2: "- -",
    Company_city: "- -",
    Company_State: "- -",
    Company_Country: "- -",
    Company_ZipCode: "- -",
    Native_Language: "- -",
    Second_Language: "- -",
    Contact_Source: "- -",
    Contact_Type: "- -",
    Created_Date: "09-06-2013",
    Created_By: "Sheena Mae",
    Subscribed_Marketing_Email: "Yes",
  },
];
