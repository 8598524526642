import React from "react";
import ListAllListing from "./ListAllListing";

const Alllisting = () => {
  return (
    <>
      <ListAllListing />
    </>
  );
};

export default Alllisting;
