import React from "react";
import CustomBreadcrumb from "../../Common/customBreadcrumb";
import ListOldDeals from "./ListOldDeals";

const OldDeals = () => {
  return (
    <>
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Manage Old Deals"
          home="Dashboard"
          currentPage="Old Deals"
          addExtraPageTitle="Contacts"
          addExtraPageTitlePath="contacts"
        />
        <ListOldDeals />
      </div>
    </>
  );
};

export default OldDeals;
