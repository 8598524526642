import React from "react";
import { customStyles } from "../../Common/dataTableStyle";
import DataTable from "react-data-table-component";
import { noteHistoryDate } from "../../../asset/StastucJsonData/allLeadJsonData";
import Modal from "../../Common/ModelCommon";

const NoteHistoryDetails = ({ historyDetailModel, handleCloseHistory }) => {
  const column = [
    {
      name: "User",
      selector: (row) => row.user,
      center: true,
      wrap: true,
      sortable: true,
    },
    {
      name: "Screen",
      selector: (row) => row.screen,
      center: true,
      wrap: true,
      sortable: true,
    },
    {
      name: "Field",
      selector: (row) => row.field,
      center: true,
      wrap: true,
      sortable: true,
    },
    {
      name: "Value",
      selector: (row) => row.value,
      center: true,
      wrap: true,
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      center: true,
      wrap: true,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row.date,
      center: true,
      wrap: true,
      sortable: true,
    },
  ];
  return (
    <>
      <Modal show={historyDetailModel} handleClose={handleCloseHistory}>
        <div>
          <h5 className="mb-2">History</h5>
        </div>

        <div>
          <DataTable
            columns={column}
            data={noteHistoryDate}
            noDataComponent="No data available"
            noHeader={false}
            persistTableHead
            customStyles={customStyles}
            pagination
          />
        </div>
      </Modal>
    </>
  );
};
export default NoteHistoryDetails;
