import React from "react";
import CustomBreadcrumb from "../../Common/customBreadcrumb";
import PortalList from "./portalList";

const PortalPage = () => {
  return (
    <>
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Manage Portal"
          home="Dashboard"
          currentPage="Manage Portal"
          addExtraPageTitle=""
          addExtraPageTitlePath=""
        />
        <PortalList />
      </div>
    </>
  );
};

export default PortalPage;
