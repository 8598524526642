// WEBSITELEADS STATIC DATA...

export const WebsiteLeads = [
  {
    _id: "660fa7213644bf04fd93e6bb",
    name: "Samuel Hatem",
    email: "samuelhatem326@gmail.com",
    mobile: "12136360994",
    chanel: "Website",
    message:
      "Give me a call back, I’ll be needing your guidance in purchasing a property in Dubai",
    project_name: "contact us",
    Date_Added: "29-03-2024 12:10",
    property_id: "S00KQ0BMRC",
  },
  {
    _id: "660fa7213644bf04fd93e6bb1",
    name: "Samuel Hatem",
    email: "samuelhatem326@gmail.com",
    mobile: "12136360994",
    chanel: "Website",
    message:
      "Give me a call back, I’ll be needing your guidance in purchasing a property in Dubai",
    project_name: "contact us",
    Date_Added: "29-03-2024 12:10",
    property_id: "S00KQ0BMRC",
  },
  {
    _id: "660fa7213644bf04fd93e6bb2",
    name: "Samuel Hatem",
    email: "samuelhatem326@gmail.com",
    mobile: "12136360994",
    chanel: "Website",
    message:
      "Give me a call back, I’ll be needing your guidance in purchasing a property in Dubai",
    project_name: "contact us",
    Date_Added: "29-03-2024 12:10",
    property_id: "S00KQ0BMRC",
  },
  {
    _id: "660fa7213644bf04fd93e6bb3",
    name: "Samuel Hatem",
    email: "samuelhatem326@gmail.com",
    mobile: "12136360994",
    chanel: "Website",
    message:
      "Give me a call back, I’ll be needing your guidance in purchasing a property in Dubai",
    project_name: "contact us",
    Date_Added: "29-03-2024 12:10",
    property_id: "S00KQ0BMRC",
  },
  {
    _id: "660fa7213644bf04fd93e6bb4",
    name: "Samuel Hatem",
    email: "samuelhatem326@gmail.com",
    mobile: "12136360994",
    chanel: "Website",
    message:
      "Give me a call back, I’ll be needing your guidance in purchasing a property in Dubai",
    project_name: "contact us",
    Date_Added: "29-03-2024 12:10",
    property_id: "S00KQ0BMRC",
  },
  {
    _id: "660fa7213644bf04fd93e6bb45",
    name: "Samuel Hatem",
    email: "samuelhatem326@gmail.com",
    mobile: "12136360994",
    chanel: "Website",
    message:
      "Give me a call back, I’ll be needing your guidance in purchasing a property in Dubai",
    project_name: "contact us",
    Date_Added: "29-03-2024 12:10",
    property_id: "S00KQ0BMRC",
  },
  {
    _id: "660fa7213644bf04fd93e6bb46",
    name: "Samuel Hatem",
    email: "samuelhatem326@gmail.com",
    mobile: "12136360994",
    chanel: "Website",
    message:
      "Give me a call back, I’ll be needing your guidance in purchasing a property in Dubai",
    project_name: "contact us",
    Date_Added: "29-03-2024 12:10",
    property_id: "S00KQ0BMRC",
  },
  {
    _id: "660fa7213644bf04fd93e6bb47",
    name: "Samuel Hatem",
    email: "samuelhatem326@gmail.com",
    mobile: "12136360994",
    chanel: "Website",
    message:
      "Give me a call back, I’ll be needing your guidance in purchasing a property in Dubai",
    project_name: "contact us",
    Date_Added: "29-03-2024 12:10",
    property_id: "S00KQ0BMRC",
  },
  {
    _id: "660fa7213644bf04fd93e6bb48",
    name: "Samuel Hatem",
    email: "samuelhatem326@gmail.com",
    mobile: "12136360994",
    chanel: "Website",
    message:
      "Give me a call back, I’ll be needing your guidance in purchasing a property in Dubai",
    project_name: "contact us",
    Date_Added: "29-03-2024 12:10",
    property_id: "S00KQ0BMRC",
  },
  {
    _id: "660fa7213644bf04fd93e6bb49",
    name: "Samuel Hatem",
    email: "samuelhatem326@gmail.com",
    mobile: "12136360994",
    chanel: "Website",
    message:
      "Give me a call back, I’ll be needing your guidance in purchasing a property in Dubai",
    project_name: "contact us",
    Date_Added: "29-03-2024 12:10",
    property_id: "S00KQ0BMRC",
  },
];
