import React from "react";
import "../../../../asset/css/common/model.css";
import CustomBreadcrumb from "../../../Common/customBreadcrumb";
import EditUnitModel from "./EditUnitModel";

const EditUnitModelPage = () => {
  return (
    <div className="container page-containners-main">
      <CustomBreadcrumb
        title="Edit Unit Model"
        home="Dashbord"
        currentPage="Edit Unit Model"
        addExtraPageTitle="Project"
        addExtraPageTitlePath="create-project"
      />
      <EditUnitModel />
    </div>
  );
};

export default EditUnitModelPage;
