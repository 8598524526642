// PROPERTY FINDER LEADS STATIC DATA...

export const PropertyFinderLeadsData = [
  {
    user_id: "1",
    fullname: "mr shanawaz",
    email: "SYED_SHANAWAZ@YMAIL.COM",
    mobile: "+971544418141",
    status: "notYetContacted",
    source: "propertyfinder",
    date: "12-Oct-2023",
  },
  {
    user_id: "2",
    fullname: "Francesco Fiorilli",
    email: "francescofiorilli@hotmail.com",
    mobile: "+971528731618",
    status: "notYetContacted",
    source: "propertyfinder",
    date: "18-Apr-2020",
  },
  {
    user_id: "3",
    fullname: "FAISAL",
    email: "f.a.j1@hotmail.com",
    mobile: "+971561794794",
    status: "notYetContacted",
    source: "propertyfinder",
    date: "22-Mar-2020",
  },
  {
    user_id: "4",
    fullname: "Elvi",
    email: "elvigevers@gmail.com",
    mobile: "+971555146752",
    status: "notYetContacted",
    source: "propertyfinder",
    date: "15-Mar-2020",
  },
  {
    user_id: "5",
    fullname: "Hamad Mubarak",
    email: "H.m.binmubarak@hotmail.com",
    mobile: "+971543566766",
    status: "notYetContacted",
    source: "propertyfinder",
    date: "15-Mar-2020",
  },
  {
    user_id: "6",
    fullname: "Sherif Rashad",
    email: "srashad1@gmail.com",
    mobile: "0506505128",
    status: "notYetContacted",
    source: "propertyfinder",
    date: "14-Mar-2020",
  },
  {
    user_id: "7",
    fullname: "majdouline",
    email: "majdoulinemao@gmail.com",
    mobile: "0553951118",
    status: "notYetContacted",
    source: "propertyfinder",
    date: "11-Mar-2020",
  },
  {
    user_id: "8",
    fullname: "Ayman",
    email: "Ayman.badry.ab@gmail.com",
    mobile: "+971504096099",
    status: "notYetContacted",
    source: "propertyfinder",
    date: "11-Mar-2020",
  },
  {
    user_id: "9",
    fullname: "David",
    email: "djones.anywhere@gmail.com",
    mobile: "+971503092873",
    status: "notYetContacted",
    source: "propertyfinder",
    date: "11-Mar-2020",
  },
  {
    user_id: "10",
    fullname: "Chuks",
    email: "hugoranks@yahoo.com",
    mobile: "+971544846846",
    status: "notYetContacted",
    source: "propertyfinder",
    date: "11-Mar-2020",
  },
  {
    user_id: "11",
    fullname: "Eddie",
    email: "parra.eddie@gmail.com",
    mobile: "+9710557687072",
    status: "notYetContacted",
    source: "propertyfinder",
    date: "10-Mar-2020",
  },
  {
    user_id: "12",
    fullname: "maan abouhosn",
    email: "maanhosn@hotmail.com",
    mobile: "+9710529338855",
    status: "notYetContacted",
    source: "propertyfinder",
    date: "09-Mar-2020",
  },
];

// PORTAL STATIC DATA...

export const portalListData = [
  {
    _id: "1",
    portal_name: "Bayut & Dubizzle",
    link: "/agent/MTA0NS1kYXFyOGI2/bayut&dubizzle",
  },
  {
    _id: "2",
    portal_name: "Propertyfinder",
    link: "/agent/MTA0NS1kYXFyOGI2/propertyfinder",
  },
  {
    _id: "3",
    portal_name: "Binayah Website",
    link: "/agent/MTA0NS1kYXFyOGI2/binayahwebsite",
  },
  {
    _id: "1",
    portal_name: "Bayut & Dubizzle",
    link: "/agent/MTA0NS1kYXFyOGI2/bayut&dubizzle",
  },
  {
    _id: "2",
    portal_name: "Propertyfinder",
    link: "/agent/MTA0NS1kYXFyOGI2/propertyfinder",
  },
  {
    _id: "3",
    portal_name: "Binayah Website",
    link: "/agent/MTA0NS1kYXFyOGI2/binayahwebsite",
  },
  {
    _id: "1",
    portal_name: "Bayut & Dubizzle",
    link: "/agent/MTA0NS1kYXFyOGI2/bayut&dubizzle",
  },
  {
    _id: "2",
    portal_name: "Propertyfinder",
    link: "/agent/MTA0NS1kYXFyOGI2/propertyfinder",
  },
  {
    _id: "3",
    portal_name: "Binayah Website",
    link: "/agent/MTA0NS1kYXFyOGI2/binayahwebsite",
  },
  {
    _id: "1",
    portal_name: "Bayut & Dubizzle",
    link: "/agent/MTA0NS1kYXFyOGI2/bayut&dubizzle",
  },
];
