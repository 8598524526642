import React from "react";
import WebsiteLeadsList from "./WebsiteLeadsList";
import CustomBreadcrumb from "../../Common/customBreadcrumb";

const WebsiteLeads = () => {
  return (
    <>
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Manage Website Leads"
          home="Dashboard"
          currentPage="Website Leads"
          addExtraPageTitle="All Leads"
          addExtraPageTitlePath="all-leads"
        />
        <WebsiteLeadsList />
      </div>
    </>
  );
};

export default WebsiteLeads;
