import React, { useEffect, useState } from "react";
import CustomBreadcrumb from "../../../Common/customBreadcrumb";
import DataTable from "react-data-table-component";
import { customStyles } from "../../../Common/dataTableStyle";
import { Link, useParams } from "react-router-dom";
import { propertyListData } from "../../../../asset/StastucJsonData/PropertyJsonData";

const PropertysPage = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [getIdWiseData, setGetIdWiseData] = useState([]);

  let { id } = useParams();

  const getAgentData = (id) => {
    const findData = propertyListData.filter((item) => item?._id === id);
    setGetIdWiseData(findData);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = getIdWiseData.filter((item) =>
    Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const column = [
    {
      name: "Ref",
      selector: (row) => row?.Reference,
      center: true,
      wrap: true,
    },
    {
      name: "Type",
      selector: (row) => row?.Type,
      center: true,
      wrap: true,
    },
    {
      name: "Unit",
      selector: (row) => row?.Listings_UnitNo,
      center: true,
      wrap: true,
    },
    {
      name: "Category",
      selector: (row) => row?.Listings_Category,
      center: true,
      wrap: true,
    },
    {
      name: "Location",
      selector: (row) => row?.Area_Location,
      center: true,
      wrap: true,
    },
    {
      name: "Sub Location",
      selector: (row) => row?.Sub_Area_Location,
      center: true,
      wrap: true,
    },
    {
      name: "Tenancy Start Date",
      selector: (row) => row?.Tenancy_Start_Date,
      center: true,
      wrap: true,
      width: "150px",
    },
    {
      name: "Tenancy Renewal Date",
      selector: (row) => row?.Tenancy_Renewal_Date,
      center: true,
      wrap: true,
      width: "150px",
    },
    {
      name: "Statement",
      selector: (row) => row?.Statement,
      center: true,
      wrap: true,
    },
    {
      name: "Edit",
      cell: (row) => (
        <>
          <Link to={`/tenants-edit-property/${row?._id}`}>
            <div className="cursor-pointer">
              <img width={"20px"} src="/svg/pencil.png" alt="images" />
            </div>
          </Link>
        </>
      ),
      center: true,
      wrap: true,
      width: "60px",
    },
  ];

  useEffect(() => {
    getAgentData(id);
  }, [id]);

  return (
    <>
      <div className="container">
        <CustomBreadcrumb
          title={`Show Tenant Property for - ${getIdWiseData[0]?.Agent1}`}
          home="Dashboard"
          currentPage={`Show Tenant Property for - ${getIdWiseData[0]?.Agent1}`}
          addExtraPageTitle="Tenants"
          addExtraPageTitlePath="tenants"
        />
      </div>
      <div className="container">
        <div className="datatable-header-main property-finder-leads-containner">
          <div className="searchbox-parant-main my-3">
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearch}
              className="add-search-box-in-datatable rounded-0"
            />
            <i className="fa fa-search"></i>
          </div>
        </div>
        <div className="mt-2 mb-2">
          <DataTable
            data={filteredData ? filteredData : getIdWiseData}
            noDataComponent="No data available"
            customStyles={customStyles}
            highlightOnHover
            noHeader={false}
            persistTableHead
            columns={column}
            pagination
            searchable
            responsive
          />
        </div>
      </div>
    </>
  );
};

export default PropertysPage;
