import React from "react";
import CustomBreadcrumb from "../../../Common/customBreadcrumb";
import EditAllLead from "./EditAllLead";

const EditAllLeadPage = () => {
  return (
    <>
      <div className="container page-containners-main ">
        <CustomBreadcrumb
          title="Edit All Lead"
          home="Dashboard"
          currentPage="Edit All Leads"
          addExtraPageTitle="All Leads"
          addExtraPageTitlePath="all-leads"
        />
        <EditAllLead />
      </div>
    </>
  );
};

export default EditAllLeadPage;
