import React, { useEffect, useState } from "react";
import "../../../../asset/css/pages/tenants.css";
import CustomDatePicker from "../../../Common/DatePickerCommon";
import CustomSelect from "../../../Common/customSelectbox";
import { Link, useParams } from "react-router-dom";
import { tenantsListData } from "../../../../asset/StastucJsonData/TenantsJsonData";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";

const EditOwnersList = () => {
  const [selectType, setSelectType] = useState(0);
  const [getIdWiseDatas, setGetIdWiseDatas] = useState({});

  let { id } = useParams();

  const getIdWiseData = (id) => {
    const findData = tenantsListData.find((item) => id === item?._id);
    setGetIdWiseDatas(findData);
  };

  const options = [
    {
      is_approved: [
        {
          id: 1,
          option: "Yes",
        },
        {
          id: 2,
          option: "Partially",
        },
      ],
      is_approved_document: [
        {
          id: 1,
          option: "No",
        },
        {
          id: 2,
          option: "Yes",
        },
      ],
    },
  ];

  const initialValues = {
    owner_id: "",
    owner_type: "",
    email: "",
    password: "",
    emirates_id_ront: "",
    emirates_id_back: "",
    passport: "",
    visa: "",
    primary_no: "",
    secondary_no: "",
    nationality: "",
    owner_document: "",
    owner_phone_no: "",
    is_approved: "",
    date_of_birth: "",
    passport_expiry_date: "",
    visa_expiry_date: "",
    emirates_id_expiry_date: "",
  };

  const validationSchema = Yup.object()
    .shape({
      owner_id: Yup.string().required("Field is required."),
      owner_type: Yup.string().required("Field is required."),
      email: Yup.string().required("Field is required."),
      password: Yup.string().required("Field is required."),
      emirates_id_ront: Yup.string().required("Field is required."),
      emirates_id_back: Yup.string().required("Field is required."),
      passport: Yup.string().required("Field is required."),
      visa: Yup.string().required("Field is required."),
      primary_no: Yup.string().required("Field is required."),
      secondary_no: Yup.string().required("Field is required."),
      nationality: Yup.string().required("Field is required."),
      owner_document: Yup.string().required("Field is required."),
      owner_phone_no: Yup.string().required("Field is required."),
      is_approved: Yup.string().required("Field is required."),
      date_of_birth: Yup.date().nullable().required("Date is required"),
      passport_expiry_date: Yup.date().nullable().required("Date is required"),
      visa_expiry_date: Yup.date().nullable().required("Date is required"),
      emirates_id_expiry_date: Yup.date()
        .nullable()
        .required("Date is required"),
    })
    .defined();

  const handleSubmitForm = (e) => {
    console.log(e);
  };

  useEffect(() => {
    getIdWiseData(id);
  }, [id]);

  return (
    <>
      <div className="add-tenants mb-5">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmitForm}
        >
          {(formik) => {
            const { setFieldValue } = formik;
            return (
              <>
                <Form>
                  <div className="row my-3">
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                      <p className="m-0">
                        Owner Id <span className="text-danger">*</span>
                      </p>
                      <Field
                        className="form-control rounded-0"
                        type="text"
                        placeholder="Enter Owner Id"
                        name="owner_id"
                      />
                      <ErrorMessage
                        name="owner_id"
                        component={"div"}
                        className="text-danger"
                      />
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                      <p className="m-0">
                        Owner Type <span className="text-danger">*</span>
                      </p>
                      <Field
                        className="form-control rounded-0"
                        type="text"
                        placeholder="Enter Owner Type"
                        name="owner_type"
                      />
                      <ErrorMessage
                        name="owner_type"
                        component={"div"}
                        className="text-danger"
                      />
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                      <p className="m-0">
                        Email <span className="text-danger">*</span>
                      </p>
                      <Field
                        className="form-control rounded-0"
                        type="text"
                        placeholder="Enter Email"
                        name="email"
                      />
                      <ErrorMessage
                        name="email"
                        component={"div"}
                        className="text-danger"
                      />
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                      <p className="m-0">
                        Password <span className="text-danger">*</span>
                      </p>
                      <Field
                        className="form-control rounded-0"
                        type="text"
                        placeholder="Enter Password"
                        name="password"
                      />
                      <ErrorMessage
                        name="password"
                        component={"div"}
                        className="text-danger"
                      />
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                      <p className="m-0">
                        Emirates ID Front <span className="text-danger">*</span>
                      </p>
                      <Field
                        className="form-control rounded-0"
                        type="text"
                        placeholder="Enter Emirates ID Front"
                        name="emirates_id_ront"
                      />
                      <ErrorMessage
                        name="emirates_id_ront"
                        component={"div"}
                        className="text-danger"
                      />
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                      <p className="m-0">
                        Emirates ID Back <span className="text-danger">*</span>
                      </p>
                      <Field
                        className="form-control rounded-0"
                        type="text"
                        placeholder="Enter Emirates ID Back"
                        name="emirates_id_back"
                      />
                      <ErrorMessage
                        name="emirates_id_back"
                        component={"div"}
                        className="text-danger"
                      />
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                      <p className="m-0">
                        Passport <span className="text-danger">*</span>
                      </p>
                      <Field
                        className="form-control rounded-0"
                        type="file"
                        placeholder="Enter Passport"
                        name="passport"
                      />
                      <ErrorMessage
                        name="passport"
                        component={"div"}
                        className="text-danger"
                      />
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                      <p className="m-0">
                        Visa <span className="text-danger">*</span>
                      </p>
                      <Field
                        className="form-control rounded-0"
                        type="file"
                        placeholder="Enter Visa"
                        name="visa"
                      />
                      <ErrorMessage
                        name="visa"
                        component={"div"}
                        className="text-danger"
                      />
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                      <p className="m-0">
                        Primary mobile number
                        <span className="text-danger">*</span>
                      </p>
                      <Field
                        className="form-control rounded-0"
                        type="text"
                        placeholder="Enter Primary No."
                        name="primary_no"
                      />
                      <ErrorMessage
                        name="primary_no"
                        component={"div"}
                        className="text-danger"
                      />
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                      <p className="m-0">
                        Secondary mobile number{" "}
                        <span className="text-danger">*</span>
                      </p>
                      <Field
                        className="form-control rounded-0"
                        type="text"
                        placeholder="Enter Secondary No."
                        name="secondary_no"
                      />
                      <ErrorMessage
                        name="secondary_no"
                        component={"div"}
                        className="text-danger"
                      />
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                      <p className="m-0">
                        Nationality <span className="text-danger">*</span>
                      </p>
                      <Field
                        className="form-control rounded-0"
                        type="text"
                        placeholder="Enter Nationality"
                        name="nationality"
                      />
                      <ErrorMessage
                        name="nationality"
                        component={"div"}
                        className="text-danger"
                      />
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3 mb-4 ">
                      <p className="m-0">
                        Is Document to be shown to the Owner ?{" "}
                        <span className="text-danger">*</span>
                      </p>
                      <CustomSelect
                        options={options[0]?.is_approved}
                        placeholder=" - Select - "
                        value={selectType}
                        onChange={(name, value) => {
                          setFieldValue(name, value);
                        }}
                        name={"owner_document"}
                      />
                      <ErrorMessage
                        name="owner_document"
                        component={"div"}
                        className="text-danger"
                      />
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3 mb-4 ">
                      <p className="m-0">
                        Is Phone Number to be shown to the Owners ?
                        <span className="text-danger">*</span>
                      </p>
                      <CustomSelect
                        options={options[0]?.is_approved_document}
                        placeholder=" - Select - "
                        value={selectType}
                        onChange={(name, value) => {
                          setFieldValue(name, value);
                        }}
                        name={"owner_phone_no"}
                      />
                      <ErrorMessage
                        name="owner_phone_no"
                        component={"div"}
                        className="text-danger"
                      />
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3 mb-4 ">
                      <p className="m-0">
                        Is Approved ? <span className="text-danger">*</span>
                      </p>
                      <CustomSelect
                        options={options[0]?.is_approved_document}
                        placeholder=" - Select - "
                        value={selectType}
                        onChange={(name, value) => {
                          setFieldValue(name, value);
                        }}
                        name={"is_approved"}
                      />
                      <ErrorMessage
                        name="is_approved"
                        component={"div"}
                        className="text-danger"
                      />
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3 mb-4">
                      <p className="m-0">
                        Date Of Birth <span className="text-danger">*</span>
                      </p>
                      <Field name="date_of_birth">
                        {({ field, form }) => (
                          <CustomDatePicker
                            startDate={field.value}
                            setStartDate={(date) =>
                              form.setFieldValue("date_of_birth", date)
                            }
                            src={"/svg/calendar.png"}
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="date_of_birth"
                        component={"div"}
                        className="text-danger"
                      />
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3 mb-4">
                      <p className="m-0">
                        Passport Expiry Date{" "}
                        <span className="text-danger">*</span>
                      </p>
                      <Field name="passport_expiry_date">
                        {({ field, form }) => (
                          <CustomDatePicker
                            startDate={field.value}
                            setStartDate={(date) =>
                              form.setFieldValue("passport_expiry_date", date)
                            }
                            src={"/svg/calendar.png"}
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="passport_expiry_date"
                        component={"div"}
                        className="text-danger"
                      />
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3 mb-4">
                      <p className="m-0">
                        Visa Expiry Date
                        <span className="text-danger">*</span>
                      </p>
                      <Field name="visa_expiry_date">
                        {({ field, form }) => (
                          <CustomDatePicker
                            startDate={field.value}
                            setStartDate={(date) =>
                              form.setFieldValue("visa_expiry_date", date)
                            }
                            src={"/svg/calendar.png"}
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="visa_expiry_date"
                        component={"div"}
                        className="text-danger"
                      />
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3 mb-4">
                      <p className="m-0">
                        Emirates ID Expiry Date
                        <span className="text-danger">*</span>
                      </p>
                      <Field name="emirates_id_expiry_date">
                        {({ field, form }) => (
                          <CustomDatePicker
                            startDate={field.value}
                            setStartDate={(date) =>
                              form.setFieldValue(
                                "emirates_id_expiry_date",
                                date
                              )
                            }
                            src={"/svg/calendar.png"}
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="emirates_id_expiry_date"
                        component={"div"}
                        className="text-danger"
                      />
                    </div>
                  </div>
                  <div className="cansel-submit-note-btn text-center mt-5 mb-3">
                    <button type="submit" className="btn btn-secondary mx-2">
                      Submit
                    </button>

                    <Link to="/owners">
                      <button className="mx-2 btn btn-light">Cancel</button>
                    </Link>
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default EditOwnersList;
