// SALES OFFER STATIS DATA...

export const salesOfferListData = [
  {
    _id: "1",
    ref_no: "AmalTower/REF/01092023_1",
    unit_no: "1021",
  },
  {
    _id: "2",
    ref_no: "AmalTower/REF/01092023_2",
    unit_no: "6452",
  },
  {
    _id: "3",
    ref_no: "AmalTower/REF/01092023_3",
    unit_no: "7562",
  },
  {
    _id: "4",
    ref_no: "AmalTower/REF/01092023_4",
    unit_no: "2364",
  },
  {
    _id: "5",
    ref_no: "AmalTower/REF/01092023_5",
    unit_no: "7524",
  },
  {
    _id: "6",
    ref_no: "AmalTower/REF/01092023_6",
    unit_no: "9734",
  },
  {
    _id: "7",
    ref_no: "AmalTower/REF/01092023_7",
    unit_no: "2574",
  },
  {
    _id: "8",
    ref_no: "AmalTower/REF/01092023_8",
    unit_no: "4554",
  },
  {
    _id: "9",
    ref_no: "AmalTower/REF/01092023_9",
    unit_no: "2313",
  },
  {
    _id: "10",
    ref_no: "AmalTower/REF/01092023_10",
    unit_no: "8409",
  },
];
