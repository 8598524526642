import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { agentListData } from "../../../../asset/StastucJsonData/LocationJsonData";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import {
  CommonDateField,
  CommonInputField,
  FormikCanselButton,
  FormikSelectField,
  FormikSubmitButton,
} from "../../../Common/CommonFromikFields";

const EditAgent = () => {
  const [data, setData] = useState([]);

  let { id } = useParams();

  const getIdWiseData = (id) => {
    const findData = agentListData.find((item) => id === item?._id);
    setData(findData);
  };

  const initialValues = {
    agent: data?.agent_name || "",
    email: data?.email || "",
    mobile_no: data?.mobile_no || "",
    user_name: data?.user_name || "",
    password: data?.password || "",
    dob: data?.dob || "",
    team_leader: "",
    work_area: "",
  };

  const validationSchema = Yup.object()
    .shape({
      agent: Yup.string().required("Field is required."),
      email: Yup.string().required("Field is required."),
      mobile_no: Yup.string().required("Field is required."),
      user_name: Yup.string().required("Field is required."),
      password: Yup.string().required("Field is required."),
      dob: Yup.string().required("Field is required."),
      team_leader: Yup.string().required("Field is required."),
      work_area: Yup.string().required("Field is required."),
    })
    .defined();

  const handleubmitForm = (e) => {
    console.log(e);
  };
  useEffect(() => {
    getIdWiseData(id);
  }, [id]);

  return (
    <>
      <div className="">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleubmitForm}
          enableReinitialize={true}
        >
          {() => {
            return (
              <>
                <Form>
                  <div className="row">
                    <CommonInputField
                      lable="Agent Name"
                      name="agent"
                      placeholder="Enter Agent Name"
                    />
                    <CommonInputField
                      lable="Email"
                      name="email"
                      placeholder="Enter Email"
                    />
                    <CommonInputField
                      lable="Mobile No"
                      name="mobile_no"
                      placeholder="Enter Mobile No"
                    />
                    <CommonInputField
                      lable="User Name"
                      name="user_name"
                      placeholder="Enter User Name"
                    />
                    <CommonInputField
                      lable="Password"
                      name="password"
                      placeholder="Enter Password"
                    />
                  </div>
                  <div className="row">
                    <CommonDateField lable="Date of Birth" name="dob" />
                    <FormikSelectField
                      label="Teem Leader"
                      name="team_leader"
                      options={[]}
                      placeholder=" - Select Team Leader - "
                    />
                    <FormikSelectField
                      label="Work Area"
                      name="work_area"
                      options={[]}
                      placeholder=" - Select Work Area - "
                    />
                  </div>
                  <div className="cansel-submit-note-btn text-center mt-5 mb-3">
                    <FormikSubmitButton />
                    <FormikCanselButton path="/agents" />
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default EditAgent;
