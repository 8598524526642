import React from "react";
import CustomBreadcrumb from "../../../Common/customBreadcrumb";
import EditAllListing from "./EditAllListing";

const EditAllListingPage = () => {
  return (
    <>
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Edit All Listing"
          home="Dashboard"
          currentPage="Edit All Listing"
          addExtraPageTitle="All Listing"
          addExtraPageTitlePath="all-listing"
        />
        <EditAllListing />
      </div>
    </>
  );
};

export default EditAllListingPage;
