import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { addDealsOptionsData } from "../../../../asset/StastucJsonData/AllDealsJsonData";
import { unitModelListData } from "../../../../asset/StastucJsonData/unitModelJsonData";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import {
  CommonDateField,
  CommonInputField,
  FormikCanselButton,
  FormikSelectField,
  FormikSubmitButton,
} from "../../../Common/CommonFromikFields";

const EditUnitModel = () => {
  const [data, setData] = useState([]);

  const getDealsOptionsData = addDealsOptionsData[0] || [];

  let { id } = useParams();

  const initialValues = {
    project: "",
    estimated_completion_date: "",
    type: "",
    property_status: "",
    totle_area: "",
    salling_price: "",
    installment: "",
    percentage: "",
    date: "",
    amount_aed: "",
    unit_no: data?.unit_no || "",
    unit_type: data?.unit_type || "",
    unit_size: data?.unit_size || "",
    price_per_ft: data?.price_per_ft || "",
    sales_price: data?.sales_proce || "",
    down_payment: data?.down_payment || "",
    three_month: data?.three_month || "",
    six_month: data?.six_month || "",
    handover: data?.handover || "",
    three_year_plan: data?.three_year_plan || "",
    images: "",
  };

  const validationSchema = Yup.object()
    .shape({
      project: Yup.string().required("Fieldm is required."),
      estimated_completion_date: Yup.date()
        .nullable()
        .required("Date is required."),
      type: Yup.string().required("Fieldm is required."),
      property_status: Yup.string().required("Fieldm is required."),
      totle_area: Yup.string().required("Fieldm is required."),
      salling_price: Yup.string().required("Fieldm is required."),
      installment: Yup.string().required("Fieldm is required."),
      percentage: Yup.string().required("Fieldm is required."),
      date: Yup.string().required("Fieldm is required."),
      amount_aed: Yup.string().required("Fieldm is required."),
      unit_no: Yup.string().required("Fieldm is required."),
      unit_type: Yup.string().required("Fieldm is required."),
      unit_size: Yup.string().required("Fieldm is required."),
      price_per_ft: Yup.string().required("Fieldm is required."),
      sales_price: Yup.string().required("Fieldm is required."),
      down_payment: Yup.string().required("Fieldm is required."),
      three_month: Yup.string().required("Fieldm is required."),
      six_month: Yup.string().required("Fieldm is required."),
      handover: Yup.string().required("Fieldm is required."),
      three_year_plan: Yup.string().required("Fieldm is required."),
      images: Yup.string().required("Fieldm is required."),
    })
    .defined();

  const handleSubmit = (e) => {
    console.log(e);
  };

  const getIdWiseData = (id) => {
    const findData = unitModelListData.find((item) => item?._id === id);
    setData(findData);
  };

  useEffect(() => {
    getIdWiseData(id);
  }, [id]);

  return (
    <>
      <div className="add-unit-model-containner px-3 py-5">
        <div className="mb-4 border-bottom-1">
          Fieds Marked with (<span className="text-danger"> * </span>) are
          Mandatory
        </div>

        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange
          onSubmit={handleSubmit}
        >
          {() => {
            return (
              <>
                <Form>
                  <div className="row d-flex  mb-3">
                    <CommonInputField lable={"Project"} name="project" />

                    <CommonDateField
                      lable={"Estimated Completion Date"}
                      name={"estimated_completion_date"}
                    />

                    <FormikSelectField
                      label="Type"
                      name="type"
                      options={getDealsOptionsData?.select_by_type}
                    />

                    <FormikSelectField
                      label="Property status"
                      name="property_status"
                      options={getDealsOptionsData?.select_by_type}
                    />

                    <CommonInputField
                      lable={"Total Area(SQ. FT)"}
                      name={"totle_area"}
                    />
                    <CommonInputField
                      lable={"Selling Price (AED)"}
                      name={"salling_price"}
                    />
                  </div>
                  <div className="my-1 mb-3">
                    <div className="my-3">
                      <h3>Instalments</h3>
                    </div>
                    <div className="cansel-submit-note-btn  mt-5 mb-3">
                      <button className="btn btn-secondary my-2">
                        Add Row{" "}
                      </button>

                      <button className="my-2 ml-2 btn btn-light">
                        Delete Row
                      </button>
                    </div>
                    <div className="row">
                      <CommonInputField
                        lable={"Installment"}
                        name={"installment"}
                      />
                      <CommonInputField
                        lable={"Percentage (%)"}
                        name={"percentage"}
                      />
                      <FormikSelectField
                        label="Date"
                        name="date"
                        options={getDealsOptionsData?.select_by_type}
                      />

                      <CommonInputField
                        lable={"Amount (AED) (%)"}
                        name={"amount_aed"}
                      />
                    </div>
                  </div>
                  <div className="my-1 mb-3">
                    <div className="my-3">
                      <h3>Unit Model</h3>
                    </div>
                    <div className="row">
                      <CommonInputField lable={"Unit No."} name={"unit_no"} />
                      <CommonInputField
                        lable={"Unit Type"}
                        name={"unit_type"}
                      />
                      <CommonInputField
                        lable={"Unit Size"}
                        name={"unit_size"}
                      />
                      <CommonInputField
                        lable={"Price Per FT"}
                        name={"price_per_ft"}
                      />
                      <CommonInputField
                        lable={"Sales Price"}
                        name={"sales_price"}
                      />
                      <CommonInputField
                        lable={"Down Payment"}
                        name={"down_payment"}
                      />
                      <CommonInputField
                        lable={"3 month"}
                        name={"three_month"}
                      />
                      <CommonInputField lable={"6 month"} name={"six_month"} />
                      <CommonInputField lable={"Handover"} name={"handover"} />
                      <CommonInputField
                        lable={"3 Years Plan"}
                        name={"three_year_plan"}
                      />
                      <CommonInputField
                        lable={"Image"}
                        name={"images"}
                        type="file"
                      />
                    </div>
                  </div>
                  <div className="text-center mt-5 mb-3">
                    <FormikSubmitButton />
                    <FormikCanselButton path="/create-project" />
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default EditUnitModel;
