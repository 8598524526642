// CALENDER LIST STATIC DATA...

export const CalendarListData = [
  {
    _id: 1,
    property_ref_no: "",
    event: "office",
    date: "26-04-2024",
    time: "10:30",
    status: "",
    agent: "Shahbaz Khan",
  },
  {
    _id: 2,
    property_ref_no: "",
    event: "office",
    date: "26-04-2024",
    time: "10:30",
    status: "",
    agent: "Shahbaz Khan",
  },
  {
    _id: 3,
    property_ref_no: "",
    event: "office",
    date: "26-04-2024",
    time: "10:30",
    status: "",
    agent: "Shahbaz Khan",
  },
  {
    _id: 4,
    property_ref_no: "",
    event: "office",
    date: "26-04-2024",
    time: "10:30",
    status: "",
    agent: "Shahbaz Khan",
  },
  {
    _id: 5,
    property_ref_no: "",
    event: "office",
    date: "26-04-2024",
    time: "10:30",
    status: "",
    agent: "Shahbaz Khan",
  },
  {
    _id: 6,
    property_ref_no: "",
    event: "office",
    date: "26-04-2024",
    time: "10:30",
    status: "",
    agent: "Shahbaz Khan",
  },
  {
    _id: 7,
    property_ref_no: "",
    event: "office",
    date: "26-04-2024",
    time: "10:30",
    status: "",
    agent: "Shahbaz Khan",
  },
  {
    _id: 8,
    property_ref_no: "",
    event: "office",
    date: "26-04-2024",
    time: "10:30",
    status: "",
    agent: "Shahbaz Khan",
  },
  {
    _id: 9,
    property_ref_no: "",
    event: "office",
    date: "26-04-2024",
    time: "10:30",
    status: "",
    agent: "Shahbaz Khan",
  },
  {
    _id: 10,
    property_ref_no: "",
    event: "office",
    date: "26-04-2024",
    time: "10:30",
    status: "",
    agent: "Shahbaz Khan",
  },
];
