import React from "react";
import "../../../asset/css/common/model.css";
import CustomBreadcrumb from "../../Common/customBreadcrumb";
import ListOldLead from "./ListOldLead";

const OldLead = () => {
  return (
    <div className="container page-containners-main">
      <CustomBreadcrumb
        title="Old Lead"
        home="Dashboard"
        currentPage="Old Lead"
        addExtraPageTitle="All Leads"
        addExtraPageTitlePath="all-leads"
      />
      <ListOldLead />
    </div>
  );
};

export default OldLead;
