import React from "react";

const DetailComponent = ({
  title,
  title1,
  title2,
  title3,
  data,
  data1,
  data2,
  data3,
}) => {
  return (
    <>
      <div className="olad-lead-card-main-containner">
        <div className="p-3 olad-lead-card">
          <div className="row ">
            <div className="col-lg-3 col-md-6 col-sm-12 mb-3 ">
              {title && (
                <>
                  <div className="old-lead-details-data">
                    <span className="mb-0 fw-bold d-block">{title}</span>
                    <span className="mb-0 pl-2">{data || " - "}</span>
                  </div>
                </>
              )}
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mb-3 ">
              {title1 && (
                <>
                  <div className="old-lead-details-data">
                    <span className="mb-0 fw-bold d-block">{title1}</span>
                    <span className="mb-0 pl-2">{data1 || " - "}</span>
                  </div>
                </>
              )}
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mb-3 ">
              {title2 && (
                <>
                  <div className="old-lead-details-data">
                    <span className="mb-0 fw-bold d-block">{title2}</span>
                    <span className="mb-0 pl-2">{data2 || " - "}</span>
                  </div>
                </>
              )}
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mb-3 ">
              {title3 && (
                <>
                  <div className="old-lead-details-data">
                    <span className="mb-0 fw-bold d-block">{title3}</span>
                    <span className="mb-0 pl-2">{data3 || " - "}</span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailComponent;
