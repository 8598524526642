import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import CustomSelect from "../../Common/customSelectbox";
import LeadDetails from "./LeadDetails";
import "../../../asset/css/common/customeDatePicker.css";
import "../../../asset/css/pages/allLead.css";
import CustomDatePicker from "../../Common/DatePickerCommon";
import {
  listStaticSelectOptions,
  getOwnnerData,
} from "../../../asset/StastucJsonData/allLeadJsonData";
import { customStyles } from "../../Common/dataTableStyle.jsx";
import { Link, useNavigate } from "react-router-dom";

const ListAllLead = () => {
  // DEFINE STATE...

  const [selectedValue, setSelectedValue] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [textareaHeight, setTextAreaHeight] = useState("40px");
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectAll, setSelectAll] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectAgentLeadId, setSelectAgentLeadId] = useState("1");
  const [selectAgentLeadIdWiseData, setSelectAgentLeadIdWiseData] = useState(
    []
  );

  const [perPage, setPerPage] = useState(10);

  const navigate = useNavigate();

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  // DEFINE VARIABLES...
  const listStaticSelectOptionsData = listStaticSelectOptions[0] || [];
  var rowsPerPage = 10;

  // MODEL OPEN AND CLOSE FUNCTIONS...
  const handleSelectChange = (option) => {
    setSelectedValue(option);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const paginationComponentOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  // Handle change page for data table functionality...

  const handlePageChange = (page) => {
    setCurrentPage(page);
    const pageRows = getOwnnerData.slice((page - 1) * perPage, page * perPage);
    const allPageRowsSelected = pageRows.every((row) =>
      selectedRowIds.includes(row.user_id)
    );
    setSelectAll(allPageRowsSelected);
  };

  // Handle select box functionality for data table...
  const handleRowCheckboxChange = (rowId) => {
    const isSelected = selectedRowIds.includes(rowId);
    const newSelectedRowIds = isSelected
      ? selectedRowIds.filter((id) => id !== rowId)
      : [...selectedRowIds, rowId];

    setSelectedRowIds(newSelectedRowIds);
    setSelectAll(newSelectedRowIds.length === getOwnnerData.length);
  };

  const handleSelectAllChange = () => {
    const pageRows = getOwnnerData.slice(
      (currentPage - 1) * perPage,
      currentPage * perPage
    );
    const pageRowIds = pageRows.map((row) => row.user_id);

    if (selectAll) {
      setSelectedRowIds(
        selectedRowIds.filter((id) => !pageRowIds.includes(id))
      );
      setSelectAll(false);
    } else {
      setSelectedRowIds([
        ...selectedRowIds,
        ...pageRowIds.filter((id) => !selectedRowIds.includes(id)),
      ]);
      setSelectAll(true);
    }
  };

  // Search functionality for data table....

  const filteredData = getOwnnerData.filter((item) =>
    Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const getLeadDetailsIdWiseData = (id) => {
    const findData = getOwnnerData.find((user) => id === user.user_id);
    setSelectAgentLeadIdWiseData(findData);
  };

  const handleRefNoClick = (row) => {
    setSelectAgentLeadId(row.user_id);
  };

  useEffect(() => {
    getLeadDetailsIdWiseData(selectAgentLeadId);
  }, [selectAgentLeadId]);

  // define data table column array...
  const column = [
    {
      name: (
        <div className="text-center">
          {/* <span>Sr.</span> */}
          <input
            type="checkbox"
            checked={selectAll}
            onChange={handleSelectAllChange}
          />
        </div>
      ),
      width: "40px",
      cell: (row) => (
        <div className="text-center">
          <input
            type="checkbox"
            checked={selectedRowIds.includes(row.user_id)}
            onChange={() => handleRowCheckboxChange(row.user_id)}
          />
        </div>
      ),
      center: true,
      wrap: true,
    },
    {
      name: "Ref No.",
      selector: (row) => row.ref_no,
      center: true,
      wrap: true,
    },
    {
      name: "Date & Time",
      selector: (row) => row.date_time,
      center: true,
      wrap: true,
    },
    {
      name: "Status",
      width: "60px",
      selector: (row) => row.status,
      center: true,
      wrap: true,
    },
    {
      name: "S. Status",
      selector: (row) => row.s_status,
      center: true,
      wrap: true,
    },
    {
      name: "Project",

      selector: (row) => row.project,
      center: true,
      wrap: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      center: true,
      wrap: true,
    },
    {
      name: "Mobile",
      selector: (row) => row.mobile,
      center: true,
      wrap: true,
    },
    {
      name: "Email",
      width: "150px",
      selector: (row) => row.email,
      center: true,
      wrap: true,
    },
    {
      name: "Category",
      selector: (row) => row.category,
      center: true,
      wrap: true,
    },
    {
      name: "Location",
      selector: (row) => row.location,
      center: true,
      wrap: true,
    },
    {
      name: "S. Location",
      selector: (row) => row.s_location,
      center: true,
      wrap: true,
    },
    {
      name: "Agent",
      selector: (row) => row.agent,
      center: true,
      wrap: true,
    },
    {
      name: "Team Leader",
      selector: (row) => row.teem_leader,
      center: true,
      wrap: true,
    },
    {
      name: "Source",
      selector: (row) => row.souece,
      center: true,
      wrap: true,
    },
    {
      name: "Message",
      width: "65px",
      cell: (row) => (
        <div className="text-center cursor-pointer">
          <img width={"20px;"} src="/svg/eye.png" alt="img" />
        </div>
      ),
      center: true,
      wrap: true,
    },
    {
      name: "Edit",
      width: "40px",
      cell: (row) => (
        <>
          <Link to={`/edit-all-leads/${row?.user_id}`}>
            <div className="cursor-pointer">
              <img width={"20px;"} src="/svg/pencil.png" alt="img" />
            </div>
          </Link>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      center: true,
      wrap: true,
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row) => true,
      style: {
        cursor: "pointer",
      },
    },
  ];
  return (
    <>
      <div className="all-list-main-containner">
        <div className="row">
          <div className="col-lg-2 col-md-4 col-sm-6 mb-2">
            <CustomDatePicker
              startDate={startDate}
              setStartDate={setStartDate}
              src={"/svg/calendar.png"}
            />
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 mb-2">
            <CustomDatePicker
              startDate={startDate}
              setStartDate={setStartDate}
              src={"/svg/calendar.png"}
            />
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 mb-2">
            <CustomSelect
              options={listStaticSelectOptionsData.filter_by_agent}
              placeholder={"Select Agent"}
              value={selectedValue}
              onChange={handleSelectChange}
            />
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 mb-2">
            <CustomSelect
              options={listStaticSelectOptionsData.filter_by_team_lead}
              placeholder={"Select Team Leader"}
              value={selectedValue}
              onChange={handleSelectChange}
            />
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 mb-2">
            <CustomSelect
              options={listStaticSelectOptionsData.filter_by_status}
              placeholder={"Filter By Status"}
              value={selectedValue}
              onChange={handleSelectChange}
            />
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 mb-2">
            <CustomSelect
              options={listStaticSelectOptionsData.filter_by_sub_status}
              placeholder={"Filter By SubStatus"}
              value={selectedValue}
              onChange={handleSelectChange}
            />
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 mb-2">
            <CustomSelect
              options={listStaticSelectOptionsData.filter_by_lead_type}
              placeholder={"Filter By Lead Type"}
              value={selectedValue}
              onChange={handleSelectChange}
            />
          </div>

          <div className="col-lg-2 col-md-4 col-sm-6 mb-2">
            <CustomSelect
              options={listStaticSelectOptionsData.filter_by_lead_channel}
              placeholder={"Filter By Channel"}
              value={selectedValue}
              onChange={handleSelectChange}
            />
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 mb-2">
            <CustomSelect
              options={listStaticSelectOptionsData.filter_by_lead_badrooms}
              placeholder={"Filter By Bedrooms"}
              value={selectedValue}
              onChange={handleSelectChange}
            />
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 mb-2">
            <CustomSelect
              options={listStaticSelectOptionsData.filter_by_lead_emirates}
              placeholder={"Filter By Emirates"}
              value={selectedValue}
              onChange={handleSelectChange}
            />
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 mb-2">
            <CustomSelect
              options={listStaticSelectOptionsData.filter_by_lead_location}
              placeholder={"Filter By Location"}
              value={selectedValue}
              onChange={handleSelectChange}
            />
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 mb-2">
            <CustomSelect
              options={listStaticSelectOptionsData.filter_by_sublocation}
              placeholder={"Filter By SubLocation"}
              value={selectedValue}
              onChange={handleSelectChange}
            />
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 mb-2">
            <CustomSelect
              options={listStaticSelectOptionsData.filter_by_price_range}
              placeholder={"Filter By Price Range"}
              value={selectedValue}
              onChange={handleSelectChange}
            />
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 mb-2">
            <CustomSelect
              options={listStaticSelectOptionsData.filter_by_price_range}
              placeholder={"Filter By Price Range"}
              value={selectedValue}
              onChange={handleSelectChange}
            />
          </div>

          <div className="col-lg-2 col-md-4 col-sm-6 mb-2">
            <CustomSelect
              options={listStaticSelectOptionsData.filter_by_category}
              placeholder={"Filter By Category"}
              value={selectedValue}
              onChange={handleSelectChange}
            />
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 mb-2">
            <CustomSelect
              options={listStaticSelectOptionsData.filter_by_agent}
              placeholder={"Filter By Agent"}
              value={selectedValue}
              onChange={handleSelectChange}
            />
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 mb-2">
            <CustomSelect
              options={listStaticSelectOptionsData.filter_by_team_lead}
              placeholder={"Filter By TeamLead"}
              value={selectedValue}
              onChange={handleSelectChange}
            />
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 mb-2">
            <CustomSelect
              options={listStaticSelectOptionsData.filter_by_open}
              placeholder={"Filter By Open"}
              value={selectedValue}
              onChange={handleSelectChange}
            />
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 mb-2">
            <CustomSelect
              options={listStaticSelectOptionsData.filter_by_substatus}
              placeholder={"Filter By SubStatus"}
              value={selectedValue}
              onChange={handleSelectChange}
            />
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 mb-2">
            {" "}
            <button
              type="button"
              className="btn filter_button listing_button px-2 custom-select"
              style={{ height: "36px" }}
            >
              <i className="fa fa-file-pdf-o me-2"></i>Update Fields
            </button>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 mb-2">
            <button
              type="button"
              className="btn filter_button listing_button px-2 custom-select"
              style={{ height: "36px" }}
            >
              <i className="fa fa-file-pdf-o me-2"></i>Export To CSV
            </button>
          </div>
        </div>
      </div>

      <LeadDetails
        textareaHeight={textareaHeight}
        setTextAreaHeight={setTextAreaHeight}
        selectAgentLeadIdWiseData={selectAgentLeadIdWiseData}
      />

      <div className="d-flex datatable-header-main">
        <button
          type="button"
          className="btn filter_button add_property_button rounded-0"
          onClick={() => navigate("/add-all-leads")}
        >
          <i className="fa fa-plus me-2"></i>Add New Leads
        </button>
        <div className="searchbox-parant-main ">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearch}
            className="add-search-box-in-datatable rounded-0"
          />
          <i className="fa fa-search"></i>
        </div>
      </div>

      <DataTable
        data={filteredData ? filteredData : getOwnnerData}
        conditionalRowStyles={conditionalRowStyles}
        noDataComponent="No data available"
        customStyles={customStyles}
        onRowClicked={handleRefNoClick}
        highlightOnHover
        noHeader={false}
        persistTableHead
        columns={column}
        pagination
        searchable
        responsive
        paginationComponentOptions={paginationComponentOptions}
        paginationTotalRows={filteredData.length}
        onChangePage={handlePageChange}
        paginationPerPage={(perPage, rowsPerPage)}
        onChangeRowsPerPage={handlePerRowsChange}
      />
    </>
  );
};

export default ListAllLead;
