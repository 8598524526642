import React from "react";
import LiveChatLeadsList from "./LiveChatLeadsList";
import CustomBreadcrumb from "../../Common/customBreadcrumb";

const LiveChatLeads = () => {
  return (
    <>
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Manage Live Chat Leads"
          home="Dashboard"
          currentPage="LiveChat Leads"
          addExtraPageTitle="All Leads"
          addExtraPageTitlePath="all-leads"
        />
        <LiveChatLeadsList />
      </div>
    </>
  );
};

export default LiveChatLeads;
