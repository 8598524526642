import React from "react";
import CustomBreadcrumb from "../../../Common/customBreadcrumb";
import AddDealComponent from "./AddDeal";

const AddAllDeals = () => {
  return (
    <>
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Add All Deals"
          home="Dashboard"
          currentPage="Add All Deals"
          addExtraPageTitle="All Deals"
          addExtraPageTitlePath="all-deals"
        />
        <AddDealComponent />
      </div>
    </>
  );
};

export default AddAllDeals;
