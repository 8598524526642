import React from "react";
import PaidLeads1List from "./PaidLeads1List";
import CustomBreadcrumb from "../../Common/customBreadcrumb";

const PaidLeads1 = () => {
  return (
    <>
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Manage Paid Leads 1"
          home="Dashboard"
          currentPage="Paid Leads 1"
          addExtraPageTitle="All Leads"
          addExtraPageTitlePath="all-leads"
        />
        <PaidLeads1List />
      </div>
    </>
  );
};

export default PaidLeads1;
