// DEVELOPER LOGO STATIC DATA...

export const developerListData = [
  {
    _id: "1",
    title: " Lorem Ipsum is not simply random text",
    image_url: "https://picsum.photos/200/300",
  },
  {
    _id: "2",
    title: "Consolidated Urban Real Estate Development",
    image_url: "https://picsum.photos/200",
  },
  {
    _id: "3",
    title: "There are many variations of passages",
    image_url: "https://picsum.photos/id/237/200/300",
  },
  {
    _id: "4",
    title: "All the Lorem Ipsum generators",
    image_url: "https://picsum.photos/seed/picsum/200/300",
  },
  {
    _id: "5",
    title: "The standard chunk",
    image_url: "https://picsum.photos/200/300?grayscale",
  },
  {
    _id: "6",
    title: "Comes from a line in section",
    image_url: "https://picsum.photos/200/300/?blur",
  },
  {
    _id: "7",
    title: "Lorem ipsum dolor sit amet",
    image_url: "https://picsum.photos/200/300/?blur=2",
  },
  {
    _id: "8",
    title: "Consectetur adipiscing elit",
    image_url: "https://picsum.photos/id/870/200/300?grayscale&blur=2",
  },
  {
    _id: "9",
    title: "But I must explain to you",
    image_url: "https://picsum.photos/200/300.jpg",
  },
  {
    _id: "10",
    title: "will give you a complete account of the system",
    image_url: "https://picsum.photos/200/300.webp",
  },
];
