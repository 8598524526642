import React, { useState } from "react";
import { portalListData } from "../../../asset/StastucJsonData/PropertyFinderLeadsJson";
import DataTable from "react-data-table-component";
import { customStyles } from "../../Common/dataTableStyle";

const PortalList = () => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = portalListData.filter((item) =>
    Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const column = [
    {
      name: "Sr.",
      selector: (row, index) => index + 1,
      center: true,
      wrap: true,
      width: "150px",
    },
    {
      name: "Portal Name",
      selector: (row) => row?.portal_name,
      wrap: true,
    },
    {
      name: "Link",
      selector: (row) => row?.link,
      wrap: true,
    },
  ];

  return (
    <>
      <div className="my-3">
        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-12 mb-2 search-box-datatable-search">
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearch}
              className="add-search-box-in-datatable rounded-0"
            />
            <i className="fa fa-search"></i>
          </div>
        </div>
      </div>
      <DataTable
        data={filteredData ? filteredData : portalListData}
        columns={column}
        customStyles={customStyles}
        fixedHeader={true}
        noHeader={false}
        noDataComponent="No data available"
        pagination
        searchable
        responsive
        highlightOnHover
        persistTableHead
      />
    </>
  );
};

export default PortalList;
