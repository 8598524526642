// PROPERTYS STATIC DATA...

export const propertyListData = [
  {
    _id: "660fa7213644bf04fd93e6bd",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 100,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "660fa7213644bf04fd93e6bd",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 200,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "660fa7213644bf04fd93e6bd",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "660fa7213644bf04fd93e6bd",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "660fa7213644bf04fd93e6bd",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "660fa7213644bf04fd93e6bd",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "660fa7213644bf04fd93e6bd",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "660fa7213644bf04fd93e6bd",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "660fa7213644bf04fd93e6bd",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "660fa7213644bf04fd93e6bd",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
];

// AGENT PROPERTYS STATIC DATA...

export const agentPropertyListData = [
  {
    _id: "4",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 100,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "4",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 200,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "4",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "4",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "4",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "4",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "4",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "4",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "4",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "5",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },

  {
    _id: "2",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 100,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "2",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 200,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "2",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "2",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "2",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "2",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "2",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "2",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "2",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "2",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },

  {
    _id: "3",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 100,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "3",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 200,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "3",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "3",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "3",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "3",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "3",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "3",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "3",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "3",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },

  {
    _id: "5",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 100,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "5",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 200,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "5",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "5",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "5",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "5",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "5",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "5",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "5",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "4",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },

  {
    _id: "6",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 100,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "6",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 200,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "6",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "7",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 100,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "8",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 100,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "9",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 100,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "10",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 100,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "1",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 200,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "1",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "1",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "1",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "1",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "1",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "1",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "1",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "10",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "10",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 200,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "10",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "10",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "10",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "10",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "10",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "10",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "10",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "9",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "9",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 200,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "9",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "9",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "9",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "9",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "9",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "9",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "9",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "8",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "8",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 200,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "8",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "8",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "8",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "8",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "8",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "8",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "8",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "7",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "6",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "7",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 100,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "7",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 200,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "7",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "7",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "7",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "7",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "7",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "7",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "7",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "6",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "6",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "6",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "6",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "6",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "6",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
  {
    _id: "5",
    Reference: "BY-D-3987",
    Type: "Rental",
    Listings_UnitNo: 426,
    Created_By: "Binayah Support",
    Area_Location: "Dubai Hills Estate",
    Sub_Area_Location: "Maple at Dubai Hills Estate",
    Tenancy_Renewal_Date: "24-07-2024 00:00",
    Tenancy_Start_Date: "09-01-2024 00:00",
    Listings_Category: "Villa",
    Agent1: "Talal Ahmad",
  },
];
