import React from "react";

const TeamLeaderPage = () => {
  return (
    <div className="w-100 d-flex justify-content-center align-items-center vh-100">
      TeamLeaderPage
    </div>
  );
};

export default TeamLeaderPage;
