import React from "react";
import CustomBreadcrumb from "../../../Common/customBreadcrumb";
import EditAgent from "./editAgent";

const EditAgentsPage = () => {
  return (
    <>
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Edit Agent"
          home="Dashboard"
          currentPage="Edit Agent"
          addExtraPageTitle="Agent"
          addExtraPageTitlePath="agents"
        />
        <EditAgent />
      </div>
    </>
  );
};

export default EditAgentsPage;
