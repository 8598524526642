// LOCATION STATIC DATA...

export const locationListData = [
  {
    _id: 1,
    location: "Abu Dhabi Gate City",
    sub_location: "1 JBR",
    project: "1 Residences",
  },
  {
    _id: 2,
    location: "Abu shagara",
    sub_location: "10C Building",
    project: "1095 Residence",
  },
  {
    _id: 3,
    location: "Academic City",
    sub_location: "15 Northside",
    project: "1095 Residence",
  },
  {
    _id: 4,
    location: "Afnan District",
    sub_location: "151 Residence",
    project: "118 Downtown",
  },
  {
    _id: 5,
    location: "Ain Al Faydah",
    sub_location: "17 Icon Bay",
    project: "12 Villas Project",
  },
  {
    _id: 6,
    location: "Airport Road",
    sub_location: "18 Villas Complex",
    project: "17 Villas Project",
  },
  {
    _id: 7,
    location: "Ajman Corniche Road",
    sub_location: "2 Bedroom Villas",
    project: "18 Burj Boulevard",
  },
  {
    _id: 8,
    location: "Ajman Downtown",
    sub_location: "20 Villas Project",
    project: "2 Bedroom Villas",
  },
  {
    _id: 9,
    location: "Ajman Industrial Area",
    sub_location: "2020 Marquis",
    project: "21 Villas Project",
  },
  {
    _id: 10,
    location: "Ajman Meadows",
    sub_location: "21st Century Tower",
    project: "27 Tower",
  },
  {
    _id: 11,
    location: "Ajman Uptown",
    sub_location: "23 Marina",
    project: "29 Burj Boulevard Podium",
  },
  {
    _id: 12,
    location: "Al Ain Industrial Area",
    sub_location: "29 Burj Boulevard",
    project: "29 Burj Boulevard Tower 1",
  },
  {
    _id: 13,
    location: "Al Ajayyiz",
    sub_location: "29 Dubai South",
    project: "29 Burj Boulevard Tower 2",
  },
  {
    _id: 14,
    location: "Al Ajban",
    sub_location: "2nd Street",
    project: "48 Burj gate",
  },
  {
    _id: 16,
    location: "Al Alia",
    sub_location: "3 Sails Tower",
    project: "585 Building",
  },
];

// AGENT STATIC DATA...

export const agentListData = [
  {
    _id: "1",
    agent_name: "Binayah",
    email: "test@gmail.com",
    mobile_no: 28123869430,
    dob: "11-11-1111",
    user_name: "binayah",
    password: "Binayah2023#@",
  },
  {
    _id: "2",
    agent_name: "Binayah",
    email: "test@gmail.com",
    mobile_no: 28123869430,
    dob: "11-11-1111",
    user_name: "binayah",
    password: "Binayah2023#@",
  },
  {
    _id: "3",
    agent_name: "Binayah",
    email: "test@gmail.com",
    mobile_no: 28123869430,
    dob: "11-11-1111",
    user_name: "binayah",
    password: "Binayah2023#@",
  },
  {
    _id: "4",
    agent_name: "Binayah",
    email: "test@gmail.com",
    mobile_no: 28123869430,
    dob: "11-11-1111",
    user_name: "binayah",
    password: "Binayah2023#@",
  },
  {
    _id: "5",
    agent_name: "Binayah",
    email: "test@gmail.com",
    mobile_no: 28123869430,
    dob: "11-11-1111",
    user_name: "binayah",
    password: "Binayah2023#@",
  },
  {
    _id: "6",
    agent_name: "Binayah",
    email: "test@gmail.com",
    mobile_no: 28123869430,
    dob: "11-11-1111",
    user_name: "binayah",
    password: "Binayah2023#@",
  },
  {
    _id: "7",
    agent_name: "Binayah",
    email: "test@gmail.com",
    mobile_no: 28123869430,
    dob: "11-11-1111",
    user_name: "binayah",
    password: "Binayah2023#@",
  },
  {
    _id: "8",
    agent_name: "Binayah",
    email: "test@gmail.com",
    mobile_no: 28123869430,
    dob: "11-11-1111",
    user_name: "binayah",
    password: "Binayah2023#@",
  },
  {
    _id: "9",
    agent_name: "Binayah",
    email: "test@gmail.com",
    mobile_no: 28123869430,
    dob: "11-11-1111",
    user_name: "binayah",
    password: "Binayah2023#@",
  },
  {
    _id: "10",
    agent_name: "Binayah",
    email: "test@gmail.com",
    mobile_no: 28123869430,
    dob: "11-11-1111",
    user_name: "binayah",
    password: "Binayah2023#@",
  },
];
