import React from "react";
import ListBayutLeads from "./ListBayutLeads";
import CustomBreadcrumb from "../../Common/customBreadcrumb";

const BayutLeads = () => {
  return (
    <>
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Manage Bayut Leads"
          home="Dashboard"
          currentPage="Bayut Leads"
          addExtraPageTitle="All Leads"
          addExtraPageTitlePath="all-leads"
        />
        <ListBayutLeads />
      </div>
    </>
  );
};

export default BayutLeads;
