import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { customStyles } from "../../Common/dataTableStyle";
import { WebsiteLeads } from "../../../asset/StastucJsonData/WebsiteLeadsJsonData";
import CustomDatePicker from "../../Common/DatePickerCommon";

const WebsiteLeadsList = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = WebsiteLeads.filter((item) =>
    Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const column = [
    {
      name: "Sr",
      selector: (row, index) => index + 1,
      center: true,
      wrap: true,
      width: "70px",
    },
    {
      name: "Name",

      selector: (row) => row?.name,
      center: true,
      wrap: true,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
      center: true,
      wrap: true,
      width: "200px",
    },

    {
      name: "Mobile",
      selector: (row) => row?.mobile,
      center: true,
      wrap: true,
    },
    {
      name: "Channel",
      selector: (row) => row?.chanel,
      center: true,
      wrap: true,
    },
    {
      name: "Message",
      cell: (row) => (
        <div className="text-start pl-2 font-monospace">{row?.message}</div>
      ),
      center: true,
      wrap: true,
      width: "300px",
    },
    {
      name: "Project",
      selector: (row) => row?.project_name,
      center: true,
      wrap: true,
    },
    {
      name: "Date",
      selector: (row) => row?.Date_Added,
      center: true,
      wrap: true,
    },
  ];

  return (
    <>
      <div className="my-3">
        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-12 mb-2">
            <CustomDatePicker
              startDate={startDate}
              setStartDate={setStartDate}
              src={"/svg/calendar.png"}
            />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12 mb-2">
            <CustomDatePicker
              startDate={endDate}
              setStartDate={setEndDate}
              src={"/svg/calendar.png"}
            />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12 mb-2">
            <button
              type="button"
              class="btn filter_button listing_button custom-select unit-number-list"
            >
              <i class="fa fa-file-pdf-o me-2"></i>Export To CSV
            </button>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12 mb-2 search-box-datatable-search">
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearch}
              className="add-search-box-in-datatable rounded-0"
            />
            <i className="fa fa-search"></i>
          </div>
        </div>
      </div>

      <DataTable
        data={filteredData ? filteredData : WebsiteLeads}
        columns={column}
        customStyles={customStyles}
        fixedHeader={true}
        noHeader={false}
        noDataComponent="No data available"
        pagination
        searchable
        responsive
        highlightOnHover
        persistTableHead
      />
    </>
  );
};

export default WebsiteLeadsList;
