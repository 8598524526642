import React from "react";
import CustomBreadcrumb from "../../../Common/customBreadcrumb";
import EditOwnersList from "./EditOwnerList";

const EditOwnerPage = () => {
  return (
    <>
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Edit Owner"
          home="Dashboard"
          currentPage="Edit Owner"
          addExtraPageTitle="Owner"
          addExtraPageTitlePath="owners"
        />
        <EditOwnersList />
      </div>
    </>
  );
};

export default EditOwnerPage;
