// UNIT MODEL STATIC DATA...

export const unitModelListData = [
  {
    _id: "1",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "1",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "1",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "1",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "1",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "1",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "1",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "1",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "1",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "1",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "2",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "2",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "2",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "2",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "2",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "2",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "2",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "2",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "2",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "2",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "3",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "3",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "3",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "3",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "3",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "3",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "3",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "3",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "3",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "3",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "4",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "4",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "4",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "4",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "4",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "4",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "4",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "4",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "4",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "4",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "5",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "5",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "5",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "5",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "5",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "5",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "5",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "5",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "5",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "5",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "6",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "6",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "6",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "6",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "6",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "6",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "6",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "6",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "6",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "6",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "7",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "7",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "7",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "7",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "7",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "7",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "7",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "7",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "7",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "7",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "8",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "8",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "8",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "8",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "8",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "8",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "8",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "8",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "8",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "8",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "9",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "9",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "9",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "9",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "9",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "9",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "9",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "9",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "9",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "9",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "10",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "10",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "10",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "10",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "10",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "10",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "10",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "10",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "10",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
  {
    _id: "10",
    unit_no: 1009,
    unit_type: "One Bedroom",
    unit_size: 807.62,
    price_per_ft: 1221,
    sales_proce: 468228,
    down_payment: 93646,
    three_month: 93646,
    six_month: 46823,
    handover: 64381,
    three_year_plan: 169733,
  },
];
