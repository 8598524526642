// FEATURES STATIC DATA...

export const featuresListData = [
  {
    _id: 1,
    features: "Beach Access",
    Is_display_features: true,
  },
  {
    _id: 2,
    features: "Cable-ready",
    Is_display_features: true,
  },
  {
    _id: 3,
    features: "Carpets",
    Is_display_features: true,
  },
  {
    _id: 4,
    features: "City View",
    Is_display_features: true,
  },
  {
    _id: 5,
    features: "Community View",
    Is_display_features: true,
  },
  {
    _id: 6,
    features: "Concierge Service",
    Is_display_features: true,
  },
  {
    _id: 7,
    features: "Drivers Room",
    Is_display_features: true,
  },
  {
    _id: 8,
    features: "East Orientation",
    Is_display_features: true,
  },
  {
    _id: 9,
    features: "Fully-fitted Kitchen",
    Is_display_features: true,
  },
  {
    _id: 10,
    features: "Garden View",
    Is_display_features: true,
  },
];
