import React from "react";
import "../../../asset/css/common/model.css";
import CustomBreadcrumb from "../../Common/customBreadcrumb";
import ProjectList from "./ProjectList";

const ProjectPage = () => {
  return (
    <div className="container page-containners-main">
      <CustomBreadcrumb
        title="Manage Project"
        home="Dashbord"
        currentPage="Project"
        addExtraPageTitle=""
        addExtraPageTitlePath=""
      />
      <ProjectList />
    </div>
  );
};

export default ProjectPage;
