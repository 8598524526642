import React from "react";
import "../../../asset/css/common/model.css";
import CustomBreadcrumb from "../../Common/customBreadcrumb";
import UnitModelList from "./UnitModelList";

const UnitModelPage = () => {
  return (
    <div className="container page-containners-main">
      <CustomBreadcrumb
        title="Manage Unit Model"
        home="Dashbord"
        currentPage="Unit Model"
        addExtraPageTitle="Project"
        addExtraPageTitlePath="create-project"
      />
      <UnitModelList />
    </div>
  );
};

export default UnitModelPage;
