import React from "react";
import CustomBreadcrumb from "../../../Common/customBreadcrumb";
import EditTenantsList from "./EditTenants";

const EditTenantsPage = () => {
  return (
    <>
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Edit Tenants"
          home="Dashboard"
          currentPage="Edit Tenants"
          addExtraPageTitle="Tenants"
          addExtraPageTitlePath="tenants"
        />
        <EditTenantsList />
      </div>
    </>
  );
};

export default EditTenantsPage;
