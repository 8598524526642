import React, { useState } from "react";
import NoteHistoryDetails from "./NoteHistoryDetails";

const LeadDetails = ({
  textareaHeight,
  setTextAreaHeight,
  selectAgentLeadIdWiseData,
}) => {
  const [historyDetailModel, setHistoryDetailModel] = useState(false);

  const handleCloseHistory = () => {
    setHistoryDetailModel(false);
  };

  return (
    <>
      <div className="lead-detail-container p-1 gap-3 d-flex  mx-0">
        {[selectAgentLeadIdWiseData]?.map((data) => (
          <>
            <div className="lead-detail-container-child remove-ml-lead-details">
              <div className="d-flex py-2 lead-detail-container-child-data">
                <span className="lead-data-key-value">Ref. No. : </span>
                <span className="lead-data-key-value-data">{data?.ref_no}</span>
              </div>
              <div className="d-flex py-2 lead-detail-container-child-data">
                <span className="lead-data-key-value">Property Ref. No. :</span>
                <span className="lead-data-key-value-data">
                  {data?.property_ref_no}
                </span>
              </div>
              <div className="d-flex py-2 lead-detail-container-child-data">
                <span className="lead-data-key-value">Name :</span>
                <span className="lead-data-key-value-data">{data?.name}</span>
              </div>
              <div className="d-flex py-2 lead-detail-container-child-data">
                <span className="lead-data-key-value">Mobile Number :</span>
                <span className="lead-data-key-value-data">{data?.mobile}</span>
              </div>
              <div className="d-flex py-2 lead-detail-container-child-data">
                <span className="lead-data-key-value">Email :</span>
                <span className="lead-data-key-value-data">{data?.email}</span>
              </div>
              <div className="d-flex py-2 lead-detail-container-child-data">
                <span className="lead-data-key-value">Lead Type :</span>
                <span className="lead-data-key-value-data">Agent</span>
              </div>
              <div className="d-flex py-2 lead-detail-container-child-data">
                <span className="lead-data-key-value">Agent 1 :</span>
                <span className="lead-data-key-value-data">
                  Binayah Support
                </span>
              </div>
              <div className="d-flex py-2 lead-detail-container-child-data">
                <span className="lead-data-key-value">Created By :</span>
                <span className="lead-data-key-value-data">
                  {data?.date_time}
                </span>
              </div>
              <div className="d-flex py-2 lead-detail-container-child-data">
                <span className="lead-data-key-value">Priority :</span>
                <span className="lead-data-key-value-data">Low</span>
              </div>
              <div className="d-flex py-2 lead-detail-container-child-data">
                <span className="lead-data-key-value">Hot Lead :</span>
                <span className="lead-data-key-value-data">Yes</span>
              </div>
              <div className="d-flex py-2 lead-detail-container-child-data">
                <span className="lead-data-key-value">Source :</span>
                <span className="lead-data-key-value-data">{data?.souece}</span>
              </div>
            </div>
            <div className="lead-detail-container-child">
              <div className="d-flex py-2 lead-detail-container-child-data">
                <span className="lead-data-key-value">Owner :</span>
                <span className="lead-data-key-value-data">Agent</span>
              </div>
              <div className="d-flex py-2 lead-detail-container-child-data">
                <span className="lead-data-key-value">Status :</span>
                <span className="lead-data-key-value-data">{data?.status}</span>
              </div>
              <div className="d-flex py-2 lead-detail-container-child-data">
                <span className="lead-data-key-value">Substatus :</span>
                <span className="lead-data-key-value-data">
                  {data?.s_status}
                </span>
              </div>
              <div className="d-flex py-2 lead-detail-container-child-data">
                <span className="lead-data-key-value">Type :</span>
                <span className="lead-data-key-value-data">Rent</span>
              </div>
              <div className="d-flex py-2 lead-detail-container-child-data">
                <span className="lead-data-key-value">Category :</span>
                <span className="lead-data-key-value-data">
                  {data?.category}
                </span>
              </div>
              <div className="d-flex py-2 lead-detail-container-child-data">
                <span className="lead-data-key-value">Unit No. :</span>
                <span className="lead-data-key-value-data">
                  {data?.unit_no}
                </span>
              </div>
              <div className="d-flex py-2 lead-detail-container-child-data">
                <span className="lead-data-key-value">Price :</span>
                <span className="lead-data-key-value-data">{data?.price}</span>
              </div>
              <div className="d-flex py-2 lead-detail-container-child-data">
                <span className="lead-data-key-value">Bedrooms :</span>
                <span className="lead-data-key-value-data">2 Beds</span>
              </div>
              <div className="d-flex py-2 lead-detail-container-child-data">
                <span className="lead-data-key-value">Emirates :</span>
                <span className="lead-data-key-value-data">Halfweg</span>
              </div>
              <div className="d-flex py-2 lead-detail-container-child-data">
                <span className="lead-data-key-value">Building :</span>
                <span className="lead-data-key-value-data"> Not Available</span>
              </div>
              <div className="d-flex py-2 lead-detail-container-child-data">
                <span className="lead-data-key-value">Location :</span>
                <span className="lead-data-key-value-data">
                  {data?.location}
                </span>
              </div>
            </div>
            <div className="note-containner-lead-list lead-detail-container-child">
              <div className="d-flex py-2 lead-detail-container-child-data">
                <span className="lead-data-key-value">Property :</span>
                <span className="lead-data-key-value-data">Note..</span>
              </div>
              <div className="d-flex py-2 lead-detail-container-child-data">
                <span className="lead-data-key-value">
                  Project & Page Name :
                </span>
                <span className="lead-data-key-value-data">Binayah & 1</span>
              </div>
              <div className="d-flex py-2 lead-detail-container-child-data">
                <span className="lead-data-key-value">Sublocation :</span>
                <span className="lead-data-key-value-data">
                  {" "}
                  {data?.s_location}
                </span>
              </div>
              <div className="d-flex py-2 lead-detail-container-child-data">
                <span className="lead-data-key-value">Country :</span>
                <span className="lead-data-key-value-data">
                  {" "}
                  {data?.country}
                </span>
              </div>
              <div className="lead-detail-history-btn d-flex">
                <h6 className="border-none text-black ">Note</h6>
                <button
                  className=""
                  onClick={() => {
                    setHistoryDetailModel(true);
                  }}
                >
                  History
                </button>
              </div>
              <div className="mt-2 note-text-area-containner">
                <textarea
                  style={{
                    height: textareaHeight,
                    transition: "height 0.3s ease",
                  }}
                  onFocus={() => setTextAreaHeight("150px")}
                  placeholder="Add a note..."
                ></textarea>
                <div className="lead-note-details-btn-containner">
                  <button
                    class="lead-list-cancel-btn"
                    onClick={() => setTextAreaHeight("40px")}
                  >
                    Cancel
                  </button>
                  <button class="lead-list-submit-btn">Submit</button>
                </div>
              </div>
              <div className="note-chat-containner">
                <ul id="chat">
                  <li class="me">
                    <div class="entete">
                      <span class="status blue"></span>
                      <h3>30-07-2024 12:06</h3> <h2>Admin</h2>
                    </div>
                    <div class="triangle"></div>
                    <div class="messages">Hii</div>
                  </li>
                </ul>
              </div>
              <div className="cansel-submit-note-btn">
                <button className="cansel-btn-note-chat">Cancel</button>
                <button className="submit-btn-note-chat">Submit</button>
              </div>
            </div>
          </>
        ))}
      </div>
      <NoteHistoryDetails
        historyDetailModel={historyDetailModel}
        handleCloseHistory={handleCloseHistory}
      />
    </>
  );
};

export default LeadDetails;
