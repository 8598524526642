import React from "react";
import "../../../asset/css/common/model.css";
import ProjectList from "./ProjectList";
import CustomBreadcrumb from "../../Common/customBreadcrumb";

const Project = () => {
  return (
    <div className="container page-containners-main">
      <CustomBreadcrumb
        title="Manage Project"
        home="Dashboard"
        currentPage="Project"
        addExtraPageTitle=""
        addExtraPageTitlePath=""
      />
      <ProjectList />
    </div>
  );
};

export default Project;
