import React from "react";
import TableComponent from "./TableComponent";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <>
      <div className="container-fluid mt-7 px-5 pb-5 ">
        <div className="row widget-grid align-items-center">
          <div className="col-xxl-4 col-sm-4 box-col-4">
            <div className="card profile-box">
              <div className="card-body main_card_body">
                <div className="media media-wrapper justify-content-between">
                  <div className="media-body">
                    <div className="greeting-user">
                      <h4 className="f-w-600 text-left">Welcome Admin!</h4>
                      <p
                        className="text-left pt-2 w-75 custom_text_date"
                        style={{ width: "90%;" }}
                      >
                        You have successfully logged in the CRM.
                        <br />
                        Last Login: 28th July 2024, 11.00 AM
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="clockbox">
                      <svg
                        id="clock"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 600 600"
                      >
                        <g id="face">
                          <circle
                            className="circle"
                            cx="300"
                            cy="300"
                            r="253.9"
                          ></circle>
                          <path
                            className="hour-marks"
                            d="M300.5 94V61M506 300.5h32M300.5 506v33M94 300.5H60M411.3 107.8l7.9-13.8M493 190.2l13-7.4M492.1 411.4l16.5 9.5M411 492.3l8.9 15.3M189 492.3l-9.2 15.9M107.7 411L93 419.5M107.5 189.3l-17.1-9.9M188.1 108.2l-9-15.6"
                          ></path>
                          <circle
                            className="mid-circle"
                            cx="300"
                            cy="300"
                            r="16.2"
                          ></circle>
                        </g>
                        <g id="hour">
                          <path className="hour-hand" d="M300.5 298V142"></path>
                          <circle
                            className="sizing-box"
                            cx="300"
                            cy="300"
                            r="253.9"
                          ></circle>
                        </g>
                        <g id="minute">
                          <path
                            className="minute-hand"
                            d="M300.5 298V67"
                          ></path>
                          <circle
                            className="sizing-box"
                            cx="300"
                            cy="300"
                            r="253.9"
                          ></circle>
                        </g>
                        <g id="second">
                          <path
                            className="second-hand"
                            d="M300.5 350V55"
                          ></path>
                          <circle
                            className="sizing-box"
                            cx="300"
                            cy="300"
                            r="253.9"
                          ></circle>
                        </g>
                      </svg>
                    </div>
                    <div className="badge f-10 p-0" id="txt"></div>
                  </div>
                </div>
                <div className="cartoon">
                  <img
                    className="img-fluid"
                    src="/svg/cartoon.svg"
                    alt="vector women with leptop"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Existing Widget Columns */}
          <div className="col-xxl-2 col-xl-2 col-sm-4 box-col-4">
            <div className="row">
              <div className="col-xl-12">
                <div className="card widget-1">
                  <Link to={`all-listing`}>
                    <div className="card-body">
                      <div className="widget-content">
                        <div className="widget-round warning">
                          <div className="bg-round">
                            <img
                              width="70%"
                              src="/svg/listing.svg"
                              alt="All Listing"
                            />
                          </div>
                        </div>
                        <div>
                          <h4>774</h4>
                          <span className="f-light">All Listings</span>
                        </div>
                      </div>
                      {/* <div className="font-warning f-w-500">
                      <i className="icon-arrow-down icon-rotate me-1"></i>
                      <span>-20%</span>
                    </div> */}
                    </div>
                  </Link>
                </div>
                <div className="card widget-1">
                  <Link to={`/team-leaders`}>
                    <div className="card-body">
                      <div className="widget-content">
                        <div className="widget-round warning">
                          <div className="bg-round">
                            <img
                              width="70%"
                              src="/svg/book.png"
                              alt="All Listing"
                            />
                          </div>
                        </div>
                        <div>
                          <h4>12855</h4>
                          <span className="f-light">Team Lead</span>
                        </div>
                      </div>
                      {/* <div className="font-warning f-w-500">
                      <i className="icon-arrow-down icon-rotate me-1"></i>
                      <span>-20%</span>
                    </div> */}
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xxl-2 col-xl-2 col-sm-4 box-col-4">
            <div className="row">
              <div className="col-xl-12">
                <div className="card widget-1">
                  <Link to={`/all-leads`}>
                    <div className="card-body">
                      <div className="widget-content">
                        <div className="widget-round secondary">
                          <div className="bg-round">
                            <img width={"100%"} src="/svg/deal.png" alt="img" />
                          </div>
                        </div>
                        <div>
                          <h4>138</h4>
                          <span className="f-light">Leads</span>
                        </div>
                      </div>
                      {/* <div className="font-secondary f-w-500">
                        <i className="icon-arrow-up icon-rotate me-1"></i>
                        <span>+50%</span>
                      </div> */}
                    </div>
                  </Link>
                </div>
                <div className="col-xl-12">
                  <div className="card widget-1">
                    <Link to={`/owners`}>
                      <div className="card-body">
                        <div className="widget-content">
                          <div className="widget-round primary">
                            <div className="bg-round">
                              <img
                                width={"80%"}
                                src="/svg/businessman.png"
                                alt="img"
                              />
                            </div>
                          </div>
                          <div>
                            <h4>53</h4>
                            <span className="f-light">Owner</span>
                          </div>
                        </div>
                        {/* <div className="font-primary f-w-500">
                        <i className="icon-arrow-up icon-rotate me-1"></i>
                        <span>+70%</span>
                      </div> */}
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Repeat for other columns */}
          <div className="col-xxl-2 col-xl-2 col-sm-4 box-col-4">
            <div className="row">
              <div className="col-xl-12">
                <div className="card widget-1">
                  <Link to={`/all-deals`}>
                    <div className="card-body">
                      <div className="widget-content">
                        <div className="widget-round warning">
                          <div className="bg-round">
                            <img
                              width={"70%"}
                              src="/svg/manager.png"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div>
                          <h4>156</h4>
                          <span className="f-light">Deals</span>
                        </div>
                      </div>
                      {/* <div className="font-warning f-w-500">
                      <i className="icon-arrow-down icon-rotate me-1"></i>
                      <span>-20%</span>
                    </div> */}
                    </div>
                  </Link>
                </div>
                <div className="col-xl-12">
                  <div className="card widget-1">
                    <Link to={`/tenants`}>
                      <div className="card-body">
                        <div className="widget-content">
                          <div className="widget-round success">
                            <div className="bg-round">
                              <img
                                width={"80%"}
                                src="/svg/user.svg"
                                alt="img"
                              />
                            </div>
                          </div>
                          <div>
                            <h4>156</h4>
                            <span className="f-light">Tenants</span>
                          </div>
                        </div>
                        {/* <div className="font-success f-w-500">
                        <i className="icon-arrow-up icon-rotate me-1"></i>
                        <span>+70%</span>
                      </div> */}
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-2 col-xl-2 col-sm-4 box-col-4">
            <div className="row">
              <div className="col-xl-12">
                <div className="col-xl-12">
                  <div className="card widget-1">
                    <Link to={`/agents`}>
                      <div className="card-body">
                        <div className="widget-content">
                          <div className="widget-round success">
                            <div className="bg-round">
                              <img
                                width={"80%"}
                                src="/svg/lender.png"
                                alt="img"
                              />
                            </div>
                          </div>
                          <div>
                            <h4>12460</h4>
                            <span className="f-light">Agent</span>
                          </div>
                        </div>
                        {/* <div className="font-success f-w-500">
                        <i className="icon-arrow-up icon-rotate me-1"></i>
                        <span>+70%</span>
                      </div> */}
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="col-xl-12">
                  <div className="card widget-1">
                    <Link to={`/contacts`}>
                      <div className="card-body">
                        <div className="widget-content">
                          <div className="widget-round success">
                            <div className="bg-round">
                              <img
                                width={"80%"}
                                src="/svg/phone.png"
                                alt="img"
                              />
                            </div>
                          </div>
                          <div>
                            <h4>127</h4>
                            <span className="f-light">Contacts</span>
                          </div>
                        </div>
                        {/* <div className="font-success f-w-500">
                        <i className="icon-arrow-up icon-rotate me-1"></i>
                        <span>+70%</span>
                      </div> */}
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <TableComponent />
      </div>
    </>
  );
};

export default Home;
