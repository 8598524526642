import React from "react";
import CustomBreadcrumb from "../../../Common/customBreadcrumb";
import AddAgent from "./AddAgent";

const AddAgentsPage = () => {
  return (
    <>
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Add New Agent"
          home="Dashboard"
          currentPage="Add Agent"
          addExtraPageTitle="Agent"
          addExtraPageTitlePath="agents"
        />
        <AddAgent />
      </div>
    </>
  );
};

export default AddAgentsPage;
