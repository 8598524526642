// BAYUT LEADS STATIC DATA...

export const BayutLeadsData = [
  {
    property_id: "ffaa52ed-5db3-4a6e-bf3a-d36e49ece7de",
    property_refrance: "BY-S-13599",
    currant_type: "",
    message: "	Hi, I am interested in your property on Bayut.",
    link: "https://www.bayut.com/pm/9024832/a0cfe320-b797-4ba6-87ad-85d2685cce07",
    name: "Fadi",
    email: "test@123.com",
    mobile: "+971543525054",
    type: "whatsapp_leads",
    date: "2024-07-25 23:25:12",
  },
  {
    property_id: "ffaa52ed-5db3-4a6e-bf3a-d36e49ece7de",
    property_refrance: "BY-S-13599",
    currant_type: "",
    message: "	Hi, I am interested in your property on Bayut.",
    link: "https://www.bayut.com/pm/9024832/a0cfe320-b797-4ba6-87ad-85d2685cce07",
    name: "Fadi",
    email: "test@123.com",
    mobile: "+971543525054",
    type: "whatsapp_leads",
    date: "2024-07-25 23:25:12",
  },
  {
    property_id: "ffaa52ed-5db3-4a6e-bf3a-d36e49ece7de",
    property_refrance: "BY-S-13599",
    currant_type: "",
    message: "	Hi, I am interested in your property on Bayut.",
    link: "https://www.bayut.com/pm/9024832/a0cfe320-b797-4ba6-87ad-85d2685cce07",
    name: "Fadi",
    email: "test@123.com",
    mobile: "+971543525054",
    type: "whatsapp_leads",
    date: "2024-07-25 23:25:12",
  },
  {
    property_id: "ffaa52ed-5db3-4a6e-bf3a-d36e49ece7de",
    property_refrance: "BY-S-13599",
    currant_type: "",
    message: "	Hi, I am interested in your property on Bayut.",
    link: "https://www.bayut.com/pm/9024832/a0cfe320-b797-4ba6-87ad-85d2685cce07",
    name: "Fadi",
    email: "test@123.com",
    mobile: "+971543525054",
    type: "whatsapp_leads",
    date: "2024-07-25 23:25:12",
  },
  {
    property_id: "ffaa52ed-5db3-4a6e-bf3a-d36e49ece7de",
    property_refrance: "BY-S-13599",
    currant_type: "",
    message: "	Hi, I am interested in your property on Bayut.",
    link: "https://www.bayut.com/pm/9024832/a0cfe320-b797-4ba6-87ad-85d2685cce07",
    name: "Fadi",
    email: "test@123.com",
    mobile: "+971543525054",
    type: "whatsapp_leads",
    date: "2024-07-25 23:25:12",
  },
  {
    property_id: "ffaa52ed-5db3-4a6e-bf3a-d36e49ece7de",
    property_refrance: "BY-S-13599",
    currant_type: "",
    message: "	Hi, I am interested in your property on Bayut.",
    link: "https://www.bayut.com/pm/9024832/a0cfe320-b797-4ba6-87ad-85d2685cce07",
    name: "Fadi",
    email: "test@123.com",
    mobile: "+971543525054",
    type: "whatsapp_leads",
    date: "2024-07-25 23:25:12",
  },
  {
    property_id: "ffaa52ed-5db3-4a6e-bf3a-d36e49ece7de",
    property_refrance: "BY-S-13599",
    currant_type: "",
    message: "	Hi, I am interested in your property on Bayut.",
    link: "https://www.bayut.com/pm/9024832/a0cfe320-b797-4ba6-87ad-85d2685cce07",
    name: "Fadi",
    email: "test@123.com",
    mobile: "+971543525054",
    type: "whatsapp_leads",
    date: "2024-07-25 23:25:12",
  },
  {
    property_id: "ffaa52ed-5db3-4a6e-bf3a-d36e49ece7de",
    property_refrance: "BY-S-13599",
    currant_type: "",
    message: "	Hi, I am interested in your property on Bayut.",
    link: "https://www.bayut.com/pm/9024832/a0cfe320-b797-4ba6-87ad-85d2685cce07",
    name: "Fadi",
    email: "test@123.com",
    mobile: "+971543525054",
    type: "whatsapp_leads",
    date: "2024-07-25 23:25:12",
  },
  {
    property_id: "ffaa52ed-5db3-4a6e-bf3a-d36e49ece7de",
    property_refrance: "BY-S-13599",
    currant_type: "",
    message: "	Hi, I am interested in your property on Bayut.",
    link: "https://www.bayut.com/pm/9024832/a0cfe320-b797-4ba6-87ad-85d2685cce07",
    name: "Fadi",
    email: "test@123.com",
    mobile: "+971543525054",
    type: "whatsapp_leads",
    date: "2024-07-25 23:25:12",
  },
  {
    property_id: "ffaa52ed-5db3-4a6e-bf3a-d36e49ece7de",
    property_refrance: "BY-S-13599",
    currant_type: "",
    message: "	Hi, I am interested in your property on Bayut.",
    link: "https://www.bayut.com/pm/9024832/a0cfe320-b797-4ba6-87ad-85d2685cce07",
    name: "Fadi",
    email: "test@123.com",
    mobile: "+971543525054",
    type: "whatsapp_leads",
    date: "2024-07-25 23:25:12",
  },
  {
    property_id: "ffaa52ed-5db3-4a6e-bf3a-d36e49ece7de",
    property_refrance: "BY-S-13599",
    currant_type: "",
    message: "	Hi, I am interested in your property on Bayut.",
    link: "https://www.bayut.com/pm/9024832/a0cfe320-b797-4ba6-87ad-85d2685cce07",
    name: "Fadi",
    email: "test@123.com",
    mobile: "+971543525054",
    type: "whatsapp_leads",
    date: "2024-07-25 23:25:12",
  },
  {
    property_id: "ffaa52ed-5db3-4a6e-bf3a-d36e49ece7de",
    property_refrance: "BY-S-13599",
    currant_type: "",
    message: "	Hi, I am interested in your property on Bayut.",
    link: "https://www.bayut.com/pm/9024832/a0cfe320-b797-4ba6-87ad-85d2685cce07",
    name: "Fadi",
    email: "test@123.com",
    mobile: "+971543525054",
    type: "whatsapp_leads",
    date: "2024-07-25 23:25:12",
  },
  {
    property_id: "ffaa52ed-5db3-4a6e-bf3a-d36e49ece7de",
    property_refrance: "BY-S-13599",
    currant_type: "",
    message: "	Hi, I am interested in your property on Bayut.",
    link: "https://www.bayut.com/pm/9024832/a0cfe320-b797-4ba6-87ad-85d2685cce07",
    name: "Fadi",
    email: "test@123.com",
    mobile: "+971543525054",
    type: "whatsapp_leads",
    date: "2024-07-25 23:25:12",
  },
];
